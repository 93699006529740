<template>
    <div class="job-item"
        :class="{
            'selected-job': currentIdx == activeIdx,
        }">
        <div class="job-item-top" @click="handlePageDetail">
            <div class="title-row ell">
                <span class="name text-ellipsis" :title="job.name">{{job.name}}</span>
                <span class="locations text-ellipsis" :title="job.location">[{{job.location}}]</span>
                <span class="tags">
                    <!-- <span class="hurry-tag">急聘</span>
                    <span class="tag job-tag-danger">较难</span>
                    <span class="tag job-tag-warning">最新</span> -->

                    <el-tooltip
                        v-for="item in job.activityTags"
                        :key="item.id"
                        effect="light"
                        placement="right"
                        :disabled="!item.description"
                        v-show="item.id !== 'StandstillJob' && item.id != 'FirstOrderActivityJob'"
                        popper-class="job-tag-description">
                        <div slot="content" v-html="item.description"></div>

                        <span class="JobEmergency" title="急聘" v-if="item.id == 'JobEmergency'">
                            <span class="hurry">
                                <font-icon href="#icon-shandian"></font-icon>    
                            </span>急聘
                        </span>
                        <span v-else class="tag" :class="`job-tag-${jobActivityTag[item.id].theme}`" :title="item.name">{{item.name == 'New' ? '最新': item.name}}</span>

                    </el-tooltip>

                    <span class="tag job-tag-info" v-if="job.jobType ==3">HR</span>
                    <span class="tag job-tag-info" v-if="job.jobType ==5">代运营</span>
                    <span class="tag job-tag-info" title="该职位有职位讲解" v-if="job.hasRecord">有讲解</span>
                </span>
            </div>
            <div class="item-row">
                <div class="row-l">
                    <div class="item">
                        <span class="salary">{{job.salary == '0K-0K' ? '面议' : job.salary}}</span>
                        <span class="job-tag">{{job.yearOfExperience}}</span>
                        <span class="job-tag">{{job.degree}}</span>
                        <span class="job-tag">需 {{job.recruitingCount}} 人</span>
                        <span class="job-tag" v-if="job.categoryName">{{job.categoryName}}</span>
                    </div>
                    <div class="item">
                        <span class="percent">{{job.commissionValue.indexOf('0K-0K') > -1 ? '按比例' : job.commissionValue}}</span>
                        <span class="promise-time">
                            保证期<span class="month">{{job.guaranteePeriod}}</span>个月 
                        </span>
                        <!-- :href="(isMyFirm && ![3,5].includes(job.jobType)) ? `/#/Customer/${job.customerId}` : 'javascript:void(0)'" -->
                        <span class="customer"
                            :target="(isMyFirm && ![3,5].includes(job.jobType)) ? '_blank' : ''"
                            :class="{'no-link': !isMyFirm || [3,5].includes(job.jobType)}"
                            @click.stop="handleJumpCustomer(job)"
                            v-if="job.canViewFirmDetail"
                            :title="job.firmShortName || job.firmName"
                            >{{job.firmShortName || job.firmName}}</span>
                    </div>
                    <div class="item">
                        <div
                            class="job-firm-commission"
                            v-if="job.isCooperativeJob && userInfo.headhuntingFirmType > 0 && ((userInfo.headhuntingFirmType > 2 && job.headhuntingFirmCommissionValue > 0) || (userInfo.headhuntingFirmType < 3 && job.otherFirmCommissionValue > 0))">
                            分佣比例
                            <span class="text-grey">
                                {{userInfo.headhuntingFirmType > 2 ? job.headhuntingFirmCommissionValue + '%' : job.otherFirmCommissionValue + '%'}}
                            </span>
                            <el-tooltip
                                popper-class="commission-rate-text"
                                effect="light"
                                content="简历提供者，分成比例需要和平台运营确认。"
                                placement="right"
                                v-if="userInfo.headhuntingFirmType < 3">
                                <div class="tip-icon">
                                    <font-icon class="default" href="#icon-talent_qd"></font-icon>
                                    <font-icon class="hover" href="#icon-talent_ql"></font-icon>
                                </div>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
                <div class="row-r">
                    <template v-if="((!job.isMyOrder && !job.isMyJob) && job.status != 4 && job.status != 2) || ((job.isMyOrder || job.isMyJob) && job.status != 4 && job.status != 2) || (job.isMyJob && job.status != 4) || (job.isMyJob && job.status == 4)">
                        <button class="job-btn" 
                            v-if="(!job.isMyOrder && !job.isMyJob) && job.status != 4 && job.status != 2&& job.status != 3"
                            @click="handleJobMoreOperate('grabOrder')"
                            v-loading="moreOperateLoading"
                            data-event-id="E_job_item_click"
                            >抢</button>
                        <button class="job-btn" 
                            v-if="(job.isMyOrder || job.isMyJob) && job.status != 4 && job.status != 2"
                            @click="handleJobMoreOperate('recommend')"
                            data-event-id="E_job_item_click"
                            >推荐候选人</button>
                        <button class="job-btn" 
                            v-if="job.isMyJob && job.status != 4"
                            @click="handleJobMoreOperate('changeJobStatus')"
                            data-event-id="E_job_item_click">修改职位状态</button>
                        <button class="job-btn" 
                            v-if="job.isMyJob && job.status == 4"
                            @click="handleJobMoreOperate('copyJob')"
                            data-event-id="E_job_item_click">复制职位</button>
                    </template>
                    <span v-else>职位{{  job.status == 4 ? '已完成' : '已暂停' }}状态下无法操作</span>

                </div>
            </div>


        </div>
           
        <div class="job-item-bottom">
            <div class="bottom-l">
                <!-- <div class="pic"> -->
                    <avatar
                        class="pic"
                        size="sm"
                        :src="job.avatarUrl"
                        :userId="job.ownerId"
                        :enableCard="true"
                        :enableLink="true"
                        :shareData="shareData"
                    ></avatar>
                <!-- </div> -->
                <div class="infos">
                    <p class="name">{{job.realName}} </p>
                    <p class="time">{{job.updatedDateTime | dateFilter}} 更新</p>
                </div>
            </div>
            <div class="bottom-m">
                <div class="middle-cell">
                    <span class="count">{{job.recommendationStatistic ? job.recommendationStatistic.total : 0}}</span>推荐
                </div>
                <div class="middle-cell">
                    <span class="count">{{job.recommendationStatistic ? job.recommendationStatistic.interviewCount : 0}}</span>面试
                </div>
                <div class="middle-cell">
                    <span class="count">{{job.recommendationStatistic ? job.recommendationStatistic.offerCount : 0}}</span>Offer
                </div>
            </div>
            <div class="bottom-r">
                <span class="to-detail"
                    data-event-id="E_job_item_click"
                    @click="jumpJobDetail(job)">
                    职位详情
                    <span class="arrow-icon">
                        <font-icon
                            href="#icon-right-copy"
                        ></font-icon>
                    </span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import jobActivityTag from '#/js/config/jobActivityTag.json';
import jobStatus from '#/js/config/jobStatus.json';
import Avatar from '#/component/common/avatar.vue';
import {job as jobUrl} from '#/js/config/api.json';
import moment from 'moment';
export default {
    components: {
        Avatar,
    },
    props: {
        job: {
            type: Object,
            default: () => {
                return {};
            }
        },
        isMyFirm: {
            type: Boolean,
            default: true
        },
        currentIdx: {
            type: Number,
        },
        activeIdx: {
            type: Number,
            default: 0
        },
    },
    computed: {
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        shareData() {
            let jobInfo = this.job;
            return {
                isPublic: jobInfo.isCooperativeJob,
                jobId: jobInfo.id,
                jobName: jobInfo.name,
                firmShortName: jobInfo.firmShortName,
                location: jobInfo.location,
                yearOfExperience: jobInfo.yearOfExperience,
                degree: jobInfo.degree,
                salary: jobInfo.salary == '0K-0K' ? '面议' : jobInfo.salary,
                commissionValue: jobInfo.commissionValue && jobInfo.commissionValue.indexOf('0K-0K') > -1 ? '按比例' : jobInfo.commissionValue,
                creatorId: jobInfo.ownerId,
                creatorName: `${jobInfo.realName}@${jobInfo.nickname}`,
                creatorAvatar: jobInfo.avatarUrl,
                isInnerJob: !jobInfo.isCooperativeJob
            };
        }
    },
    data() {
        return {
            jobActivityTag: jobActivityTag,
            jobStatus: jobStatus,
            moreOperateLoading: false,
        };
    },
    filters: {
        createDateFilter(val) {
            let today = moment().format('YYYY-MM-DD'),
                dateDay = moment(val).format('YYYY-MM-DD');
            if(today === dateDay) {
                return '今天';
            } else if(moment().subtract(1, 'days').format('YYYY-MM-DD') === dateDay) {
                return '昨天';
            } else {
                return dateDay;
            }
        },
        dateFilter(val) {
            let now = new Date(),
                date = val;
            let today = moment().format('YYYY-MM-DD'),
                dateDay = moment(date).format('YYYY-MM-DD'),
                nowTime = new Date().getTime(),
                dateTime = new Date(date).getTime(),
                oneHourTime = 60 * 60 * 1000,
                hour = now.getHours(),
                dateHours = new Date(date).getHours();
            if(today === dateDay) {
                if (dateTime > nowTime - oneHourTime * 4 && dateTime < nowTime - oneHourTime) {
                    return (hour - dateHours) + '小时前';
                } else if (dateHours == hour || (dateTime > nowTime && dateTime < nowTime + oneHourTime) || (dateTime < nowTime && dateTime > nowTime - oneHourTime)) {  // 目标时间与当前时间
                    let minutesDiff = Math.floor((dateTime - nowTime) / 60000);
                    if(minutesDiff == 0 || minutesDiff == 1) {
                        return '刚刚';
                    } else if(minutesDiff < 0) {
                        return (minutesDiff * -1) + '分钟前';
                    }
                } else {
                    return '今天';
                }
            } else if(moment().subtract(1, 'days').format('YYYY-MM-DD') === dateDay) {
                return '昨天';
            } else {
                return dateDay;
            }
        }
    },
    methods: {
        handleJobMoreOperate(command) {
            // _tracker.track('myCompanyJob',
            //     {
            //         isbyHotSearch: location.hash.slice(1) !== '' && location.hash.slice(1) !== 'null' ? true : false,
            //         type: command,
            //         id: this.job.id
            //     }
            // );
            switch(command) {
                case 'grabOrder':
                    this.grabOrder();
                    break;
                case 'recommend':
                    // window.open(`/Headhunting/MyCompany.html#/Job/${this.job.id}?action=clickOperate`);
                    this.$parent.handleRecommendBtnCallback(this.job);
                    // window.open(`/#/Job/${this.job.id}?action=clickOperate`);
                    break;
                case 'changeJobStatus':
                    this.$emit('changeJobStatus', this.job);
                    break;
                case 'copyJob':
                    // location.href = `/#/jobCreate/copy?jobId=${this.job.id}`;
                    window.open(`/#/jobCreate/copy?jobId=${this.job.id}`);
                    break;
            }
        },
        grabOrder() {
            this.moreOperateLoading = true;
            let token = this.$store.state.verificationToken.verificationToken;
            if (this.job.jobType === 2) {
                _request({
                    method: 'POST',
                    url: jobUrl.get_internal_order,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data:{
                        jobId: this.job.id,
                        __RequestVerificationToken: token
                    },
                    throwBusinessError: true
                }).then(()=>{
                    this.moreOperateLoading = false;
                    // shortTips('抢单成功！');
                    // location.href = `/Headhunting/MyCompany.html?jobName=${encodeURI(this.job.name)}#/resultRecommend?jobId=${this.job.id}&fromPage=GrabOrder`;
                    // location.href = `/?jobName=${encodeURI(this.job.name)}#/resultRecommend?jobId=${this.job.id}&fromPage=GrabOrder`;
                    this.$emit('grab-success-callback', this.job);
                }).catch(err => {
                    this.moreOperateLoading = false;
                    switch (err.data.code) {
                        case 'NotFoundFirm':
                            this.$confirm('您尚未加入公司，为更好保障您做单的权益，请加入公司。', '提示', {
                                confirmButtonText: '加入公司',
                                cancelButtonText: '取消'
                            }).then(() => {
                                window.open(err.data.url);
                            }).catch(() => {});
                            break;
                        case 'NotConfirmEmail':
                            this.$confirm('您的邮箱尚未验证，为您能及时获取职位信息，请验证。', '提示', {
                                confirmButtonText: '验证邮箱',
                                cancelButtonText: '取消'
                            }).then(() => {
                                // window.open('/Headhunting/MyCompany.html#/emailVerify');
                                window.open("/Headhunting/#/personalCenter?Tab=accountSet");
                            }).catch(() => {});
                            break;
                        case 'Applying':
                            this.$alert('还未能认证您所在的公司，请等待审核通过后再抢单！', {
                                confirmButtonText: '我知道了'
                            });
                            break;
                        case 'FirmNotVerified':
                            if(this.userInfo.firmVerificationStatus == 0) {
                                this.$alert('市场职位抢单需要公司的信息被审核验证', '提示', {
                                    confirmButtonText: '申请认证',
                                    callback: action => {
                                        if(action == 'conform') {
                                            window.open('/Headhunting/Firm/Verify');
                                        }
                                    }
                                });
                            } else if(this.userInfo.firmVerificationStatus == 1) {
                                this.$alert('你的公司信息正在审核中，请等待...', {
                                    confirmButtonText: '我知道了'
                                });
                            }
                            break;
                        default:
                            shortTips('系统异常，请稍后重试');
                            break;
                    }
                });
            } else if( [3,4,5].includes(this.job.jobType) ) {
                let isConfirm = this.job.jobType === 1?true:false;
                console.log(this.job.jobType);
                _request({
                    url: "/openapi/Job/%p/Order".replace(/\%p/ig, this.job.id),
                    method: "POST",
                    baseURL: "LbdOpenApi",
                    data: {
                        jobId: this.job.id,
                    },
                }).then(()=>{
                    this.headBtnLoading = false;
                    this.$emit('grab-success-callback', this.job);
                }).catch(err => {
                    this.headBtnLoading = false;
                });
            } else {
                let isConfirm = this.job.jobType === 1 ? true : false;
                _request({
                    method: 'POST',
                    url: jobUrl.get_market_order,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: {
                        jobId: this.job.id,
                        confirm: isConfirm,
                        __RequestVerificationToken: token
                    },
                }).then(()=>{
                    this.moreOperateLoading = false;
                    // shortTips('抢单成功！');
                    // location.href = `/Headhunting/MyCompany.html?jobName=${encodeURI(this.job.name)}#/resultRecommend?jobId=${this.job.id}`;
                    this.$emit('grab-success-callback', this.job);
                    // location.href = `/?jobName=${encodeURI(this.job.name)}#/resultRecommend?jobId=${this.job.id}`;
                }).catch(err => {
                    this.moreOperateLoading = false;
                });
            }
        },

        jumpJobDetail(job) {
            // window.open(`/#/Job/${job.id}`);
            window.open(`/Headhunting/MyCompany.html#/Job/${job.id}`);
        },

        handlePageDetail() {
            this.$emit('update-active-index', this.currentIdx);
        },
        handleJumpCustomer(job) {
            if(this.isMyFirm && ![3,5].includes(job.jobType)) {
                window.open(`/#/Customer/${job.customerId}`);
            }
        },
    }
};
</script>

<style lang="scss" scope>
    .selected-job{
        border: 1px solid $primary!important;
        .job-item-top{
            .title-row{
                .name,.locations{
                    color: $primary;
                }
            }
        }
    }
    .job-item {
        background-color: #fff;
        // padding: 20px;
        // border-top: 1px solid #eee;
        margin-bottom: 11px;

        border-radius: 10px;
        // border: 1px solid transparent;
        overflow: hidden;
        border: 1px solid white;
        .text-ellipsis {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        &:hover {
            // background-color: #f6f6f6;
            // border: 1px solid #f6f6f6;
            .job-item-top{
                cursor: pointer;
                .title-row{
                    .name,.locations{
                        color: $primary;
                    }
                }
            }
        }
        &-top {
            // height: 40px;
            // line-height: 40px;
            // color: #333;
            // font-size: 16px;
            background: white;
            padding: 15px 20px;
            .title-row{
                height: 26px;
                line-height: 26px;
                margin-bottom: 7px;
                .name{
                    display: inline-block;
                    font-size: 16px;
                    font-weight: 600;
                    color: #333333;
                    max-width: 340px;
                    cursor: pointer;
                }
                .locations{
                    display: inline-block;
                    font-size: 16px;
                    font-weight: 600;
                    color: #333333;
                    vertical-align: top;
                    max-width: 220px;
                    cursor: pointer;
                }
                .tags{
                    vertical-align: top;
                    .JobEmergency{
                        display: inline-block;
                        height: 20px;
                        line-height: 20px;
                        background: #FF4B4B;
                        border-radius: 3px;
                        font-size: 12px;
                        margin-left: 7px;
                        padding: 0 10px;
                        color: white;
                        .hurry{
                            color: white;
                            font-size: 10px;
                            vertical-align: top;
                        }
                    }
                    .tag{
                        display: inline-block;
                        height: 20px;
                        line-height: 18px;
                        border-radius: 3px;
                        font-size: 12px;
                        margin-left: 7px;
                        padding: 0 10px;
                        border: 1px solid #666;
                        // vertical-align: -1px;
                    }
                    .job-tag-danger{
                        border-color: #FFB6B6;
                        color: #FF4B4B;
                        background: rgba(255,75,75,0.1);
                    }
                    .job-tag-primary{
                        border-color: #38bc9d;
                        color: #38bc9d;
                    }
                    .job-tag-info{
                        border-color: #39f;
                        color: #39f;
                        background: rgba(185,208,255,0.14);
                    }
                    .job-tag-warning{
                        border-color: #FFDDA8;
                        color: #FF9E21;
                        background: rgba(255,236,199,0.7);
                    }
                    

                }
            }
            .item-row{
                display: flex;
                justify-content: space-between;
                .row-l{
                    flex: 1;
                    .item{
                        line-height: 22px;
                        margin-bottom: 7px;
                        font-size: 14px;
                        .salary{
                            font-size: 14px;
                            font-weight: 500;
                            color: #666666;
                            line-height: 20px;
                            margin-right: 8px;
                        }
                        .job-tag{
                            display: inline-block;
                            height: 22px;
                            background: #F6F6F6;
                            border-radius: 3px;
                            font-size: 14px;
                            font-weight: 500;
                            color: #999999;
                            line-height: 22px;
                            margin-right: 4px;
                            padding: 0 10px;
                        }

                        .percent{
                            font-weight: 500;
                            color: #FF9E21;
                        }
                        .promise-time{
                            color: #666666;
                            margin-left: 20px;
                            margin-right: 20px;
                            .month{
                                color: #FF9E21;
                                margin: 0 4px;
                            }
                        }
                        .customer{
                            color: #666666;
                            cursor: pointer;
                            &:hover{
                                color: $primary;
                            }
                            &::before{
                                width: 1px;
                                height: 15px;
                                background: #DCDCDC;
                                content: '';
                                display: inline-block;
                                margin-right: 20px;
                                vertical-align: -4px;
                            }
                        }

                        .job-firm-commission {
                            color: #b9b9b9;
                            .text-grey {
                                color: #666;
                            }
                        }

                        .tip-icon {
                            display: inline-block;
                            width: 22px;
                            height: 22px;
                            margin-left: 5px;
                            .font-icon {
                                width: 16px;
                                height: 16px;
                                margin-top: -3px;
                                opacity: 1;
                            }
                            .hover {
                                display: none;
                            }
                            &:hover {
                                .default {
                                    display: none;
                                }
                                .hover {
                                    display: inline-block;
                                }
                            }
                        }

                    }
                }
                .row-r{
                    max-width: 220px;
                    // background: #999;
                    display: flex;
                    justify-content: end;
                    .job-btn{
                        background: $primary;
                        padding: 7px 12px;
                        font-size: 14px;
                        font-weight: 500;
                        color: #FFFFFF;
                        height: 34px;
                        border-radius: 5px;
                        border: 1px solid #38BC9D;
                        margin-left: 5px;
                        // line-height: 20px;
                    }
                }
            }

            .job-title {
                display: flex;
                height: 40px;
                &-link {
                    max-width: 400px;
                    margin-right: 15px;
                }
                .job-location {
                    max-width: 300px;
                    margin-right: 10px;
                }
                .job-detail-tags {
                    margin-bottom: 0;
                    user-select: none;
                    white-space: nowrap;
                    .job-detail-tag {
                        line-height: 16px;
                        text-align: center;
                        font-size: 12px;
                        border: 1px solid #b9b9b9;
                        padding: 0 6px;
                        border-radius: 10px;
                        color: #b9b9b9;
                        margin-right: 7px;
                        &.job-tag-danger{
                            border-color: #FFB6B6;
                            color: #ff493c;
                        }
                        &.job-tag-primary{
                            border-color: rgba(56,188,157,0.53);
                            color: #38bc9d;
                        }
                        &.job-tag-info{
                            border-color: rgba(81,164,255,0.53);
                            color: #39f;
                        }
                        &.job-tag-warning{
                            border-color: #FFDDA8;
                            color: #ff7200;
                        }
                    }
                    .job-record-icon {
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        background: url("~@src/assets/images/jobList/job-record-icon.png") no-repeat 0 0;
                        background-size: contain;
                        position: relative;
                        top: 5px;
                    }
                }
            }
        }
        .job-item-bottom{
            background: linear-gradient(270deg, #FCFCFC 0%, #F7F8F8 100%);
            border-radius: 0px 0px 10px 10px;
            padding: 8px 18px 14px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            .bottom-l{
                width: 200px;
                min-width: 200px;
                max-width: 200px;
                display: flex;
                align-items: center;
                .pic{
                    width: 32px;
                    height: 32px;
                    // border: 1px solid rgba(168,168,168,0.44);
                    overflow: hidden;
                    margin-right: 7px;
                    .avatar-sm {
                        width: 32px;
                        height: 32px;
                        border: 1px solid rgba(168,168,168,0.44);
                        border-radius: 50%;
                    }
                }

            //     .user-avatar {
            //     margin-right: 10px;
            //     margin-top: -6px;
            //     width: 30px;
            //     height: 30px;
            //     .avatar-sm {
            //         width: 28px;
            //         height: 28px;
            //     }
            // }
                .infos{
                    flex: 1;
                    >p{
                        margin: 0;
                    }
                    .name{
                        font-size: 12px;
                        font-weight: 500;
                        color: #333333;
                        line-height: 17px;
                    }
                    .time{
                        font-size: 11px;
                        font-weight: 500;
                        color: #999999;
                        line-height: 16px;
                    }

                }
            }
            .bottom-m{
                flex: 1;
                max-width: 300px;
                display: flex;
                justify-content: space-around;
                align-items: center;
                .middle-cell{
                    font-size: 14px;
                    font-weight: 500;
                    color: #999999;
                    line-height: 17px;
                    flex: 1;
                    text-align: center;
                    position: relative;
                    &::after{
                        width: 1px;
                        height: 15px;
                        background: #DCDCDC;
                        content: '';
                        display: inline-block;
                        position: absolute;
                        right: 0;
                        top: 4px;
                    }
                    &:last-child::after{
                        display: none;
                    }
                    .count{
                        font-size: 14px;
                        // font-weight: 600;
                        color: #333333;
                        line-height: 20px;
                        margin-right: 5px;
                    }
                }
                
            }
            .bottom-r{
                .to-detail{
                    display: inline-block;
                    cursor: pointer;
                    font-size: 14px;
                    font-weight: 500;
                    color: #666666;
                    line-height: 20px;
                    .arrow-icon{
                        color: #666;
                        font-size: 9px;
                        vertical-align: 2px;
                    }
                    &:hover{
                        color: $primary;
                        .arrow-icon{
                            color: $primary;
                        }
                    }
                }
            }

        }
        &-body {
            display: flex;
            justify-content: space-between;
            color: #666;
            line-height: 20px;
            .job-intro {
                // width: 280px;
                width: 300px;
                .job-info {
                    margin-bottom: 10px;
                    white-space: nowrap;
                    .job-info-item {
                        border-right: 1px solid #d3d3d3;
                        padding-right: 3px;
                        margin-right: 5px;
                        &:nth-last-of-type(1) {
                            border-right: none;
                        }
                    }
                }
                .job-promise {
                    margin-bottom: 10px;
                    color: #b9b9b9;
                    white-space: nowrap;
                    // overflow: hidden;
                    // text-overflow: ellipsis;
                    .job-commission-value {
                        // margin: 0 10px;
                        margin: 0;
                    }
                    .job-guarantee-period {
                        // margin-left: 10px;
                        margin-left: 0;
                    }
                    .text-orange {
                        color: #f39800;
                    }
                }
                .job-firm-commission {
                    color: #b9b9b9;
                    .text-grey {
                        color: #666;
                    }
                }

                .tip-icon {
                    display: inline-block;
                    width: 22px;
                    height: 22px;
                    margin-left: 5px;
                    .font-icon {
                        width: 16px;
                        height: 16px;
                        margin-top: -3px;
                        opacity: 1;
                    }
                    .hover {
                        display: none;
                    }
                    &:hover {
                        .default {
                            display: none;
                        }
                        .hover {
                            display: inline-block;
                        }
                    }
                }
            }
            .job-status {
                width: 240px;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                .job-customer {
                    // width: 90px;
                    width: 240px;
                    // min-width: 90px;
                    margin-bottom: 10px;
                    // max-width: 190px;
                    &.no-link {
                        cursor: default;
                        &:hover {
                            color: #333;
                        }
                    }
                    &:hover {
                        color: #38BC9D;
                    }
                }
                .job-date {
                    color: #999;
                }
            }
            &-right {
                display: flex;
            }
            .user-avatar {
                margin-right: 10px;
                margin-top: -6px;
                width: 30px;
                height: 30px;
                .avatar-sm {
                    width: 28px;
                    height: 28px;
                }
            }
            .job-number {
                span {
                    display: inline-block;
                    min-width: 40px;
                    margin-right: 5px;
                }
            }
            .el-dropdown {
                height: 30px;
                .el-dropdown-link {
                    color: #ccc;
                    display: inline-block;
                    width: 60px;
                    text-align: center;
                    line-height: 20px;
                    font-size: 26px;
                }
            }
        }
    }
</style>

<style lang="scss">
.job-tag-description.el-tooltip__popper.is-light {
    margin-left: 7px;
    color: #666;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    .popper__arrow {
        border: 6px solid transparent;
        border-left: none;
        border-right-color: rgba(0, 0, 0, 0.2);
        &::after {
            border-right-color: #fff;
        }
    }
}
.commission-rate-text.el-tooltip__popper.is-light {
    margin-left: 8px;
    margin-top: 40px;
    .popper__arrow {
        display: none;
    }
}
.job-more-opr-dropdown.el-dropdown-menu {
    margin-top: 0;
    padding: 0;
    .el-dropdown-menu__item {
        text-align: center;
    }

    .no-dropdown-tip {
        display: inline-block;
        padding: 10px;
    }
}
</style>
