<template>
    <el-dialog
        custom-class="update-job-status"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false">
        <div class="update-job-status-header" slot="title">
            <span class="header-title">
                更新职位状态
            </span>
            <div class="batch-update-btn-list">
                <span class="btn-update-btn text-operate-btn" @click="batchUpdateJobStatus(2)">批量暂停</span>
                <span class="btn-update-btn text-operate-btn" @click="batchUpdateJobStatus(4)">批量完成</span>
            </div>
        </div>
        <div class="update-job-status-wrapper" v-loading="loading">
            <div class="update-job-status-title">
                <img src="@src/assets/images/job_status_update.png">
                <p class="title-text">
                    新的一周开始了！: )<br>
                    你现在有{{jobList.length + earlierJobList.length}}个职位正在招聘中，快随手<span class="text-primary">更新职位状态</span>吧~
                </p>
            </div>
            <div class="update-job-status-list">
                <template v-if="jobList.length > 0">
                    <div
                        class="job-month-list"
                        :key="jobMonthIndex"
                        v-for="(jobMonth, jobMonthIndex) in jobList">
                        <div class="job-month-title">
                            <el-checkbox
                                v-model="jobMonth.isSelected"
                                @change="val => handleCheckAll(val, jobMonthIndex)"
                            ></el-checkbox>
                            <span class="job-month-title-text">
                                {{jobMonth.month}}月
                            </span>
                        </div>
                        <div
                            class="update-status-job-item"
                            :key="jobIndex"
                            v-for="(job, jobIndex) in jobMonth.listItem">
                            <el-checkbox
                                v-model="job.isSelected"
                                :key="jobIndex"
                                @change="val => handleCheckSingle(val, jobMonthIndex)">
                            </el-checkbox>
                            <div class="job-item-detail">
                                <span class="job-item-name text-ellipsis">
                                    {{job.name}}
                                </span>
                                <span class="job-item-customer text-ellipsis">
                                    {{job.firmShortName}}
                                </span>
                                <span class="job-item-recommend">
                                    <span>推 {{job.recommendCount}}</span>
                                    <span>面 {{job.interviewCount}}</span>
                                    <span>Offer {{job.offerCount}}</span>
                                </span>
                                <el-dropdown
                                    placement="bottom"
                                    class="job-item-status"
                                    @command="(val) => singelUpdateJob(job, 'jobStatus', val)">
                                    <span
                                        class="el-dropdown-link"
                                        :class="{'primary': job.status === 1}">
                                        {{jobStatusMap[job.status]}}
                                    </span>
                                    <el-dropdown-menu
                                        class="job-item-status-dropdown"
                                        slot="dropdown">
                                        <el-dropdown-item
                                            :key="jobStatusIndex"
                                            :command="jobStatus.value"
                                            v-show="showJobStatusOption(job.status, jobStatus.value)"
                                            v-for="(jobStatus, jobStatusIndex) in jobStatusList">
                                            {{jobStatus.text}}
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                                <span class="job-item-emergency">
                                    <span
                                        class="job-item-emerency-item"
                                        :class="{'emergency-active': job.emergencyDegree === 1}"
                                        @click="singelUpdateJob(job, 'emergencyDegree', 1)">
                                        紧急
                                    </span>
                                    <span
                                        class="job-item-emerency-item"
                                        :class="{'not-emergency-active': job.emergencyDegree === 0}"
                                        @click="singelUpdateJob(job, 'emergencyDegree', 0)">
                                        不紧急
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </template>
                <div
                    class="earlier-job-list-wrapper"
                    v-if="earlierJobList.length && isShowEarlierJob">
                    <div class="earlier-job-list-title">
                        <el-checkbox v-model="isAllEarlier" @change="handleCheckAllEarlier"></el-checkbox>
                        <span @click="handleEarlierJob">
                            查看更早职位（共{{earlierJobList.length}}个）
                            <i class="el-icon-arrow-up"></i>
                        </span>
                    </div>
                    <div class="earlier-job-list" v-if="isShowEarlierJob">
                        <div
                            class="update-status-job-item"
                            :key="jobIndex"
                            v-for="(job, jobIndex) in earlierJobList">
                            <el-checkbox
                                v-model="job.isSelected"
                                :key="jobIndex"
                                @change="handleCheckSingleEarlier">
                            </el-checkbox>
                            <div class="job-item-detail">
                                <span class="job-item-name">
                                    {{job.name}}
                                </span>
                                <span class="job-item-customer">
                                    {{job.firmShortName}}
                                </span>
                                <span class="job-item-recommend">
                                    <span>推 {{job.recommendCount}}</span>
                                    <span>面 {{job.interviewCount}}</span>
                                    <span>Offer {{job.offerCount}}</span>
                                </span>
                                <el-dropdown
                                    placement="bottom"
                                    class="job-item-status"
                                    @command="(val) => singelUpdateJob(job, 'jobStatus', val)">
                                    <span
                                        class="el-dropdown-link"
                                        :class="{'primary': job.status === 1}">
                                        {{jobStatusMap[job.status]}}
                                    </span>
                                    <el-dropdown-menu
                                        class="job-item-status-dropdown"
                                        slot="dropdown">
                                        <el-dropdown-item
                                            :key="jobStatusIndex"
                                            :command="jobStatus.value"
                                            v-show="showJobStatusOption(job.status, jobStatus.value)"
                                            v-for="(jobStatus, jobStatusIndex) in jobStatusList">
                                            {{jobStatus.text}}
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                                <span class="job-item-emergency">
                                    <span
                                        class="job-item-emerency-item emergency"
                                        :class="{'active': job.emergencyDegree === 1}"
                                        @click="singelUpdateJob(job, 'emergencyDegree', 1)">
                                        紧急
                                    </span>
                                    <span
                                        class="job-item-emerency-item not-emergency"
                                        :class="{'active': job.emergencyDegree === 0}"
                                        @click="singelUpdateJob(job, 'emergencyDegree', 0)">
                                        不紧急
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <span class="earlier-job-list-btn" @click="handleEarlierJob" v-if="!isShowEarlierJob">
            查看更早职位（共{{earlierJobList.length}}个）
            <i class="el-icon-arrow-down"></i>
        </span>
        <div class="dialog-footer" slot="footer">
            <el-button @click="handleCloseDialog">稍后再提醒我</el-button>
        </div>
    </el-dialog>
</template>

<script>
const jobStatusMap = {
    1: 'Live',
    2: '暂停',
    4: '完成'
}
export default {
    props: {
        jobList: {
            type: Array,
            default: () => {
                return {}
            }
        },
        earlierJobList: {
            type: Array,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            dialogVisible: false,
            loading: false,
            jobStatusMap: jobStatusMap,
            jobStatusList: [
                {
                    value: 1,
                    text: '恢复招聘中'
                }, {
                    value: 2,
                    text: '暂停'
                }, {
                    value: 4,
                    text: '完成'
                }
            ],
            isShowEarlierJob: false,
            isAllEarlier: false,
            selectedList: []
        }
    },
    methods: {
        show() {
            this.dialogVisible = true;
            if(!this.jobList.length) {
                this.isShowEarlierJob = true;
            }
        },
        getSelectedJob() {
            let idsList = [],
                subList = [],
                selectList = [];
            this.jobList.forEach(job => {
                if(job.isSelected) {
                    job.listItem.some((item, index) => {
                        selectList.push(item)
                        idsList.push({
                            jobId: item.id,
                            jobStatus: item.status,
                            emergencyDegree: item.emergencyDegree
                        })
                        if(index === job.listItem.length - 1) {
                            return true;
                        }
                    })
                } else {
                    subList = job.listItem.filter(item => item.isSelected);
                    if(subList.length) {
                        subList.some((item, index) => {
                            selectList.push(item)
                            idsList.push({
                                jobId: item.id,
                                jobStatus: item.status,
                                emergencyDegree: item.emergencyDegree
                            })
                            if(index === subList.length - 1) {
                                return true;
                            }
                        })
                    }
                }
            });
            subList = this.earlierJobList.filter(item => item.isSelected);
            if(subList.length) {
                subList.some((item, index) => {
                    selectList.push(item)
                    idsList.push({
                        jobId: item.id,
                        jobStatus: item.status,
                        emergencyDegree: item.emergencyDegree
                    })
                    if(index === subList.length - 1) {
                        return true;
                    }
                })
            }
            this.selectedList = selectList;
            return idsList;
        },
        batchUpdateJobStatus(value) {
            let list = this.getSelectedJob();
            if(list.length === 0) {
                shortTips('请至少选择一个职位！');
                return false;
            } else {
                this.$confirm(`确定${value === 2 ? '暂停' : '完成'}这${list.length}个职位？`, '提示', {
                    confirmButtonText: value === 2 ? '暂停' : '完成',
                    cancelButtonText: "取消"
                }).then(() => {
                    list.forEach(job => {
                        job.jobStatus = value;
                    })
                    this.handleUpdateJob(list, () => {
                        this.selectedList.forEach(job => {
                            this.$set(job, 'status', value)
                        })
                        this.handleCloseDialog();
                    });
                }).catch(() => {})
            }
        },
        singelUpdateJob(job, type, value) {
            if(type === 'emergencyDegree' && job.emergencyDegree === value) {
                return false;
            }
            let item = {
                jobId: job.id,
                jobStatus: job.status,
                emergencyDegree: job.emergencyDegree
            };
            item[type] = value;
            this.handleUpdateJob([item], () => {
                if(type === 'jobStatus') {
                    this.$set(job, 'status', value)
                } else {
                    this.$set(job, type, value)
                }
            })
        },
        handleUpdateJob(list, cb) {
            this.loading = true;
            _request({
                url: '/Headhunting/MyCompany/ChangePopUpWindowRecruitingJob',
                method: 'POST',
                data: {
                    jobSituationList: list
                },
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(res => {
                this.loading = false;
                shortTips("职位状态更新成功");
                cb && cb();
            }).catch(err => {
                this.loading = false;
                console.log(err)
            })
        },
        handleCloseDialog() {
            this.dialogVisible = false;
            this.selectedList = [];
        },
        handleCheckAll(val, monthIndex) {
            this.jobList[monthIndex].listItem.forEach(job => {
                this.$set(job, 'isSelected', val);
            });
        },
        handleCheckSingle(val, monthIndex) {
            if(this.jobList[monthIndex].isSelected && !val) {
                this.$set(this.jobList[monthIndex], 'isSelected', false);
            }
            if(!this.jobList[monthIndex].isSelected && val) {
                let isAllSelected = this.jobList[monthIndex].listItem.every(job => job.isSelected);
                if(isAllSelected) {
                    this.$set(this.jobList[monthIndex], 'isSelected', true);
                }
            }
        },
        showJobStatusOption(jobStatus, status) {
            if(jobStatus === 1) {
                return status !== jobStatus;
            } else if(jobStatus === 2) {
                return status === 1;
            } else if(jobStatus === 4) {
                return status === 2;
            }
        },
        handleEarlierJob() {
            this.isShowEarlierJob = !this.isShowEarlierJob;
        },
        handleCheckAllEarlier(val) {
            this.earlierJobList.forEach(job => {
                this.$set(job, 'isSelected', val);
            })
        },
        handleCheckSingleEarlier(val) {
            if(this.isAllEarlier && !val) {
                this.isAllEarlier = false;
            }
            if(!this.isAllEarlier && val) {
                let isAllSelected = this.earlierJobList.every(job => job.isSelected);
                if(isAllSelected) {
                    this.isAllEarlier = true;
                }
            }
        }
    }
}
</script>

<style lang="scss" scope>
    .update-job-status.el-dialog {
        width: 870px;
        .text-ellipsis {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .update-job-status-header {
            position: relative;
            .header-title {
                color: #333;
                font-size: 18px;
            }
            .batch-update-btn-list {
                color: $primary;
                line-height: 20px;
                position: absolute;
                right: 44px;
                bottom: 12px;
                .btn-update-btn + .btn-update-btn {
                    margin-left: 20px;
                }
            }
        }
        .el-dialog__body {
            overflow: visible;
            position: relative;
        }
        .update-job-status-wrapper {
            max-height: 560px;
            overflow: auto;
            position: relative;
        }
        .el-checkbox {
            margin-right: 10px;
            margin-bottom: 0;
        }
        .update-job-status-title {
            height: 118px;
            display: flex;
            align-items: center;
            img {
                width: 116px;
                height: 100%;
            }
            .title-text {
                margin-left: 146px;
                margin-bottom: 0;
                color: #666;
                line-height: 30px;
                .text-primary {
                    color: $primary;
                    font-size: 16px;
                }
            }
        }
        .update-job-status-list {
            padding-left: 20px;
            .job-month-list {
                .job-month-title {
                    color: $primary;
                    margin: 15px 0;
                    font-size: 16px;
                    line-height: 20px;
                    .job-month-title-text {
                        display: inline-block;
                    }
                }
            }
        }
        .update-status-job-item {
            color: #333;
            line-height: 36px;
            margin-bottom: 10px;
            .job-item-detail {
                display: inline-flex;
                .job-item-name {
                    width: 200px;
                    max-width: 200px;
                }
                .job-item-customer {
                    width: 150px;
                    max-width: 150px;
                }
                .job-item-recommend {
                    width: 200px;
                    span + span {
                        margin-left: 10px;
                    }
                }
                .job-item-status {
                    width: 50px;
                    margin-right: 25px;
                    .el-dropdown-link {
                        padding: 0 10px;
                        height: 18px;
                        line-height: 18px;
                        display: inline-block;
                        border-radius: 9px;
                        color: #fff;
                        background-color: #ccc;
                        font-size: 12px;
                        &.primary {
                            background-color: $primary;
                        }
                    }
                }
                .job-item-emergency {
                    width: 120px;
                    .job-item-emerency-item {
                        font-size: 12px;
                        padding: 0 8px;
                        height: 18px;
                        line-height: 16px;
                        display: inline-block;
                        border-radius: 9px;
                        color: #9b9b9b;
                        cursor: pointer;
                        border: 1px solid #b9b9b9;
                        &.emergency-active {
                            cursor: default;
                            color: #ff493c;
                            border: 1px solid #ff493c;
                        }
                        &.not-emergency-active {
                            cursor: default;
                            color: $primary;
                            border: 1px solid $primary;
                        }
                        & + .job-item-emerency-item {
                            margin-left: 12px;
                        }
                    }
                }
            }
        }
        .earlier-job-list-wrapper {
            .earlier-job-list-title {
                cursor: pointer;
                margin-bottom: 15px;
                color: $primary;
                font-size: 16px;
                line-height: 20px;
                .el-icon-arrow-up {
                    margin-left: 10px;
                    color: #ccc;
                }
                & > span {
                    display: inline-block;
                }
            }
        }
        .earlier-job-list-btn {
            cursor: pointer;
            position: absolute;
            bottom: 0;
            left: 40px;
            font-size: 16px;
            line-height: 20px;
            color: $primary;
            .el-icon-arrow-down {
                margin-left: 10px;
                color: #ccc;
            }
        }
        .dialog-footer {
            .el-button {
                padding: 0 14px;
                width: auto;
            }
        }
    }
</style>

<style lang="scss">
.job-item-status-dropdown.el-dropdown-menu {
    margin-top: 0;
    padding: 0;
}
</style>
