var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "job-list" },
    [
      !_vm.isImJobList && !_vm.isProgramList
        ? _c("job-search", {
            ref: "jobSearch",
            attrs: { jobNumber: _vm.pager.total, orderParams: _vm.orderParams },
            on: {
              updateOrderParams: (params) =>
                _vm.$emit("updateOrderParams", params),
              updateParams: (updateOrderType, searchLabelList) =>
                _vm.search(false, updateOrderType, searchLabelList),
            },
          })
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "job-content",
        },
        [
          _vm.jobList.length > 0
            ? _c("div", { staticClass: "job-list-page" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading,
                        expression: "listLoading",
                      },
                    ],
                    staticClass: "job-list-wrapper",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "list-container",
                        attrs: {
                          "data-spm-area-id": "A_job_search_result_list",
                        },
                      },
                      _vm._l(_vm.jobList, function (job, index) {
                        return _c("job-item", {
                          key: index,
                          ref: "jobItem",
                          refInFor: true,
                          attrs: {
                            "data-spm-e-data": _vm.listItemSpmData(job, index),
                            "data-spm-e-id": `L_job_search_result_list.${
                              _vm.pager.take
                            }xP${_vm.pager.current}-${index + 1}`,
                            job: job,
                            isMyFirm: _vm.isMyFirm,
                            currentIdx: index,
                            activeIdx: _vm.activeIdx,
                          },
                          on: {
                            changeJobStatus: _vm.changeJobStatus,
                            "update-active-index": _vm.handleUpdateIndex,
                            "grab-success-callback": _vm.handleGrabCallback,
                          },
                        })
                      }),
                      1
                    ),
                    !_vm.isImJobList && _vm.jobList.length > 0
                      ? _c(
                          "el-pagination",
                          {
                            staticClass: "job-list-pagination",
                            attrs: {
                              "current-page": _vm.pager.current,
                              "page-sizes": [10, 30, 50],
                              "page-size": _vm.pager.take,
                              layout: "total, sizes, prev, pager, next, slot",
                              total: _vm.pager.total,
                            },
                            on: {
                              "size-change": _vm.handleSizeChange,
                              "current-change": _vm.handleCurrentChange,
                            },
                          },
                          [
                            _c("span", { staticClass: "pagination-text" }, [
                              _c(
                                "span",
                                [
                                  _vm._v(
                                    "\n                            前往\n                            "
                                  ),
                                  _c("el-input", {
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.handlePagerJump(
                                          _vm.pagerJump
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.pagerJump,
                                      callback: function ($$v) {
                                        _vm.pagerJump = $$v
                                      },
                                      expression: "pagerJump",
                                    },
                                  }),
                                  _vm._v(
                                    "\n                            页\n                        "
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlePagerJump(_vm.pagerJump)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            跳转\n                        "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.jobList.length > 0
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "job-content-page",
                },
                [
                  _c("div", { staticClass: "content-top" }, [
                    _c("div", { staticClass: "content-title-row" }, [
                      _c("div", { staticClass: "crow-l" }, [
                        _c("span", { staticClass: "title_loc" }, [
                          _c(
                            "span",
                            {
                              staticClass: "name ell",
                              attrs: { title: _vm.jobInfo.name },
                            },
                            [_vm._v(_vm._s(_vm.jobInfo.name) + " ")]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "loca ell",
                              attrs: { title: _vm.jobInfo.location },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.jobInfo.location
                                    ? `[${_vm.jobInfo.location}]`
                                    : ""
                                )
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "crow-r" },
                        [
                          _vm.jobInfo.isMyJob && _vm.jobInfo.status != 4
                            ? _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "dark",
                                    content: "修改职位状态",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "edit",
                                      on: { click: _vm.handleJobStatus },
                                    },
                                    [
                                      _c("font-icon", {
                                        attrs: { href: "#icon-bianji" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "el-dropdown",
                            { attrs: { trigger: "click" } },
                            [
                              _c(
                                "span",
                                { staticClass: "more" },
                                [
                                  _c("font-icon", {
                                    attrs: { href: "#icon-set" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-dropdown-menu",
                                {
                                  staticClass: "more-dropdown-menu no-arrow",
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                [
                                  _c(
                                    "el-dropdown-item",
                                    [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "left",
                                            width: "245",
                                            "popper-class": "share-popover",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "item-wrap",
                                              attrs: { slot: "reference" },
                                              slot: "reference",
                                            },
                                            [
                                              _c("font-icon", {
                                                staticClass: "item-icon",
                                                attrs: { href: "#icon-share1" },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "item-text" },
                                                [_vm._v("分享")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "share-btn",
                                              on: { click: _vm.shareToIM },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require("#/assets/images/instantMessage/private_message.png"),
                                                },
                                              }),
                                              _vm._v(
                                                " 私信和群分享\n                                        "
                                              ),
                                            ]
                                          ),
                                          !_vm.isFromAuthorization &&
                                          _vm.jobInfo.recruiterFirmId ===
                                            _vm.user.firmId
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass: "share-btn",
                                                  on: {
                                                    click: _vm.shareToWeChat,
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: require("#/assets/images/instantMessage/wechat.png"),
                                                    },
                                                  }),
                                                  _vm._v(
                                                    "\n                                            分享到微信\n                                        "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          !_vm.isFromAuthorization &&
                                          _vm.jobInfo.recruiterFirmId ===
                                            _vm.user.firmId
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass: "share-btn",
                                                  on: {
                                                    click: _vm.addToMyJobCard,
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: require("#/assets/images/instantMessage/user-card.png"),
                                                    },
                                                  }),
                                                  _vm._v(
                                                    "\n                                            添加到我的职位名片\n                                        "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.jobInfo.recruiterFirmId ==
                                    _vm.user.firmId &&
                                  _vm.jobInfo.customerDepartmentId &&
                                  _vm.jobInfo.customerDepartmentId != "0" &&
                                  _vm.jobInfo.status != 4
                                    ? _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.showBindProjectDialog.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-wrap" },
                                            [
                                              _c("font-icon", {
                                                staticClass: "item-icon",
                                                attrs: { href: "#icon-survey" },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "item-text" },
                                                [_vm._v("绑定项目")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  (_vm.currentJobType != 3 &&
                                    _vm.jobInfo.status == 1 &&
                                    _vm.jobInfo.canGetOrder &&
                                    !_vm.jobInfo.isMyOrder &&
                                    !_vm.jobInfo.isMyJob) ||
                                  ([3, 5].includes(_vm.currentJobType) &&
                                    !_vm.jobInfo.isMyOrder)
                                    ? _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.acceptClick.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-wrap" },
                                            [
                                              _c("font-icon", {
                                                staticClass: "item-icon",
                                                attrs: {
                                                  href: "#icon-operation",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "item-text" },
                                                [_vm._v("抢单")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  (_vm.jobInfo.isMyJob ||
                                    _vm.jobInfo.canPublishToMarket) &&
                                  !_vm.jobInfo.isSleeping &&
                                  !_vm.isFromAuthorization &&
                                  _vm.jobInfo.recruiterId == _vm.user.id &&
                                  ((_vm.jobInfo.isMyJob &&
                                    _vm.jobInfo.status == 4) ||
                                    (_vm.jobInfo.canPublishToMarket &&
                                      _vm.jobInfo.isPublic) ||
                                    _vm.jobInfo.canDelete)
                                    ? _c(
                                        "el-dropdown-item",
                                        [
                                          _c("my-job-operate", {
                                            attrs: {
                                              jobInfo: _vm.jobInfo,
                                              customerId: _vm.firmId,
                                              customerDepartmentId:
                                                _vm.jobInfo
                                                  .customerDepartmentId,
                                              isAdministrator:
                                                _vm.user.isAdministrator,
                                            },
                                            on: { refresh: _vm.refresh },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.jobInfo.isMyJob &&
                                  _vm.user.isCFUser &&
                                  !_vm.jobInfo.publicJobId &&
                                  _vm.jobInfo.status == 1
                                    ? _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.showFamousCompanyDialog.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-wrap" },
                                            [
                                              _c("font-icon", {
                                                staticClass: "item-icon",
                                                attrs: {
                                                  href: "#icon-electronics",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "item-text" },
                                                [_vm._v("发布到大厂机会")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  ((_vm.jobInfo.jobType == 2 &&
                                    _vm.jobInfo.isPublic == false) ||
                                    _vm.jobInfo.acceptedHeadhuntersCount ==
                                      0) &&
                                  _vm.canEdit
                                    ? _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.handleEdit.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-wrap" },
                                            [
                                              _c("font-icon", {
                                                staticClass: "item-icon",
                                                attrs: { href: "#icon-edit1" },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "item-text" },
                                                [_vm._v("编辑")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.jobInfo.isSleeping &&
                                  (_vm.jobInfo.isMyJob ||
                                    (_vm.isHeadhunter && _vm.jobInfo.isMyOrder))
                                    ? _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.showTransferJobDialog.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-wrap" },
                                            [
                                              _c("font-icon", {
                                                staticClass: "item-icon",
                                                attrs: {
                                                  href: "#icon-export1",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "item-text" },
                                                [_vm._v("移交")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.jobInfo.isMyJob &&
                                  _vm.jobInfo.approvedStatus != 2 &&
                                  _vm.jobInfo.status != 4
                                    ? _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.handleBindDepartment.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-wrap" },
                                            [
                                              _c("font-icon", {
                                                staticClass: "item-icon",
                                                attrs: {
                                                  href: "#icon-zuzhijiagou",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "item-text" },
                                                [_vm._v("绑定组织架构")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "cont-row" },
                      [
                        _vm.jobInfo.activityTags?.length > 0 ||
                        [3, 5].includes(_vm.jobInfo.jobType)
                          ? _c(
                              "span",
                              { staticClass: "tags" },
                              [
                                _vm._l(
                                  _vm.jobInfo.activityTags,
                                  function (item) {
                                    return _c(
                                      "el-tooltip",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              item.id !== "StandstillJob" &&
                                              item.id !=
                                                "FirstOrderActivityJob",
                                            expression:
                                              "item.id !== 'StandstillJob' && item.id != 'FirstOrderActivityJob'",
                                          },
                                        ],
                                        key: item.id,
                                        attrs: {
                                          effect: "light",
                                          placement: "right",
                                          disabled: !item.description,
                                          "popper-class": "job-tag-description",
                                        },
                                      },
                                      [
                                        _c("div", {
                                          attrs: { slot: "content" },
                                          domProps: {
                                            innerHTML: _vm._s(item.description),
                                          },
                                          slot: "content",
                                        }),
                                        item.id == "JobEmergency"
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "JobEmergency",
                                                attrs: { title: "急聘" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "hurry" },
                                                  [
                                                    _c("font-icon", {
                                                      attrs: {
                                                        href: "#icon-shandian",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(
                                                  "急聘\n                            "
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "span",
                                              {
                                                staticClass: "tag",
                                                class: `job-tag-${
                                                  _vm.jobActivityTag[item.id]
                                                    .theme
                                                }`,
                                                attrs: { title: item.name },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    item.name == "New"
                                                      ? "最新"
                                                      : item.name
                                                  )
                                                ),
                                              ]
                                            ),
                                      ]
                                    )
                                  }
                                ),
                                _vm.jobInfo.status == 1 &&
                                _vm.jobInfo.approvedStatus != "2"
                                  ? _c("span", { staticClass: "recruiting" }, [
                                      _c(
                                        "span",
                                        { staticClass: "hurry" },
                                        [
                                          _c("font-icon", {
                                            attrs: { href: "#icon-a-bianzu11" },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(
                                        "招聘中\n                        "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.jobInfo.jobType == 3
                                  ? _c(
                                      "span",
                                      { staticClass: "tag job-tag-info" },
                                      [_vm._v("HR")]
                                    )
                                  : _vm._e(),
                                _vm.jobInfo.jobType == 5
                                  ? _c(
                                      "span",
                                      { staticClass: "tag job-tag-info" },
                                      [_vm._v("代运营")]
                                    )
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                        ![3, 5].includes(_vm.currentJobType)
                          ? [
                              _c("span", { staticClass: "percent" }, [
                                _vm._v("佣金 " + _vm._s(_vm.commissionValue)),
                              ]),
                            ]
                          : _vm._e(),
                        [3, 5].includes(_vm.currentJobType) &&
                        _vm.jobInfo.commissionType == "0"
                          ? [
                              _c("span", { staticClass: "percent" }, [
                                _vm._v(
                                  "佣金 \n                            " +
                                    _vm._s(
                                      _vm.jobInfo.minSalary == 0 &&
                                        _vm.jobInfo.maxSalary == 0
                                        ? `按比例(${_vm.jobInfo.commissionValue}%)`
                                        : _vm.jobInfo.commissionValueText
                                    ) +
                                    "\n                        "
                                ),
                              ]),
                            ]
                          : _vm._e(),
                        [3, 5].includes(_vm.currentJobType) &&
                        _vm.jobInfo.commissionType == "1"
                          ? [
                              _c("span", { staticClass: "percent" }, [
                                _vm._v(
                                  "佣金 " +
                                    _vm._s(
                                      _vm.jobInfo.commissionTypeValue + "K"
                                    )
                                ),
                              ]),
                            ]
                          : _vm._e(),
                        _c("span", { staticClass: "promise-time" }, [
                          _vm._v("\n                        保证期"),
                          _c("span", { staticClass: "month" }, [
                            _vm._v(_vm._s(_vm.jobInfo.guaranteePeriod)),
                          ]),
                          _vm._v("个月 \n                    "),
                        ]),
                      ],
                      2
                    ),
                    _c("div", { staticClass: "cont-row" }, [
                      _vm.currentJobType == 3
                        ? _c("span", { staticClass: "salary" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.jobInfo.minSalary == 0 &&
                                    _vm.jobInfo.maxSalary == 0
                                    ? "面议"
                                    : _vm.jobInfo.minSalary +
                                        "K-" +
                                        _vm.jobInfo.maxSalary +
                                        "K"
                                ) +
                                "\n                    "
                            ),
                          ])
                        : _c("span", { staticClass: "salary" }, [
                            _vm._v(
                              _vm._s(
                                _vm.jobInfo.salary == "0K-0K"
                                  ? "面议"
                                  : _vm.jobInfo.salary
                              )
                            ),
                          ]),
                      _c("span", { staticClass: "icon-wrap" }, [
                        _c(
                          "span",
                          { staticClass: "info-icon" },
                          [_c("font-icon", { attrs: { href: "#icon-time1" } })],
                          1
                        ),
                        _vm._v(
                          _vm._s(_vm.jobInfo.yearOfExperience) +
                            "\n                    "
                        ),
                      ]),
                      _c("span", { staticClass: "icon-wrap" }, [
                        _c(
                          "span",
                          { staticClass: "info-icon" },
                          [_c("font-icon", { attrs: { href: "#icon-work" } })],
                          1
                        ),
                        _vm._v(
                          _vm._s(_vm.jobInfo.degree) + "\n                    "
                        ),
                      ]),
                      _c("span", { staticClass: "icon-wrap" }, [
                        _c(
                          "span",
                          { staticClass: "info-icon" },
                          [_c("font-icon", { attrs: { href: "#icon-user1" } })],
                          1
                        ),
                        _vm._v(
                          "需" +
                            _vm._s(_vm.jobInfo.recruitingCount) +
                            "人\n                    "
                        ),
                      ]),
                      _vm.currentItemInfo.categoryName
                        ? _c("span", { staticClass: "icon-wrap" }, [
                            _c(
                              "span",
                              { staticClass: "info-icon" },
                              [
                                _c("font-icon", {
                                  attrs: { href: "#icon-modular" },
                                }),
                              ],
                              1
                            ),
                            _c("span", { staticClass: "loc ell" }, [
                              _vm._v(_vm._s(_vm.currentItemInfo.categoryName)),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                    [3, 5].includes(_vm.currentJobType)
                      ? _c("div", { staticClass: "nums-wrap" }, [
                          _c("div", { staticClass: "num-cell" }, [
                            _c("span", { staticClass: "count" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.jobInfo.floatingInfo
                                    ? _vm.jobInfo.floatingInfo
                                        .recommendationCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("推荐\n                    "),
                          ]),
                          _c("div", { staticClass: "num-cell" }, [
                            _c("span", { staticClass: "count" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.jobInfo.floatingInfo
                                    ? _vm.jobInfo.floatingInfo.interviewCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("面试\n                    "),
                          ]),
                          _c("div", { staticClass: "num-cell" }, [
                            _c("span", { staticClass: "count" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.jobInfo.floatingInfo
                                    ? _vm.jobInfo.floatingInfo.offerCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("Offer\n                    "),
                          ]),
                          _c("div", { staticClass: "num-cell" }, [
                            _c("span", { staticClass: "count" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.jobInfo.floatingInfo
                                    ? _vm.jobInfo.floatingInfo.acceptedCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("接受\n                    "),
                          ]),
                          _c("div", { staticClass: "num-cell" }, [
                            _c("span", { staticClass: "count" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.jobInfo.floatingInfo
                                    ? _vm.jobInfo.floatingInfo.rejectedCount
                                    : 0
                                )
                              ),
                            ]),
                            _vm._v("拒绝\n                    "),
                          ]),
                        ])
                      : _c("div", { staticClass: "nums-wrap" }, [
                          _c("div", { staticClass: "num-cell" }, [
                            _c("span", { staticClass: "count" }, [
                              _vm._v(
                                _vm._s(_vm.jobInfo.recommendationCount || 0)
                              ),
                            ]),
                            _vm._v("推荐\n                    "),
                          ]),
                          _c("div", { staticClass: "num-cell" }, [
                            _c("span", { staticClass: "count" }, [
                              _vm._v(_vm._s(_vm.jobInfo.interviewCount || 0)),
                            ]),
                            _vm._v("面试\n                    "),
                          ]),
                          _c("div", { staticClass: "num-cell" }, [
                            _c("span", { staticClass: "count" }, [
                              _vm._v(_vm._s(_vm.jobInfo.offerCount || 0)),
                            ]),
                            _vm._v("Offer\n                    "),
                          ]),
                          _c("div", { staticClass: "num-cell" }, [
                            _c("span", { staticClass: "count" }, [
                              _vm._v(_vm._s(_vm.jobInfo.acceptedCount || 0)),
                            ]),
                            _vm._v("接受\n                    "),
                          ]),
                          _c("div", { staticClass: "num-cell" }, [
                            _c("span", { staticClass: "count" }, [
                              _vm._v(_vm._s(_vm.jobInfo.rejectedCount || 0)),
                            ]),
                            _vm._v("拒绝\n                    "),
                          ]),
                        ]),
                    _vm.currentJobType == 3
                      ? _c("div", { staticClass: "customer-infos" }, [
                          _c(
                            "a",
                            {
                              staticClass: "pic",
                              class: {
                                "pointer-event-none":
                                  !_vm.jobInfo.canViewInternalInformation,
                              },
                              attrs: {
                                href: `/#/Customer/${_vm.firmId}`,
                                target: "_blank",
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src:
                                    _vm.jobInfo.firmLogoUrl ||
                                    "//hstatic.hirede.com/lbd/images/firm-default-avatar.jpg",
                                },
                              }),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "infos",
                              attrs: {
                                title:
                                  _vm.jobInfo.firmShortName +
                                  "-" +
                                  _vm.jobInfo.weComDepartment,
                              },
                            },
                            [
                              _c("span", { staticClass: "name" }, [
                                _vm._v(_vm._s(_vm.jobInfo.firmShortName)),
                              ]),
                            ]
                          ),
                        ])
                      : _c("div", { staticClass: "customer-infos" }, [
                          _c(
                            "a",
                            {
                              staticClass: "pic",
                              class: {
                                "pointer-event-none":
                                  !_vm.jobInfo.canViewInternalInformation,
                              },
                              attrs: {
                                href: `/#/Customer/${_vm.firmId}`,
                                target: "_blank",
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@src/assets/images/default-company.png"),
                                },
                              }),
                            ]
                          ),
                          [3, 4, 5].includes(_vm.jobInfo.jobType)
                            ? _c(
                                "div",
                                { staticClass: "infos" },
                                [
                                  _vm.jobInfo.jobType == 4
                                    ? [
                                        _c("span", { staticClass: "name" }, [
                                          _vm._v(
                                            _vm._s(_vm.jobInfo.customerName)
                                          ),
                                        ]),
                                      ]
                                    : [
                                        _c("span", { staticClass: "name" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.jobInfo.definedCustomerName
                                            )
                                          ),
                                        ]),
                                      ],
                                  _vm.jobInfo.bindingProjects &&
                                  Object.keys(_vm.jobInfo.bindingProjects)
                                    .length > 0 &&
                                  _vm.jobInfo.recruiterFirmId == _vm.user.firmId
                                    ? _c("i", { staticClass: "center-line" })
                                    : _vm._e(),
                                  _vm.jobInfo.bindingProjects &&
                                  Object.keys(_vm.jobInfo.bindingProjects)
                                    .length > 0 &&
                                  _vm.jobInfo.recruiterFirmId == _vm.user.firmId
                                    ? _c(
                                        "span",
                                        { staticClass: "name" },
                                        [
                                          _vm._l(
                                            _vm.jobInfo.bindingProjects,
                                            function (value, name, index) {
                                              return [
                                                index != 0
                                                  ? _c(
                                                      "i",
                                                      { key: index + name },
                                                      [_vm._v("、")]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "span",
                                                  {
                                                    key: name,
                                                    staticClass:
                                                      "project-info-item",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        value.replace(
                                                          "$#$",
                                                          "-"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    : _c("span", { staticClass: "name" }, [
                                        _vm._v("还未绑定项目"),
                                      ]),
                                ],
                                2
                              )
                            : _c(
                                "div",
                                { staticClass: "infos" },
                                [
                                  _c("span", { staticClass: "name" }, [
                                    _vm._v(_vm._s(_vm.customerName)),
                                  ]),
                                  _vm.jobInfo.bindingProjects &&
                                  Object.keys(_vm.jobInfo.bindingProjects)
                                    .length > 0 &&
                                  _vm.jobInfo.recruiterFirmId == _vm.user.firmId
                                    ? _c("i", { staticClass: "center-line" })
                                    : _vm._e(),
                                  _vm.jobInfo.bindingProjects &&
                                  Object.keys(_vm.jobInfo.bindingProjects)
                                    .length > 0 &&
                                  _vm.jobInfo.recruiterFirmId == _vm.user.firmId
                                    ? _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            "popper-class":
                                              "bind-project-popover",
                                            placement: "bottom",
                                            trigger: "hover",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "project-name ell",
                                              attrs: { slot: "reference" },
                                              slot: "reference",
                                            },
                                            _vm._l(
                                              _vm.jobInfo.bindingProjects,
                                              function (value, name, index) {
                                                return _c(
                                                  "span",
                                                  { key: "name_" + index },
                                                  [
                                                    index != 0
                                                      ? _c(
                                                          "i",
                                                          { key: index + name },
                                                          [_vm._v("、")]
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "project-info-item",
                                                        attrs: {
                                                          target: "_blank",
                                                          href: `/#/ProjectDetail/${name}`,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            value.replace(
                                                              "$#$",
                                                              "-"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "name" },
                                            _vm._l(
                                              _vm.jobInfo.bindingProjects,
                                              function (value, name, index) {
                                                return _c(
                                                  "span",
                                                  { key: name },
                                                  [
                                                    index != 0
                                                      ? _c(
                                                          "i",
                                                          { key: index + name },
                                                          [_vm._v("、")]
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "project-info-item",
                                                        attrs: {
                                                          target: "_blank",
                                                          href: `/#/ProjectDetail/${name}`,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            value.replace(
                                                              "$#$",
                                                              "-"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                        ]),
                  ]),
                  _c("div", { staticClass: "jd-content" }, [
                    _c("div", { staticClass: "block" }, [
                      _c("p", { staticClass: "tit" }, [
                        _vm._v(
                          "\n                        职位详情：\n                    "
                        ),
                      ]),
                      !_vm.jobInfo.isMyJob &&
                      _vm.jobInfo.firmLabels &&
                      _vm.jobInfo.firmLabels.length > 0 &&
                      _vm.currentJobType != 3
                        ? _c(
                            "div",
                            { staticClass: "firm-label-list" },
                            _vm._l(
                              _vm.jobInfo.firmLabels,
                              function (label, index) {
                                return _c(
                                  "span",
                                  {
                                    key: "label_" + index,
                                    staticClass: "firm-label-item",
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(label) +
                                        "\n                        "
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                      _c("pre", {
                        staticClass: "pre-wrap",
                        domProps: {
                          textContent: _vm._s(_vm.jobInfo.description),
                        },
                      }),
                    ]),
                    ![3, 5].includes(_vm.currentJobType)
                      ? _c("div", { staticClass: "block" }, [
                          _c("p", { staticClass: "tit" }, [
                            _vm._v(
                              "\n                        职位分析：\n                    "
                            ),
                          ]),
                          _vm.jobInfo.isCooperativeJob && _vm.jobInfo.isMyJob
                            ? _c("div", { staticClass: "job-share-board" }, [
                                _c(
                                  "p",
                                  { staticClass: "job-share-board-msg" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "text-primary font-bold" },
                                      [_vm._v("【本职位由我公司发布】")]
                                    ),
                                    _vm._v(
                                      "\n                            内部佣金：" +
                                        _vm._s(
                                          _vm.jobInfo.internalCommissionValue
                                        ) +
                                        "，保证期：" +
                                        _vm._s(
                                          _vm.jobInfo.internalGuaranteePeriod
                                        ) +
                                        "，内部退款条款按客户合同约定。\n                        "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _c("pre", {
                            staticClass: "pre-wrap",
                            domProps: {
                              textContent: _vm._s(_vm.jobInfo.analysis),
                            },
                          }),
                        ])
                      : _vm._e(),
                    ![3, 5].includes(_vm.currentJobType)
                      ? _c("div", { staticClass: "block" }, [
                          _vm.jobInfo.acceptedHeadhuntersCount > 0
                            ? _c("p", { staticClass: "tit" }, [
                                _c("span", { staticClass: "text-primary" }, [
                                  _vm._v(
                                    _vm._s(_vm.jobInfo.acceptedHeadhuntersCount)
                                  ),
                                ]),
                                _vm._v("位猎头抢单：\n                    "),
                              ])
                            : _c("p", { staticClass: "tit" }, [
                                _vm._v("还没有猎头抢单"),
                              ]),
                          _vm.jobInfo.acceptedHeadhuntersCount > 0
                            ? _c(
                                "div",
                                { staticClass: "accept-headhunters" },
                                _vm._l(
                                  _vm.jobInfo.acceptedHeadhunters,
                                  function (item) {
                                    return _c("avatar", {
                                      key: item.id,
                                      staticClass:
                                        "accepted-headhunters-avatar",
                                      attrs: {
                                        size: "md",
                                        src: item.avatarUrl,
                                        userId: item.id,
                                        enableCard: true,
                                        enableLink: true,
                                      },
                                    })
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "infos-card" }, [
                      _c(
                        "div",
                        { staticClass: "user-pic" },
                        [
                          _c("avatar", {
                            staticClass: "pic",
                            attrs: {
                              size: "sm",
                              src:
                                _vm.currentJobType == 3
                                  ? _vm.jobInfo.recruiter
                                    ? _vm.jobInfo.recruiter.avatarUrl +
                                      "?size=60"
                                    : ""
                                  : _vm.jobInfo.recruiterAvatarUrl + "?size=60",
                              userId:
                                _vm.currentJobType == 3
                                  ? _vm.jobInfo.recruiter
                                    ? _vm.jobInfo.recruiter.userId
                                    : ""
                                  : _vm.jobInfo.recruiterId,
                              enableCard: true,
                              enableLink: true,
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "user-info" }, [
                        _c("p", { staticClass: "name" }, [
                          _vm._v(
                            _vm._s(
                              _vm.currentJobType == 3
                                ? _vm.jobInfo.recruiter
                                  ? _vm.jobInfo.recruiter.realName
                                  : ""
                                : _vm.jobInfo.recruiterRealName
                            ) +
                              "@" +
                              _vm._s(
                                _vm.currentJobType == 3
                                  ? _vm.jobInfo.recruiter
                                    ? _vm.jobInfo.recruiter.nickname
                                    : ""
                                  : _vm.jobInfo.recruiterNickname
                              )
                          ),
                        ]),
                        _c("p", { staticClass: "firm-info" }, [
                          _vm._v(
                            _vm._s(
                              _vm.currentJobType == 3
                                ? _vm.jobInfo.recruiter
                                  ? _vm.jobInfo.recruiter.firmName
                                  : ""
                                : _vm.jobInfo.recruiterFirmShortName
                            ) +
                              " " +
                              _vm._s(
                                _vm.currentJobType == 3
                                  ? _vm.jobInfo.recruiter
                                    ? _vm.jobInfo.recruiter.title
                                    : ""
                                  : _vm.jobInfo.recruiterTitle
                              )
                          ),
                        ]),
                        _c("p", { staticClass: "time" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("createDateFilter")(_vm.jobInfo.created)
                            ) + " 发布"
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "infos-right" }, [
                        !_vm.jobInfo.isMyJob
                          ? _c(
                              "button",
                              {
                                staticClass: "contact-btn",
                                on: {
                                  click: function ($event) {
                                    _vm.currentJobType == 3
                                      ? _vm.contactRecruiter(
                                          _vm.jobInfo.recruiter.unionId
                                        )
                                      : _vm.contactRecruiter(
                                          _vm.jobInfo.recruiterUnionId
                                        )
                                  },
                                },
                              },
                              [_vm._v("联系发布者")]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _c("div", { staticClass: "bottom-wrap" }, [
                      _c(
                        "button",
                        {
                          staticClass: "bottom-btn",
                          on: { click: _vm.jumpJobDetail },
                        },
                        [_vm._v("查看更多信息")]
                      ),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.jobList.length == 0
            ? _c("div", { staticClass: "page-empty" }, [
                _c("img", {
                  attrs: {
                    src: require("@src/assets/images/jobList/empty-bg@2x.png"),
                  },
                }),
                _c("p", { staticClass: "des" }, [_vm._v("没有搜索到任何职位")]),
              ])
            : _vm._e(),
        ]
      ),
      _c("job-status-dialog", {
        ref: "jobStatusDialog",
        on: {
          "update-item": _vm.updateItem,
          showProjectCreateDialog: _vm.showProjectCreateDialog,
        },
      }),
      _c("project-create-dialog", {
        ref: "projectCreateDialog",
        on: { "refresh-project-list": _vm.refreshPorjectList },
      }),
      _vm.jobInfo.isMyJob
        ? _c("bind-department-dialog", {
            ref: "bindDepartment",
            attrs: { jobInfo: _vm.jobInfo, customerId: _vm.firmId },
            on: { "bind-department-confirm": _vm.refresh },
          })
        : _vm._e(),
      _c("job-share-edit-dialog", {
        ref: "jobShareEditDialog",
        on: {
          shareJobCard: _vm.showShareJobCardDialog,
          firstEditPersonalInfo: _vm.showJobPersonalInfoDialog,
        },
      }),
      _c("share-job-card-dialog", { ref: "shareJobCardDialog" }),
      _vm.isFirstAddMyShareJob
        ? _c("job-personal-info-dialog", {
            ref: "jobPersonalInfoDialog",
            on: { personalInfoEditComfirm: _vm.updatePersonalInfo },
          })
        : _vm._e(),
      _c("job-bind-project-dialog", {
        ref: "jobBindProjectDialog",
        attrs: {
          jobId: _vm.jobInfo.id,
          customerId: _vm.firmId,
          customerDepartmentId: _vm.jobInfo.customerDepartmentId,
        },
      }),
      _c("famous-company-dialog", {
        ref: "famousCompanyDialog",
        on: { refresh: _vm.refresh },
      }),
      _c("transfer-job-dialog", {
        ref: "transferJobDialog",
        attrs: { isAllMembers: true, isAdminTransfer: true },
        on: { "update-list": _vm.refresh },
      }),
      _c("grab-result-dialog", {
        ref: "grabResultDialog",
        on: {
          "recommend-btn-callback": _vm.handleRecommendBtnCallback,
          "grab-dialog-close": _vm.grabDialogClose,
        },
      }),
      _c("recommend-dialog", { ref: "recommendDialog", attrs: { type: 1 } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }