<template>
    <el-dialog
        title="订单数量变化"
        custom-class="job-order-amount"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false">
        <div
            class="job-order-amount-wrapper"
            v-loading="loading">
            <div class="job-order-amount-title">
                <img src="@src/assets/images/icon/icon_order_modal.png" alt="order"/>
                <div class="chart-title">
                    你正在查看
                    <span class="chart-time">{{params.startDate}}到{{params.endDate}}, </span>
                    <span class="chart-customer text-ellipsis" :title="params.customerName" v-if="params.customerName">{{params.customerName}},</span>
                    <span class="chart-menber text-ellipsis" :title="member">{{member}}</span>
                    的订单数量变化
                </div>
                <el-button
                    type="primary"
                    @click="showSubscribeDataDialog">
                    订阅数据
                </el-button>
            </div>
            <div class="order-amount-chart-wrapper">
                <ul class="chart-panel-btn-list">
                    <li
                        class="chart-panel-btn"
                        :class="{'active': chartPanel === 3}"
                        @click="changeChartPanel(3)">
                        按月
                    </li>
                    <li
                        class="chart-panel-btn"
                        :class="{'active': chartPanel === 2}"
                        @click="changeChartPanel(2)">
                        按周
                    </li>
                    <li
                        class="chart-panel-btn"
                        :class="{'active': chartPanel === 1}"
                        @click="changeChartPanel(1)">
                        按天
                    </li>
                </ul>
                <div class="order-amount-chart" id="orderAmountChart"></div>
            </div>
        </div>
        <el-dialog
            title="订阅到邮箱"
            :show-close="false"
            custom-class="subscribe-data-dialog"
            :visible.sync="subscribeDialogVisible"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            append-to-body>
            <div
                class="subscribe-data-wrapper"
                v-loading="subscribeLoading">
                <div class="subscribe-data-title">
                    <i class="el-icon-s-claim"></i>
                    <div class="subscribe-title-text">
                        你正在订阅
                        <span class="subscribe-data-detail" :title="member">{{member}}</span>、
                        <span class="subscribe-data-detail" :title="customer">{{customer}}</span>、
                        <span class="subscribe-data-detail">前一个月</span>
                        的职位增长数据。
                    </div>
                </div>
                <el-form
                    ref="subscribeDataForm"
                    class="subscribe-data-form"
                    :model="subscribeDataForm"
                    label-width="110px">
                    <el-form-item label="邮箱地址" prop="emails">
                        <el-input
                            type="textarea"
                            :rows="2"
                            placeholder="请输入订阅的邮箱，多个邮箱以分号“;”隔开"
                            v-model="subscribeDataForm.emails"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="发送周期">
                        <el-checkbox v-model="subscribeDataForm.isSubscriptionWeek">每周一</el-checkbox>
                        <el-checkbox v-model="subscribeDataForm.isSubscriptionMonth">每月一号</el-checkbox>
                    </el-form-item>
                </el-form>
            </div>
            <span class="dialog-footer" slot="footer">
                <el-button @click="hideSubscribeDialog">取消</el-button>
                <el-button type="primary" @click="subscribeData">确定</el-button>
            </span>
        </el-dialog>
    </el-dialog>
</template>

<script>
import echarts from 'echarts';
import {email as emailValid} from '#/js/util/validators.js'
export default {
    name: 'jobOrderAmount',
    props: {
        params: Object
    },
    computed: {
        member() {
            return this.params.memberName ? this.params.memberName : '全部成员';
        },
        customer() {
            return this.params.customerName ? this.params.customerName : '所有客户';
        }
    },
    data() {
        return {
            dialogVisible: false,
            loading: false,
            chartPanel: 2,
            subscribeDialogVisible: false,
            subscribeLoading: false,
            subscribeDataForm: {
                emails: "",
                isSubscriptionWeek: false,
                isSubscriptionMonth: false
            }
        }
    },
    methods: {
        show() {
            this.dialogVisible = true;
            this.getChartData();
        },
        getChartData() {
            this.loading = true;
            let params = this.params;
            _request({
                url: '/MyCompany/MyCompanyOrdeCreateCountStatistics',
                method: "POST",
                data: {
                    creatorIds: params.creatorIds,
                    customerIds: params.customerIds,
                    startDate: params.startDate,
                    endDate: params.endDate,
                    type: this.chartPanel
                },
            }).then(res => {
                this.loading = false;
                let myChart = echarts.init(document.querySelector("#orderAmountChart"));
                let option = {
                    title: {
                        text: ''
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#333'
                            },
                            label:{
                                precision:0
                            }
                        }
                    },
                    grid: {
                        left: '150px',
                        top: '10px',
                        width: '635px',
                        containLabel: true
                    },
                    dataZoom: [
                        {
                            show: true,
                            realtime: true,
                            start: 0,
                            end: 100
                        },
                        {
                            type: 'inside',
                            start: 0,
                            end: 100
                        }
                    ],
                    legend: {
                        data: ['职位', 'HC'],
                        right: "40px",
                        top: "0px",
                        orient: 'vertical'
                    },
                    color: ['#38bc9c', '#80e3cb'],
                    xAxis: {
                        type: 'category',
                        data: ["9月12日", "9月15日", "9月18日", "9月21日", "8月26日", "8月27日", "8月28日", "8月29日", "8月30日", "8月31日", "9月1日", "9月2日", "9月3日", "9月4日", "9月5日", "9月6日", "9月7日", "9月8日", "9月9日", "9月10日", "9月11日", "9月13日", "9月14日", "9月16日", "9月17日", "9月19日", "9月20日", "9月22日", "9月23日", "9月24日", "9月25日"],
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        }
                    },
                    yAxis: {
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#999'
                            }
                        },
                        minInterval: 1,
                        splitNumber: 3
                    },
                    series: [{
                        name: '职位',
                        type: 'bar',
                        barWidth: '20%',
                        data: [6, 7, 7, 8, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    }, {
                        name: 'HC',
                        type: 'bar',
                        barWidth: '20%',
                        data: [24, 28, 28, 30, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    }]
                };
                option.xAxis.data = res.x;
                option.series[0].data = res.orderCounts;
                option.series[1].data = res.recruitingCounts;
                myChart.setOption(option);
            }).catch(err => {
                this.loading = false;
                console.log(err)
            })
        },
        changeChartPanel(panel) {
            if(this.chartPanel === panel) {
                return false;
            }
            this.chartPanel = panel;
            this.getChartData();
        },
        showSubscribeDataDialog() {
            this.subscribeDialogVisible = true;
            this.getSubscribeData();
        },
        getSubscribeData() {
            this.subscribeLoading = true;
            _request({
                url: "/MyCompany/GetJobSubscription",
                method: "GET",
                data: {
                    JobSubscriptionType: 1,
                    _: new Date().getTime()
                }
            }).then(res => {
                this.subscribeLoading = false;
                if(res) {
                    this.subscribeDataForm.emails = res.emails || "";
                    this.subscribeDataForm.isSubscriptionWeek = res.isSubscriptionWeek || false;
                    this.subscribeDataForm.isSubscriptionMonth = res.isSubscriptionMonth || false;
                }
            }).catch(err => {
                this.subscribeLoading = false;
                console.log(err)
            })
        },
        hideSubscribeDialog() {
            this.$refs.subscribeDataForm.resetFields();
            this.subscribeDialogVisible = false;
        },
        subscribeData() {
            let emailList = this.subscribeDataForm.emails.split(';'),
                isError = false;
                emailList.some(email => {
                    if(email && !emailValid(email)) {
                        shortTips(`${email}不是有效的邮箱`);
                        isError = true;
                        return true
                    }
                });
            if(isError) {
                return false;
            } else {
                this.subscribeLoading = true;
                let json = {
                    customerIds: this.params.customerIds,
                    creatorIds: this.params.creatorIds,
                    teamId: "",
                    JobSubscriptionType: 1,
                    emails: emailList,
                    isSubscriptionWeek: this.subscribeDataForm.isSubscriptionWeek,
                    isSubscriptionMonth: this.subscribeDataForm.isSubscriptionMonth,
                    _: new Date().getTime()
                };
                _request({
                    url: "/MyCompany/CreateJobSubscription",
                    method: "GET",
                    data: json
                }).then(res => {
                    this.subscribeLoading = false;
                    this.hideSubscribeDialog();
                    shortTips('订阅成功！');
                }).catch(err => {
                    this.subscribeLoading = false;
                    console.log(err)
                })
            }
        }
    }
}
</script>

<style lang="scss" scope>
    .job-order-amount.el-dialog {
        .text-ellipsis {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .job-order-amount-title {
            margin-bottom: 25px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #9b9b9b;
            img {
                width: 72px;
                height: 70px;
            }
            .chart-title > span {
                color: #666;
            }
            .chart-customer, .chart-menber {
                display: inline-block;
                max-width: 140px;
                position: relative;
                top: 5px;
            }
        }
        .order-amount-chart-wrapper {
            margin-bottom: 20px;
            .chart-panel-btn-list {
                float: left;
                width: 150px;
                text-align: center;
                display: inline-flex;
                flex-direction: column;
                color: #333;
                font-size: 16px;
                line-height: 20px;
                position: relative;
                z-index: 10;
                .chart-panel-btn {
                    cursor: pointer;
                    position: relative;
                    &::before {
                        content: "";
                        width: 10px;
                        height: 10px;
                        background-color: #ccc;
                        border-radius: 50%;
                        position: absolute;
                        left: 35px;
                        top: 5px;
                    }
                    & + .chart-panel-btn {
                        margin-top: 20px;
                    }
                    &.active {
                        color: $primary;
                        cursor: default;
                        &::before {
                            background-color: $primary;
                        }
                    }
                }
            }
            .order-amount-chart {
                margin-left: -150px;
                width: 900px;
                height: 320px;
                display: inline-block;
            }
        }
    }
</style>

<style lang="scss">
.subscribe-data-dialog.el-dialog {
    width: 600px;
    .subscribe-data-title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        line-height: 34px;
        color: #9b9b9b;
        .el-icon-s-claim {
            font-size: 50px;
            margin-right: 10px;
            color: $primary;
        }
        .subscribe-title-text {
            line-height: 20px;
        }
        .subscribe-data-detail {
            max-width: 80px;
            color: #333;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: inline-block;
            position: relative;
            top: 4px;
        }
    }
    .subscribe-data-form {
        margin-left: 7px;
        .el-form-item {
            margin-bottom: 15px;
        }
        .el-form-item__label {
            text-align: left;
        }
    }
}
</style>
