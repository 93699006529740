var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "jobs-right" }, [
    _c("div", { staticClass: "job-order-list-wrapper" }, [
      _vm._m(0),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.customerLoading,
              expression: "customerLoading",
            },
          ],
          staticClass: "job-order-list",
        },
        [
          _vm.customerOrders.length
            ? _vm._l(_vm.customerOrders, function (customer, customerIndex) {
                return _c(
                  "div",
                  { key: customerIndex, staticClass: "job-order-item" },
                  [
                    _c("i", {
                      staticClass:
                        "job-order-item-icon el-icon-office-building",
                    }),
                    _c(
                      "a",
                      {
                        staticClass: "job-order-item-name",
                        class: { "disable-link": !_vm.isMyFirm },
                        attrs: {
                          href: _vm.isMyFirm
                            ? `/#/Customer/${customer.customerId}`
                            : "javascript:void(0)",
                          target: _vm.isMyFirm ? "_blank" : "",
                        },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(customer.customerName) +
                            "\n                    "
                        ),
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "job-order-item-count",
                        on: {
                          click: function ($event) {
                            return _vm.addParams("customer", customer)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(customer.orderCount) +
                            "\n                    "
                        ),
                      ]
                    ),
                  ]
                )
              })
            : _c("span", { staticClass: "job-order-list-null" }, [
                _vm._v("暂无数据"),
              ]),
        ],
        2
      ),
      !_vm.customerLoading && _vm.customerOrders.length && _vm.isMyFirm
        ? _c(
            "div",
            {
              staticClass: "job-order-more-btn",
              on: {
                click: function ($event) {
                  return _vm.showMoreJobOrder("0")
                },
              },
            },
            [_vm._v("\n            加载更多\n        ")]
          )
        : _vm._e(),
    ]),
    _vm.isMyFirm
      ? _c("div", { staticClass: "job-order-list-wrapper" }, [
          _vm._m(1),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.memberLoading,
                  expression: "memberLoading",
                },
              ],
              staticClass: "job-order-list",
            },
            [
              _vm.memberOrders.length
                ? _vm._l(_vm.memberOrders, function (member, memberIndex) {
                    return _c(
                      "div",
                      { key: memberIndex, staticClass: "job-order-item" },
                      [
                        _c("i", {
                          staticClass: "job-order-item-icon el-icon-s-custom",
                        }),
                        _c(
                          "a",
                          {
                            staticClass: "job-order-item-name",
                            attrs: {
                              href: `/#/user/${member.userId}`,
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(member.nickname) +
                                "\n                    "
                            ),
                          ]
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "job-order-item-count",
                            on: {
                              click: function ($event) {
                                return _vm.addParams("creator", member.userId)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(member.orderCount) +
                                "\n                    "
                            ),
                          ]
                        ),
                      ]
                    )
                  })
                : _c("span", { staticClass: "job-order-list-null" }, [
                    _vm._v("暂无数据"),
                  ]),
            ],
            2
          ),
          !_vm.memberLoading &&
          _vm.memberOrders.length &&
          _vm.userInfo.isAdminOrTeamLeader
            ? _c(
                "div",
                {
                  staticClass: "job-order-more-btn",
                  on: {
                    click: function ($event) {
                      return _vm.showMoreJobOrder("1")
                    },
                  },
                },
                [_vm._v("\n            加载更多\n        ")]
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _c("div", { staticClass: "job-order-list-wrapper" }, [
      _vm._m(2),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.locationLoading,
              expression: "locationLoading",
            },
          ],
          staticClass: "job-order-list",
        },
        [
          _vm.locationOrders.length
            ? _vm._l(_vm.locationOrders, function (location, locationIndex) {
                return _c(
                  "div",
                  { key: locationIndex, staticClass: "job-order-item" },
                  [
                    _c("i", {
                      staticClass: "job-order-item-icon el-icon-location",
                    }),
                    _c(
                      "span",
                      { staticClass: "job-order-item-name job-location-name" },
                      [_vm._v(_vm._s(location.areaName))]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "job-order-item-count job-location-count",
                      },
                      [_vm._v(_vm._s(location.orderCount))]
                    ),
                  ]
                )
              })
            : _c("span", { staticClass: "job-order-list-null" }, [
                _vm._v("暂无数据"),
              ]),
        ],
        2
      ),
      !_vm.locationLoading &&
      _vm.locationOrders.length &&
      _vm.isMyFirm &&
      _vm.userInfo.isAdminOrTeamLeader
        ? _c(
            "div",
            {
              staticClass: "job-order-more-btn",
              on: {
                click: function ($event) {
                  return _vm.showMoreJobOrder("2")
                },
              },
            },
            [_vm._v("\n            加载更多\n        ")]
          )
        : _vm._e(),
    ]),
    _vm.isMyFirm && _vm.userInfo.isAdminOrTeamLeader
      ? _c(
          "a",
          {
            staticClass: "more-job-order-btn",
            on: {
              click: function ($event) {
                return _vm.showMoreJobOrder("3")
              },
            },
          },
          [_vm._v("\n        更多订单数据\n    ")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "job-order-list-title" }, [
      _c("i", { staticClass: "job-order-list-icon el-icon-office-building" }),
      _vm._v("\n            客户订单量\n        "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "job-order-list-title" }, [
      _c("i", { staticClass: "job-order-list-icon el-icon-s-custom" }),
      _vm._v("\n            成员订单量\n        "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "job-order-list-title" }, [
      _c("i", { staticClass: "job-order-list-icon el-icon-location" }),
      _vm._v("\n            地区订单量\n        "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }