<template>
    <div class="job-list">
        <job-search
            v-if="!isImJobList && !isProgramList"
            ref="jobSearch"
            :jobNumber="pager.total"
            :orderParams="orderParams"
            @updateOrderParams="(params) => $emit('updateOrderParams', params)"
            @updateParams="(updateOrderType, searchLabelList) => search(false, updateOrderType, searchLabelList)"
        ></job-search>
        <div class="job-content" v-loading="loading">
            <div class="job-list-page" v-if="jobList.length > 0">
                <div class="job-list-wrapper" v-loading="listLoading">
                        <div class="list-container" data-spm-area-id="A_job_search_result_list">
                            <job-item
                                ref="jobItem"
                                v-for="(job, index) in jobList"
                                :data-spm-e-data="listItemSpmData(job, index)"
                                :data-spm-e-id="`L_job_search_result_list.${pager.take}xP${pager.current}-${index+1}`"
                                :key="index"
                                :job="job"
                                :isMyFirm="isMyFirm"
                                @changeJobStatus="changeJobStatus"
                                :currentIdx="index"
                                :activeIdx="activeIdx"
                                @update-active-index="handleUpdateIndex"
                                @grab-success-callback="handleGrabCallback"
                            ></job-item>
                        </div>
                    <!-- <div class="empty" v-else>
                        <span class="empty-img"></span>
                        <p class="empty-data">暂无数据</p>
                    </div> -->
                    <!-- layout="total, sizes, prev, pager, next, slot" -->
                    <el-pagination
                        :current-page="pager.current"
                        :page-sizes="[10, 30, 50]"
                        :page-size="pager.take"
                        class="job-list-pagination"
                        layout="total, sizes, prev, pager, next, slot"
                        :total="pager.total"
                        v-if="!isImJobList && jobList.length > 0"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                    >
                        <span class="pagination-text">
                            <span>
                                前往
                                <el-input
                                    v-model="pagerJump"
                                    @keyup.enter.native="handlePagerJump(pagerJump)"
                                ></el-input>
                                页
                            </span>
                            <span @click="handlePagerJump(pagerJump)">
                                跳转
                            </span>
                        </span>
                    </el-pagination>
                </div>
            </div>

            <div class="job-content-page" v-if="jobList.length > 0" v-loading="loading">

                <div class="content-top">
                    <div class="content-title-row">
                        <div class="crow-l">
                            <span class="title_loc">
                                <span class="name ell" :title="jobInfo.name">{{jobInfo.name}} </span>
                                <span class="loca ell" :title="jobInfo.location">{{ jobInfo.location ? `[${jobInfo.location}]`: '' }}</span>
                                </span>
                        </div>
                        <div class="crow-r">
                            <el-tooltip v-if="jobInfo.isMyJob && jobInfo.status != 4" effect="dark" content="修改职位状态" placement="top">
                                <span class="edit" 
                                    @click="handleJobStatus">
                                    <font-icon href="#icon-bianji"></font-icon>
                                </span>
                            </el-tooltip>
                            
                            <el-dropdown trigger="click">
                                <span class="more">
                                    <font-icon href="#icon-set"></font-icon>
                                </span>
                                <el-dropdown-menu slot="dropdown" class="more-dropdown-menu no-arrow">
                                
                                    <el-dropdown-item>

                                        <el-popover
                                            placement="left"
                                            width="245"
                                            popper-class="share-popover"
                                        >   
                                            <div class="item-wrap" slot="reference">
                                                <font-icon
                                                    class="item-icon"
                                                    href="#icon-share1"
                                                ></font-icon>
                                                <span class="item-text">分享</span>
                                            </div>
                                            <button class="share-btn" @click="shareToIM">
                                                <img :src="require('#/assets/images/instantMessage/private_message.png')" /> 私信和群分享
                                            </button>
                                            <button class="share-btn" 
                                                @click="shareToWeChat" 
                                                v-if="!isFromAuthorization && jobInfo.recruiterFirmId === user.firmId">
                                                <img :src="require('#/assets/images/instantMessage/wechat.png')" />
                                                分享到微信
                                            </button>
                                            <button class="share-btn" 
                                                @click="addToMyJobCard" 
                                                v-if="!isFromAuthorization && jobInfo.recruiterFirmId === user.firmId">
                                                <img :src="require('#/assets/images/instantMessage/user-card.png')" />
                                                添加到我的职位名片
                                            </button>
                                        </el-popover>
                                    </el-dropdown-item>
                                    <el-dropdown-item 
                                        v-if="jobInfo.recruiterFirmId == user.firmId && jobInfo.customerDepartmentId && jobInfo.customerDepartmentId != '0' && jobInfo.status!=4" 
                                        @click.native="showBindProjectDialog">
                                        <div class="item-wrap">
                                            <font-icon
                                                class="item-icon"
                                                href="#icon-survey"
                                            ></font-icon>
                                            <span class="item-text">绑定项目</span>
                                        </div>
                                    </el-dropdown-item>
                                    <el-dropdown-item
                                        v-if="(currentJobType != 3 && jobInfo.status == 1 && jobInfo.canGetOrder && !jobInfo.isMyOrder && !jobInfo.isMyJob) || ([3,5].includes(currentJobType) && !jobInfo.isMyOrder)"
                                        @click.native="acceptClick">
                                        <div class="item-wrap">
                                            <font-icon
                                                class="item-icon"
                                                href="#icon-operation"
                                            ></font-icon>
                                            <span class="item-text">抢单</span>
                                        </div>
                                    </el-dropdown-item>
                                    <el-dropdown-item 
                                        v-if="(jobInfo.isMyJob || jobInfo.canPublishToMarket) && !jobInfo.isSleeping && !isFromAuthorization && jobInfo.recruiterId == user.id && (
                                            (jobInfo.isMyJob && jobInfo.status == 4) || (jobInfo.canPublishToMarket && jobInfo.isPublic) || jobInfo.canDelete
                                        )">
                                        <my-job-operate
                                            :jobInfo="jobInfo"
                                            :customerId="firmId" 
                                            :customerDepartmentId="jobInfo.customerDepartmentId"
                                            :isAdministrator="user.isAdministrator"
                                            @refresh="refresh">
                                        </my-job-operate>
                                    </el-dropdown-item>
                                    <el-dropdown-item 
                                        v-if="jobInfo.isMyJob && user.isCFUser && !jobInfo.publicJobId && jobInfo.status == 1"
                                        @click.native="showFamousCompanyDialog">
                                        <div class="item-wrap">
                                            <font-icon
                                                class="item-icon"
                                                href="#icon-electronics"
                                            ></font-icon>
                                            <span class="item-text">发布到大厂机会</span>
                                        </div>
                                    </el-dropdown-item>
                                    <el-dropdown-item
                                        v-if="((jobInfo.jobType == 2 &&jobInfo.isPublic == false) || jobInfo.acceptedHeadhuntersCount == 0) && canEdit" 
                                        @click.native="handleEdit">
                                        <div class="item-wrap">
                                            <font-icon
                                                class="item-icon"
                                                href="#icon-edit1"
                                            ></font-icon>
                                            <span class="item-text">编辑</span>
                                        </div>
                                    </el-dropdown-item>
                                    <el-dropdown-item 
                                        v-if="!jobInfo.isSleeping && (jobInfo.isMyJob || (isHeadhunter && jobInfo.isMyOrder))"
                                        @click.native="showTransferJobDialog">
                                        <div class="item-wrap">
                                            <font-icon
                                                class="item-icon"
                                                href="#icon-export1"
                                            ></font-icon>
                                            <span class="item-text">移交</span>
                                        </div>
                                    </el-dropdown-item>
                                    <el-dropdown-item 
                                        v-if="jobInfo.isMyJob && jobInfo.approvedStatus!=2 && jobInfo.status!=4"
                                        @click.native="handleBindDepartment">
                                        <div class="item-wrap">
                                            <font-icon
                                                class="item-icon"
                                                href="#icon-zuzhijiagou"
                                            ></font-icon>
                                            <span class="item-text">绑定组织架构</span>
                                        </div>
                                    </el-dropdown-item>
                                    
                                </el-dropdown-menu>
                            </el-dropdown>

                        </div>
                    </div>
                    <div class="cont-row">
                        <span class="tags" v-if="jobInfo.activityTags?.length>0 || [3,5].includes(jobInfo.jobType)">
                            <!-- <span class="hurry-tag">急聘</span>
                            <span class="tag job-tag-danger">较难</span>
                            <span class="tag job-tag-warning">最新</span> -->

                            <el-tooltip
                                v-for="item in jobInfo.activityTags"
                                :key="item.id"
                                effect="light"
                                placement="right"
                                :disabled="!item.description"
                                v-show="item.id !== 'StandstillJob' && item.id != 'FirstOrderActivityJob'"
                                popper-class="job-tag-description">
                                <div slot="content" v-html="item.description"></div>

                                <span class="JobEmergency" title="急聘" v-if="item.id == 'JobEmergency'">
                                    <span class="hurry">
                                        <font-icon href="#icon-shandian"></font-icon>    
                                    </span>急聘
                                </span>
                                <span class="tag" v-else :class="`job-tag-${jobActivityTag[item.id].theme}`" :title="item.name">{{item.name == 'New' ? '最新': item.name}}</span>
                            </el-tooltip>
                            <span v-if="jobInfo.status==1 && jobInfo.approvedStatus != '2'" class="recruiting" >
                                <span class="hurry">
                                    <font-icon href="#icon-a-bianzu11"></font-icon>    
                                </span>招聘中
                            </span>
                           
                            <span class="tag job-tag-info" v-if="jobInfo.jobType ==3">HR</span>
                            <span class="tag job-tag-info" v-if="jobInfo.jobType ==5">代运营</span>

                        </span>

                        <template v-if="![3,5].includes(currentJobType)">
                            <span class="percent">佣金 {{commissionValue }}</span>
                        </template>
                        <template v-if="[3,5].includes(currentJobType) && jobInfo.commissionType == '0'" >
                            <span class="percent">佣金 
                                {{ jobInfo.minSalary == 0 && jobInfo.maxSalary == 0 ? `按比例(${jobInfo.commissionValue}%)` : jobInfo.commissionValueText }}
                            </span>
                        </template>
                        <template v-if="[3,5].includes(currentJobType) && jobInfo.commissionType == '1'" >
                            <span class="percent">佣金 {{ jobInfo.commissionTypeValue + 'K' }}</span>
                        </template>

                        <span class="promise-time">
                            保证期<span class="month">{{jobInfo.guaranteePeriod}}</span>个月 
                        </span>

                    </div>
                    <div class="cont-row">

                        <span class="salary" v-if="currentJobType == 3">
                            {{jobInfo.minSalary == 0 && jobInfo.maxSalary == 0 ? '面议' : jobInfo.minSalary + 'K-' + jobInfo.maxSalary + 'K'}}
                        </span>
                        <span class="salary" v-else>{{jobInfo.salary == '0K-0K' ? '面议' : jobInfo.salary}}</span>

                        <span class="icon-wrap">
                            <span class="info-icon">
                                <font-icon
                                    href="#icon-time1"
                                ></font-icon>
                            </span>{{jobInfo.yearOfExperience}}
                        </span>
                        <span class="icon-wrap">
                            <span class="info-icon">
                                <font-icon
                                    href="#icon-work"
                                ></font-icon>
                            </span>{{jobInfo.degree}}
                        </span>
                        <span class="icon-wrap">
                            <span class="info-icon">
                                <font-icon
                                    href="#icon-user1"
                                ></font-icon>
                            </span>需{{jobInfo.recruitingCount}}人
                        </span>
                        <span class="icon-wrap" v-if="currentItemInfo.categoryName">
                            <span class="info-icon">
                                <font-icon
                                    href="#icon-modular"
                                ></font-icon>
                            </span>
                            <!-- <span class="loc ell" :title="jobInfo.location || '等待补充'">{{jobInfo.location || "等待补充"}}</span> -->
                            <span class="loc ell">{{currentItemInfo.categoryName}}</span>
                        </span>
                    </div>

                    <div class="nums-wrap" v-if="[3,5].includes(currentJobType)">
                        <div class="num-cell">
                            <span class="count">{{jobInfo.floatingInfo ? jobInfo.floatingInfo.recommendationCount : 0}}</span>推荐
                        </div>
                        <div class="num-cell">
                            <span class="count">{{jobInfo.floatingInfo ? jobInfo.floatingInfo.interviewCount : 0}}</span>面试
                        </div>
                        <div class="num-cell">
                            <span class="count">{{jobInfo.floatingInfo ? jobInfo.floatingInfo.offerCount : 0}}</span>Offer
                        </div>
                        <div class="num-cell">
                            <span class="count">{{jobInfo.floatingInfo ? jobInfo.floatingInfo.acceptedCount : 0}}</span>接受
                        </div>
                        <div class="num-cell">
                            <span class="count">{{jobInfo.floatingInfo ? jobInfo.floatingInfo.rejectedCount : 0}}</span>拒绝
                        </div>
                    </div>
                    <div class="nums-wrap" v-else>
                        <div class="num-cell">
                            <span class="count">{{jobInfo.recommendationCount || 0}}</span>推荐
                        </div>
                        <div class="num-cell">
                            <span class="count">{{jobInfo.interviewCount || 0}}</span>面试
                        </div>
                        <div class="num-cell">
                            <span class="count">{{jobInfo.offerCount || 0}}</span>Offer
                        </div>
                        <div class="num-cell">
                            <span class="count">{{jobInfo.acceptedCount || 0}}</span>接受
                        </div>
                        <div class="num-cell">
                            <span class="count">{{jobInfo.rejectedCount || 0}}</span>拒绝
                        </div>
                    </div>


                    <div class="customer-infos" v-if="currentJobType == 3">
                        <a class="pic" 
                            :href="`/#/Customer/${firmId}`"
                            :class="{'pointer-event-none' : !jobInfo.canViewInternalInformation}"
                            target="_blank">
                            <img :src="jobInfo.firmLogoUrl || '//hstatic.hirede.com/lbd/images/firm-default-avatar.jpg'" />
                        </a>
                        <div class="infos" :title="jobInfo.firmShortName+ '-' + jobInfo.weComDepartment">
                            <span class="name">{{jobInfo.firmShortName}}</span>
                            <!-- <span class="name">{{jobInfo.weComDepartment}}</span> -->
                            <!-- <span class="name">{{currentItemInfo.categoryName}}</span> -->
                        </div>
                    </div>

                    <div class="customer-infos" v-else>
                        <a class="pic"
                            :href="`/#/Customer/${firmId}`"
                            :class="{'pointer-event-none' : !jobInfo.canViewInternalInformation}"
                            target="_blank">
                            <!-- <img :src="jobInfo.firmLogoUrl || '//hstatic.hirede.com/lbd/images/firm-default-avatar.jpg'" /> -->
                            <img src="@src/assets/images/default-company.png" />
                        </a>

                        <div class="infos" v-if="[3,4,5].includes(jobInfo.jobType)">
                            <template v-if="jobInfo.jobType == 4">
                                <span class="name">{{jobInfo.customerName}}</span>
                            </template>
                            <template v-else>
                                <span class="name">{{jobInfo.definedCustomerName}}</span>
                            </template>

                            <i class="center-line" v-if="jobInfo.bindingProjects && Object.keys(jobInfo.bindingProjects).length > 0 && (jobInfo.recruiterFirmId == user.firmId)"></i>

                            <span class="name" v-if="jobInfo.bindingProjects && Object.keys(jobInfo.bindingProjects).length > 0 && (jobInfo.recruiterFirmId == user.firmId)">
                                <template v-for="(value, name, index) in jobInfo.bindingProjects">
                                    <i :key="index + name" v-if="index != 0">、</i>
                                    <span class="project-info-item" :key="name">{{value.replace("$#$","-")}}</span>
                                </template>
                            </span>
                            <span class="name" v-else>还未绑定项目</span>
                        </div>
                        <div class="infos" v-else>
                            <span class="name">{{customerName}}</span>
                            <i class="center-line" v-if="jobInfo.bindingProjects && Object.keys(jobInfo.bindingProjects).length > 0 && (jobInfo.recruiterFirmId == user.firmId)"></i>
                            
                            <!-- <span class="name" v-if="jobInfo.bindingProjects && Object.keys(jobInfo.bindingProjects).length > 0 && (jobInfo.recruiterFirmId == user.firmId)">
                                <template v-for="(value, name, index) in jobInfo.bindingProjects">
                                    <i :key="index + name" v-if="index != 0">、</i>
                                    <span class="project-info-item" :key="name">{{value.replace("$#$","-")}}</span>
                                </template>
                            </span>
                            <span class="name" v-else>还未绑定项目</span> -->

                            <el-popover
                                popper-class="bind-project-popover"
                                placement="bottom"
                                trigger="hover"
                                v-if="jobInfo.bindingProjects && Object.keys(jobInfo.bindingProjects).length > 0 && (jobInfo.recruiterFirmId == user.firmId)"
                                >
                                <span class="project-name ell"
                                    slot="reference"
                                    >
                                    <span v-for="(value, name, index) in jobInfo.bindingProjects" :key="'name_' + index">
                                        <i :key="index + name" v-if="index != 0">、</i>
                                        <a class="project-info-item" target="_blank" :href="`/#/ProjectDetail/${name}`">{{value.replace("$#$","-")}}</a>
                                    </span>
                                </span>

                                <span class="name">
                                    <span v-for="(value, name, index) in jobInfo.bindingProjects" :key="name">
                                        <i :key="index + name" v-if="index != 0">、</i>
                                        <a class="project-info-item" target="_blank" :href="`/#/ProjectDetail/${name}`">{{value.replace("$#$","-")}}</a>
                                    </span>
                                </span>
                            </el-popover>
                        </div>
                    </div>

                </div>
                <div class="jd-content">
                    <!-- v-for="(it,ind) in 3" :key="ind" -->
                    <div class="block" >
                        <p class="tit">
                            职位详情：
                        </p>
                        <div class="firm-label-list"
                            v-if=" !jobInfo.isMyJob && jobInfo.firmLabels && jobInfo.firmLabels.length > 0 && currentJobType != 3">
                            <span
                                class="firm-label-item"
                                v-for="(label, index) in jobInfo.firmLabels"
                                :key="'label_' + index"
                            >
                                {{ label }}
                            </span>
                        </div>
                        <pre class="pre-wrap" 
                            v-text="jobInfo.description"></pre>
                    </div>

                    <div class="block" v-if="![3,5].includes(currentJobType)">
                        <p class="tit">
                            职位分析：
                        </p>
                        <div
                            class="job-share-board"
                            v-if=" jobInfo.isCooperativeJob && jobInfo.isMyJob "
                        >
                            <p class="job-share-board-msg">
                                <span class="text-primary font-bold"
                                    >【本职位由我公司发布】</span
                                >
                                内部佣金：{{
                                    jobInfo.internalCommissionValue
                                }}，保证期：{{
                                    jobInfo.internalGuaranteePeriod
                                }}，内部退款条款按客户合同约定。
                            </p>
                        </div>
                        <pre
                            class="pre-wrap" 
                            v-text="jobInfo.analysis"
                        ></pre>
                    </div>


                    <div class="block" v-if="![3,5].includes(currentJobType)">
                        <p class="tit" v-if="jobInfo.acceptedHeadhuntersCount > 0">
                            <span class="text-primary">{{ jobInfo.acceptedHeadhuntersCount }}</span>位猎头抢单：
                        </p>
                        <p class="tit" v-else>还没有猎头抢单</p>

                        <div
                            class="accept-headhunters"
                            v-if="jobInfo.acceptedHeadhuntersCount > 0"
                        >
                            <avatar
                                class="accepted-headhunters-avatar"
                                size="md"
                                :src="item.avatarUrl"
                                :userId="item.id"
                                :enableCard="true"
                                :enableLink="true"
                                v-for="item in jobInfo.acceptedHeadhunters"
                                :key="item.id"
                            ></avatar>
                        </div>

                    </div>

                    <div class="infos-card">
                        <div class="user-pic">
                            <avatar
                                class="pic"
                                size="sm"
                                :src="currentJobType == 3 ? (jobInfo.recruiter ? jobInfo.recruiter.avatarUrl+'?size=60' : '') : jobInfo.recruiterAvatarUrl+'?size=60'"
                                :userId="currentJobType == 3 ? (jobInfo.recruiter ? jobInfo.recruiter.userId : '') : jobInfo.recruiterId"
                                :enableCard="true"
                                :enableLink="true"
                            ></avatar>
                        </div>
                        <div class="user-info">
                            <p class="name">{{currentJobType == 3 ? (jobInfo.recruiter ? jobInfo.recruiter.realName : '') : jobInfo.recruiterRealName}}@{{currentJobType == 3 ? (jobInfo.recruiter ? jobInfo.recruiter.nickname : '') : jobInfo.recruiterNickname}}</p>
                            <p class="firm-info">{{currentJobType == 3 ? (jobInfo.recruiter ? jobInfo.recruiter.firmName : '') : jobInfo.recruiterFirmShortName}} {{ currentJobType == 3 ? (jobInfo.recruiter ? jobInfo.recruiter.title : '') : jobInfo.recruiterTitle}}</p>
                            <p class="time">{{jobInfo.created | createDateFilter}} 发布</p>
                        </div>
                        <div class="infos-right">
                            <button class="contact-btn"
                                v-if="!jobInfo.isMyJob"
                                @click="currentJobType == 3 ? contactRecruiter(jobInfo.recruiter.unionId) : contactRecruiter(jobInfo.recruiterUnionId)"
                                >联系发布者</button>
                        </div>
                    </div>

                    <div class="bottom-wrap">
                        <button class="bottom-btn" @click="jumpJobDetail">查看更多信息</button>
                    </div>

                </div>

            </div>

            <div class="page-empty" v-if="jobList.length == 0">
                <img src="~@src/assets/images/jobList/empty-bg@2x.png" />
                <p class="des">没有搜索到任何职位</p>
            </div>

            
            <!-- <jobs-right
                ref="jobsRight"
                :params="params"
                :isMyFirm="isMyFirm"
                :orderParams="orderParams"
                @search="filterJobOrder"
                @filterRecommendDegree="filterRecommendDegree"
            ></jobs-right> -->
        </div>

        <!-- <div class="lbd-sign"></div> -->
        
        <!-- <job-status-dialog
            ref="jobStatusDialog"
            @update-item="(job) => $emit('updateJobItem', job)"
        ></job-status-dialog> -->
        <job-status-dialog
            ref="jobStatusDialog"
            @update-item="updateItem"
            @showProjectCreateDialog="showProjectCreateDialog"
        ></job-status-dialog>
        <project-create-dialog
            ref="projectCreateDialog" 
            @refresh-project-list="refreshPorjectList">
        </project-create-dialog>

        <bind-department-dialog
            ref="bindDepartment"
            :jobInfo="jobInfo"
            :customerId="firmId"
            @bind-department-confirm="refresh"
            v-if="jobInfo.isMyJob"
        ></bind-department-dialog>


        <job-share-edit-dialog 
            ref="jobShareEditDialog" 
            @shareJobCard="showShareJobCardDialog" 
            @firstEditPersonalInfo="showJobPersonalInfoDialog">
        </job-share-edit-dialog>

        <share-job-card-dialog 
            ref="shareJobCardDialog"></share-job-card-dialog>

        <job-personal-info-dialog
            ref="jobPersonalInfoDialog"
            v-if="isFirstAddMyShareJob"
            @personalInfoEditComfirm="updatePersonalInfo"
        ></job-personal-info-dialog>

        <job-bind-project-dialog 
            ref="jobBindProjectDialog" 
            :jobId="jobInfo.id"
            :customerId="firmId" 
            :customerDepartmentId="jobInfo.customerDepartmentId">
        </job-bind-project-dialog>

        <famous-company-dialog
            ref="famousCompanyDialog"
            @refresh="refresh"
        />

        <!-- 移交职位 -->
        <transfer-job-dialog
            ref="transferJobDialog"
            :isAllMembers="true"
            :isAdminTransfer="true"
            @update-list="refresh"
        ></transfer-job-dialog>

        <!-- 抢单成功后的弹出窗口 -->
        <grab-result-dialog
            ref="grabResultDialog"
            @recommend-btn-callback="handleRecommendBtnCallback"
            @grab-dialog-close="grabDialogClose"
            >
        </grab-result-dialog>

        <recommend-dialog ref="recommendDialog" :type="1" ></recommend-dialog>

    </div>
</template>

<script>
import moment from 'moment';
import JobSearch from '../component/job-search.vue';
import JobItem from '../component/job-item-refactor.vue';
import JobsRight from './jobs-right.vue';
import jobStatus from '#/js/config/jobStatus.json';
import JobStatusDialog from '@src/component/common/dialog/jobStatusDialog.vue';
import ProjectCreateDialog from '#/views/project/component/create-project-dialog.vue';
import { job as jobUrl } from '#/js/config/api.json';
import imService from '#/js/service/imService.js';
import MediaService from '#/js/service/mediaService.js';
import BindDepartmentDialog from '#/component/common/dialog/bind-department-dialog.vue';
import Avatar from "#/component/common/avatar.vue";
import JobShareEditDialog from '#/component/common/dialog/job-share-edit-dialog.vue';
import ShareJobCardDialog from '#/component/common/dialog/share-job-card-dialog.vue';
import JobPersonalInfoDialog from '#/component/common/dialog/job-personal-info-dialog.vue';
import emitter from '@src/js/mixins/emitter';
import JobBindProjectDialog from '#/views/job-detail/component/job-bind-project-dialog.vue';
import MyJobOperate from '#/views/job-detail/component/my-job-operate-refactor.vue';
import FamousCompanyDialog from '#/views/job-detail/component/famous-company-dialog.vue';
import TransferJobDialog from "#/component/common/dialog/transferJobDialog.vue";
import jobActivityTag from '#/js/config/jobActivityTag.json';
import GrabResultDialog from '../component/grab-result-dialog.vue';
import RecommendDialog from '@src/component/common/dialog/recommendDialog.vue';

export default {
    name: "jobList",
    componentName: "jobList",
    mixins: [emitter],
    props: {
        jobList: {
            type: Array,
            default: () => {
                return [];
            }
        },
        listLoading: {
            type: Boolean,
            default: false
        },
        params: {
            type: Object,
            default: () => {
                return {}
            }
        },
        isMyFirm: {
            type: Boolean,
            default: true
        }
    },
    components: {
        JobSearch,
        JobItem,
        JobsRight,
        JobStatusDialog,
        ProjectCreateDialog,
        BindDepartmentDialog,
        Avatar,
        JobShareEditDialog,
        ShareJobCardDialog,
        JobPersonalInfoDialog,
        JobBindProjectDialog,
        MyJobOperate,
        FamousCompanyDialog,
        TransferJobDialog,
        GrabResultDialog,
        RecommendDialog,
    },
    data() {
        return {
            orderParams: {
                customerIds: [],
                customerName: '',
                creatorIds: [],
                memberName: '',
                startDate: '',
                endDate: ''
            },
            pager: {
                current: 1,
                take: 10,
                total: 0
            },
            pagerJump: 0,

            currentIdx: 0,
            activeIdx: 0,

            jobInfo: {},
            loading: false,
            currentJobType: undefined,

            isFirstAddMyShareJob: false,
            jobActivityTag: jobActivityTag,
            jobStatus: jobStatus,
        };
    },
    filters: {
        createDateFilter(val) {
            let today = moment().format('YYYY-MM-DD'),
                dateDay = moment(val).format('YYYY-MM-DD');
            if(today === dateDay) {
                return '今天';
            } else if(moment().subtract(1, 'days').format('YYYY-MM-DD') === dateDay) {
                return '昨天';
            } else {
                return dateDay;
            }
        },
    },
    computed: {
        isImJobList() {
            return this.$route.name == 'IMJobList';
        },
        isProgramList() {
            return this.$route.name == 'ProjectDetail';
        },
        customerName() {
            let name = this.jobInfo.departmentPath || this.jobInfo.firmShortName;
            let enc = new TextEncoder("utf-8");
            if(this.jobInfo.jobType ==4) {
                return this.jobInfo.customerName;
            } else if(this.jobInfo.jobType == 2) {
                return this.jobInfo.departmentPath || this.jobInfo.customerName;
            }
            if(enc.encode(name).length > 34) {
                let firstParent = "";
                if(this.jobInfo.departmentPath) {
                    firstParent = name.slice(0, name.indexOf("-"));
                    if(firstParent.length > 10) {
                        firstParent = name.slice(0, 10);
                    }
                } else {
                    firstParent = name.slice(0, 10);
                }
                return firstParent + " ··· ";
            }
            return name;
        },
        user() {
            return this.$store.state.user.userInfo;
        },
        firmId() {
            let firmUrl = this.jobInfo.firmDetailUrl;
            if(firmUrl) {
                return firmUrl.replace('/MyCompany/Customer/', '').replace('/Detail', '');
            }else {
                return '';
            }
        },
        isFromAuthorization() {
            return this.jobInfo.isFromAuthorization;
        },
        shareData() {
            let jobInfo = this.jobInfo;
            let commissionValue = '';
            if ([3,5].includes(jobInfo.jobType)) {
                if (jobInfo.commissionType == '0') {
                    commissionValue = (jobInfo.minSalary == 0 && jobInfo.maxSalary == 0) ? `按比例(${jobInfo.commissionValue}%)` : jobInfo.commissionValueText;
                } else if (jobInfo.commissionType == '1') {
                    commissionValue = jobInfo.commissionTypeValue + 'K';
                } else {
                    commissionValue = jobInfo.commissionValueText;
                }
            } else {
                commissionValue = jobInfo.commissionValue;
            }
            return {
                isPublic: jobInfo.isPublic,
                jobId: jobInfo.id,
                jobName: jobInfo.name,
                firmShortName: jobInfo.firmShortName,
                location: jobInfo.location,
                yearOfExperience: jobInfo.yearOfExperience,
                degree: jobInfo.degree,
                salary: jobInfo.salary == '0K-0K' ? '面议' : jobInfo.salary,
                // commissionValue: jobInfo.commissionValue && jobInfo.commissionValue.indexOf('0K-0K') > -1 ? '按比例' : jobInfo.commissionValue,
                commissionValue: commissionValue,
                creatorId: jobInfo.recruiterId,
                creatorName: `${jobInfo.recruiterRealName}@${jobInfo.recruiterNickname}`,
                creatorAvatar: jobInfo.recruiterAvatarUrl,
                isInnerJob: !jobInfo.isPublic
            }
        },
        canEdit() {
            return (
                (!this.jobInfo.isFromAuthorization &&
                    this.jobInfo.isMyJob &&
                    (this.jobInfo.status == 1 || this.jobInfo.status == 2)) ||
                false
            );
        },
        isHeadhunter() {
            return this.$store.state.user.userInfo.isHeadhunter || false;
        },
        commissionValue(){
            if(this.jobInfo.jobType == 4 && this.applicationId && this.applicationId!='undefined') {
                return this.jobInfo.publicCommissionValue + 'K';
            }
            return this.jobInfo.commissionValue;
        },
        currentItemInfo() {
            return this.jobList[this.activeIdx] || {};
        },
    },
    mounted() {
        if(this.$route.query.startDate != null) {
            this.$refs.jobSearch.changeCreatedTime([this.$route.query.startDate, this.$route.query.endDate]);
        } else {
            this.search(null, 3);
        }

        let thisMonth = new Date().getMonth();
        let lastMonthDay = new Date().setMonth(thisMonth - 1);
        this.orderParams.startDate = moment(lastMonthDay).format('YYYY-MM-DD');
        this.orderParams.endDate = moment().format('YYYY-MM-DD');
    },
    methods: {
        listItemSpmData(job, index) {
            // 搜索查询条件不变更，点击职位项传的跟踪id（searchTraceId）不变，为上一个搜索查询的searchTraceId
            let searchTraceId = '';
            if(this.$store.getters.getSearchTraceId) {
                searchTraceId = this.$store.getters.getSearchTraceId;
            } else {
                searchTraceId = _generateUUID();
            }
            let listItemUrl = '';
            listItemUrl = `https://${window.location.host}/Headhunting/MyCompany.html#/Job/${job?.id}`;
            return `{
                "search_trace_id": "${searchTraceId}",
                "pagination_current": ${this.pager.current},
                "pagination_size": ${this.pager.take},
                "list_item_num": ${index+1},
                "list_item_url": "${listItemUrl}",
                "list_item_job_name": "${job.name}"
            }`;
        },

        showProjectCreateDialog() {
            this.$refs.projectCreateDialog.show();
        },
        refreshPorjectList() {
            this.$refs.jobStatusDialog.refreshPorjectList();
        },
        updateItem(job) {
            this.search(true);
            this.$emit('updateJobItem', job);
        },
        filterJobOrder(json) {
            let jobSearch = this.jobSearch;
            if(json.customerId) {
                jobSearch.$refs.customerSelect.handleCustomerChange({
                    customerName: json.customerName,
                    customerId: json.customerId
                });
            }
            if(json.creatorId) {
                console.log(jobSearch.$refs)
                jobSearch.$refs.teamSelect.handleClear();
                let selectedMember = jobSearch.$refs.teamSelect.memberList.find(member => member.userId === json.creatorId);
                if(selectedMember && selectedMember.userId) {
                    jobSearch.$refs.teamSelect.handleMemberSelect(selectedMember);
                    jobSearch.$refs.teamSelect.handleConfirm();
                } else {
                    jobSearch.creators = '';
                    jobSearch.jobSearchForm.creatorIds = [json.creatorId];
                    jobSearch.addParams(2);
                }
            }
        },
        filterRecommendDegree(json) {
            this.jobSearch.jobSearchForm.recommendDegree = json.recommendDegree;
            this.jobSearch.changeCreatedTime(json.time);
        },
        search(slient, updateOrderType, searchLabelList) {
            console.log(`search:`, arguments)
            let params = {};
            if(this.$refs.jobSearch) {
                params = Object.assign({}, this.$refs.jobSearch.jobSearchForm);
            }
            if(!slient) {
                this.pager.current = 1;
            }
            params.start = (this.pager.current - 1) * this.pager.take;
            params.take = this.pager.take;
            this.$emit('refresh', params, slient, updateOrderType, searchLabelList);
            this.$nextTick(() => {
                this.$refs.jobsRight?.getOrdersCountTop(updateOrderType);
            });
        },
        changeJobStatus(job) {
            this.$refs.jobStatusDialog.initDialog({
                type: 'single',
                checkedArr: [job],
                jobStatusValue: job.status,
                emergencyValue: job.emergencyDegree,
                difficultyValue: job.difficultDegree,
                jobId: job.id,
                customerId: job.customerId,
                customerDepartmentId: job.customerDepartmentId,
            });
        },
        handleSizeChange(val) {
            this.pager.take = val;
            this.pager.current = 1;
            this.search(true, 0);
        },
        handleCurrentChange(current) {
            this.pager.current = current;
            this.search(true, 0);
        },
        handlePagerJump(pagerJump) {
            // if(pagerJump > Math.ceil(this.pager.total / this.pager.size)) {
            //     return;
            // } else {
            //     this.handleCurrentChange(pagerJump);
            // }
            pagerJump = Number.parseInt(pagerJump);
            if (
                pagerJump > 0 &&
                pagerJump <= Math.ceil(this.pager.total / this.pager.take)
            ) {
                this.handleCurrentChange(pagerJump);
            }
        },

        handleUpdateIndex(newIndex) {
            this.activeIdx = newIndex;
            console.log(`handleUpdateIndex:`, newIndex);
            
            this.getJobInfo(this.jobList[newIndex]);
        },

        // 通过职位id查询判断当前的职位类型
        getJobTypeById(job) {
            console.log(`job:`, job);
            let jobId = job.id;
            this.loading = true;
            _request({
                url: "/openapi/Job/GetJobType/%p".replace(/\%p/ig, jobId),
                method: 'GET',
                baseURL: "LbdOpenApi",
            }).then((res) => {
                this.currentJobType = res;
                this.getJobInfo(job);
            }).catch(err => {
                console.log(err)
            })
            .finally(() => {
                this.loading = false;
            });
        },

        getJobInfo(job) {
            // let jobId = this.$route.params.jobId;
            this.currentJobType = job.jobType;
            let jobId = job.id;
            this.loading = true;
            let _requestParams = {};
            // if(this.curRecommendJobType==3) {
            if([3,5].includes(this.currentJobType)) {
                // hr直招职位详情接口
                _requestParams = {
                    url: jobUrl.hr_job_info.replace(/\%p/ig, jobId),
                    method: 'GET',
                    baseURL: "LbdOpenApi",
                }
            } else {
                _requestParams = {
                    url: jobUrl.url_job_info.replace(/\%p/ig, jobId),
                    method: 'GET',
                }
            }
            if(this.applicationId) {
                _requestParams = {
                    url: jobUrl.get_job_apply_detail.replace(/\%p/ig, this.applicationId),
                    method: 'GET',
                    baseURL: "LbdOpenApi",
                }
            }
            _request(
                _requestParams
            ).then((res) => {
                this.loading = false;
                this.jobInfo = res;

                // let firmName = this.$store.state.user.userInfo.firmShortName;
                // let title = this.jobInfo.name + '_' + firmName + '_' + '猎必得_最有效的O2O猎头招聘平台';
                // document.title = title;
                if(location.hash.indexOf("?action=clickOperate") > -1) {
                    this.$refs.jobHead.recommendClick();
                }
                let accountIds = [];
                accountIds.push(res.recruiterUnionId);
                if(res.projectManagers && res.projectManagers.length) {
                    res.projectManagers.forEach(item => {
                        if(item.unionId) {
                            accountIds.push(item.unionId);
                        }
                    })
                }
                // imService.getUserOnlineStatus({
                //     accountIds: accountIds
                // }).then(result => {
                //     if(result && result.length) {
                //         result.forEach(item => {
                //             this.onlineMap[item.accountId] = item?.state === 'Online'
                //         })
                //     }
                // }).catch(err => {
                //     console.log('getUserCardOnlineStatusError', err);
                // });
                // this.checkMediaRecordingAuthority();
            }).catch(err => {
                console.log(err)
                this.loading = false;
            });

            // 将详情移动到最前面
            this.$nextTick(() => {
                if(document.getElementsByClassName("job-content-page")[0]) {
                    document.getElementsByClassName("job-content-page")[0].scrollTop = 0;
                }
            });
        },
        checkMediaRecordingAuthority() {
            MediaService.checkRecordingAuthority({
                jobId: this.$route.params.jobId
            }).then(res => {
                this.canMediaRecord = res.canRecord;
                this.canMediaList = res.openStatus;
                this.$set(this.$refs.jobContent, 'canMediaDelete', res.canDelete);
                if(this.canMediaList) {
                    this.checkMediaViewed();
                }
                if(this.canMediaRecord) {
                    let today = new Date().getTime();
                    if(today >= new Date('2020/06/17 00:00:00').getTime() && today < new Date('2020/06/20 00:00:00') && (!localStorage.getItem('jobRecordGuideDate') || localStorage.getItem('jobRecordGuideDate') !== moment(today).format('YYYYMMDD'))) {
                        this.$set(this.$refs.jobHead, 'isShowRecordGuide', true);
                        localStorage.setItem('jobRecordGuideDate', moment(today).format('YYYYMMDD'));
                    }
                }
            }).catch(err => {
                console.log(err)
            })
        },

        handleBindDepartment() {
            this.$refs.bindDepartment.show();
        },
        refresh() {
            this.getJobInfo(this.jobList[this.activeIdx]);
        },

        shareToIM() {
            let shareData = Object.assign({ type: 'jobShare' }, this.shareData);
            this.dispatch('App', 'showShareToImDialog', shareData);
        },

        shareToWeChat() {
            this.$refs.jobShareEditDialog.show(true, {
                jobId: this.jobInfo.id
            });
        },
        addToMyJobCard() {
            this.$refs.jobShareEditDialog.show(false, {
                jobId: this.jobInfo.id
            });
        },
        showShareJobCardDialog() {
            this.$refs.shareJobCardDialog.show();
        },
        showJobPersonalInfoDialog() {
            this.isFirstAddMyShareJob = true;
            this.$nextTick(() => {
                this.$refs.jobPersonalInfoDialog.show(true);
            })
        },

        updatePersonalInfo() {
            //展示添加到我的职位名片
            this.$refs.jobShareEditDialog.show(false, {
                jobId: this.jobInfo.id
            });
        },

        showBindProjectDialog() {
            this.$refs.jobBindProjectDialog.show();
        },

        acceptClick() {
            let isRecommend = _getQueryString('isRecommend');
            let sourcefrom = _getQueryString('sourcefrom');
            if(isRecommend){
                lbdTrack&&lbdTrack.track(
                    'getOrder',
                    {
                        jobId: this.jobInfo.id,
                        sourcefrom: sourcefrom
                    }
                )
            }
            this.headBtnLoading = true;
            if (this.jobInfo.jobType === 2) {
                _request({
                    method: 'POST',
                    url: jobUrl.get_internal_order,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data:{
                        jobId: this.jobInfo.id,
                        __RequestVerificationToken: this.token
                    },
                    throwBusinessError: true
                }).then(()=>{
                    this.headBtnLoading = false;
                    this.$refs.grabResultDialog.show(this.jobInfo);
                    this.refresh();
                    // shortTips('抢单成功！');
                    // location.href = `/Headhunting/MyCompany.html?jobName=${encodeURI(this.jobInfo.name)}#/resultRecommend?jobId=${this.jobInfo.id}&fromPage=GrabOrder`;
                    // location.href = `/?jobName=${encodeURI(this.jobInfo.name)}#/resultRecommend?jobId=${this.jobInfo.id}&fromPage=GrabOrder`;
                }).catch(err => {
                    this.headBtnLoading = false;
                    switch (err.data.code) {
                        case "NotFoundFirm":
                            this.$confirm('您尚未加入公司，为更好保障您做单的权益，请加入公司。', '提示', {
                                confirmButtonText: '加入公司',
                                cancelButtonText: '取消'
                            }).then(() => {
                                window.open(err.data.url);
                            }).catch(() => {});
                            break;
                        case "NotConfirmEmail":
                            this.$confirm('您的邮箱尚未验证，为您能及时获取职位信息，请验证。', '提示', {
                                confirmButtonText: '验证邮箱',
                                cancelButtonText: '取消'
                            }).then(() => {
                                // window.open("/Headhunting/MyCompany.html#/emailVerify");
                                // window.open("/Headhunting/#/personalCenter?isEmailVerify=true");
                                window.open("/Headhunting/#/personalCenter??Tab=accountSet");
                            }).catch(() => {});
                            break;
                        case "Applying":
                            this.$alert('还未能认证您所在的公司，请等待审核通过后再抢单！', {
                                confirmButtonText: '我知道了'
                            });
                            break;
                        case "FirmNotVerified":
                            if(this.user.firmVerificationStatus == 0) {
                                this.$alert('市场职位抢单需要公司的信息被审核验证', '提示', {
                                    confirmButtonText: '申请认证',
                                    callback: action => {
                                        if(action == 'conform') {
                                            window.open("/Headhunting/Firm/Verify");
                                        }
                                    }
                                });
                            } else if(this.user.firmVerificationStatus == 1) {
                                this.$alert('你的公司信息正在审核中，请等待...', {
                                    confirmButtonText: '我知道了'
                                });
                            }
                            break;
                        default:
                            shortTips("系统异常，请稍后重试");
                            break;
                    }
                });
            } else if( [3,4,5].includes(this.jobInfo.jobType) ) {
                let isConfirm = this.jobInfo.jobType === 1?true:false;
                console.log(this.jobInfo.jobType);
                _request({
                    url: "/openapi/Job/%p/Order".replace(/\%p/ig, this.jobInfo.id),
                    method: "POST",
                    baseURL: "LbdOpenApi",
                    data: {
                        jobId: this.jobInfo.id,
                    },
                }).then(()=>{
                    this.headBtnLoading = false;
                    // shortTips('抢单成功！');
                    // location.href = `/Headhunting/MyCompany.html?jobName=${encodeURI(this.jobInfo.name)}#/resultRecommend?jobId=${this.jobInfo.id}&curRecommendJobType=${this.currentJobType}&marketJobType=${this.jobInfo.jobType}`;
                    this.$refs.grabResultDialog.show(this.jobInfo);
                    this.refresh();
                    // location.href = `/?jobName=${encodeURI(this.jobInfo.name)}#/resultRecommend?jobId=${this.jobInfo.id}&curRecommendJobType=${this.currentJobType}&marketJobType=${this.jobInfo.jobType}`;
                    // MyCompany.html#/market
                }).catch(err => {
                    this.headBtnLoading = false;
                });
            } else {
                let isConfirm = this.jobInfo.jobType === 1?true:false;
                console.log(this.jobInfo.jobType);
                _request({
                    method: 'POST',
                    url: jobUrl.get_market_order,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: {
                        jobId: this.jobInfo.id,
                        confirm: isConfirm,
                        __RequestVerificationToken: this.token
                    },
                }).then(()=>{
                    this.headBtnLoading = false;
                    this.$refs.grabResultDialog.show(this.jobInfo);
                    this.refresh();
                    // shortTips('抢单成功！');
                    // location.href = `/Headhunting/MyCompany.html?jobName=${encodeURI(this.jobInfo.name)}#/resultRecommend?jobId=${this.jobInfo.id}`;
                    // location.href = `/?jobName=${encodeURI(this.jobInfo.name)}#/resultRecommend?jobId=${this.jobInfo.id}`;
                }).catch(err => {
                    this.headBtnLoading = false;
                });
            }
        },

        showFamousCompanyDialog() {
            this.$refs['famousCompanyDialog'].show(this.jobInfo);
        },

        handleEdit() {
            window.open(`/Headhunting/MyCompany.html#/jobCreate/single?jobId=${this.jobInfo.id}`);
        },

        showTransferJobDialog() {
            let transferJobs = [];
            transferJobs.push(this.jobInfo);
            this.$refs.transferJobDialog.initDialog(transferJobs);
        },

        handleJobStatus() {
            this.changeJobStatus(this.jobList[this.activeIdx]);
        },
        contactRecruiter(id) {
            if (window.lbdTrack) {
                lbdTrack.track("ContactRecruiterClick", {});
            } else {
                _tracker.track("ContactRecruiterClick");
            }
            setTimeout(() => {
                let jobInfo = this.jobInfo,
                    shareData = Object.assign(
                        {
                            type: "jobShare",
                            unionId: id,
                        },
                        this.shareData
                    );
                this.dispatch("App", "showInstantMessage", {
                    isShare: true,
                    json: shareData,
                });
            }, 100);
        },
        jumpJobDetail() {
            // window.open(`/#/Job/${this.jobInfo.id}`);
            window.open(`/Headhunting/MyCompany.html#/Job/${this.jobInfo.id}`);
        },
        handleGrabCallback(job) {
            this.$refs.grabResultDialog.show(job);
        },
        handleRecommendBtnCallback(job) {
            this.$refs.recommendDialog.show(job.id, job.name, job.jobType, job.jobType);
        },
        grabDialogClose(job) {
            let activeJob = this.jobList[this.activeIdx];

            this.$emit('updateJobItem', Object.assign({}, activeJob, {
                isMyOrder: true,
            }));
        },
        // popRecom() {
        //     this.$refs.grabResultDialog.show(this.jobList[this.activeIdx]);
        // },
     
    }
};
</script>

<style lang="scss" scope>
    .job-list {
        // width: 840px;
        width: 100%;
        height: auto;
        overflow: hidden;
        .job-content {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: flex-start;
            height: calc(100vh - 280px);
            // min-width: 1182px;
            // overflow-x: auto;
            // position: relative;
            
            
            .job-list-page{
                flex: 9;
                min-width: 554px;
                border-radius: 10px;
                margin-right: 10px;
                height: calc(100vh - 280px);
                max-height: calc(100vh - 280px);
            }
            .job-content-page{
                flex: 10;
                background: #FFFFFF;
                border-radius: 10px;
                padding: 16px;
                // min-width: 618px;
                min-height: calc(100vh - 320px);
                max-height: calc(100vh - 320px);
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 10px;
                }
                &::-webkit-scrollbar-track, &::-webkit-scrollbar-thumb {
                    border-width: 2px;
                }
                scrollbar-width: thin; /* 设置比默认滚动条宽度更窄的宽度 */

                // &::-webkit-scrollbar {
                //     display: none;
                // }


                .content-top{
                    width: 100%;
                    border-bottom: 1px solid rgba(235,235,235,0.92);

                    .content-title-row{
                        display: flex;
                        flex-flow: row nowrap;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        .crow-l{
                            flex: 1;
                            overflow: hidden;

                            .title_loc{
                                display: inline-block;
                                font-size: 20px;
                                font-weight: 600;
                                color: #333333;
                                line-height: 26px;
                                width: 100%;

                                // max-width: 700px;
                                // overflow: hidden;
                                // white-space: nowrap;
                                // text-overflow: ellipsis;
                                .name{
                                    max-width: 55%;
                                    display: inline-block;
                                }
                                .loca{
                                    max-width: 45%;
                                    display: inline-block;
                                }
                            }
                            
                        }
                        .crow-r{
                            display: flex;
                            flex-flow: row nowrap;
                            justify-content: end;
                            width: 100px;
                            flex-shrink: 0;
                            .edit,.more{
                                display: inline-block;
                                font-size: 16px;
                                color: black;

                                width: 25px;
                                height: 26px;
                                line-height: 26px;
                                cursor: pointer;
                            }
                            .more{
                               font-size: 32px;
                               margin-left: 2px!important;
                            }
                        }
                    }
                    .cont-row{
                        height: 22px;
                        line-height: 22px;
                        margin-bottom: 8px;

                        .tags{
                            vertical-align: top;
                            // margin-right: 20px;
                            .JobEmergency{
                                display: inline-block;
                                height: 20px;
                                line-height: 20px;
                                background: #FF4B4B;
                                border-radius: 3px;
                                font-size: 12px;
                                margin-right: 7px;
                                padding: 0 10px;
                                color: white;
                                .hurry{
                                    color: white;
                                    font-size: 10px;
                                    vertical-align: top;
                                }
                            }
                            .recruiting{
                                display: inline-block;
                                height: 20px;
                                line-height: 18px;
                                background: rgba(56,188,157,0.14);
                                color: #38BC9D;
                                border: 1px solid rgba(56,188,157,0.53);
                                font-size: 12px;
                                margin-right: 7px;
                                padding: 0 10px;
                                border-radius: 3px;
                                .hurry{
                                    vertical-align: top;
                                    margin-right: 2px;
                                    .icon{
                                        vertical-align: -2px;
                                    }
                                }
                            }
                            .tag{
                                display: inline-block;
                                height: 20px;
                                line-height: 18px;
                                border-radius: 3px;
                                font-size: 12px;
                                margin-right: 7px;
                                padding: 0 10px;
                                border: 1px solid #666;
                                // vertical-align: -1px;
                            }
                            .job-tag-danger{
                                border-color: #FFB6B6;
                                color: #FF4B4B;
                                background: rgba(255,75,75,0.1);
                            }
                            .job-tag-primary{
                                border-color: rgba(56,188,157,0.53);
                                color: #38bc9d;
                            }
                            .job-tag-info{
                                border-color: rgba(81,164,255,0.53);
                                color: #39f;
                                background: rgba(185,208,255,0.14);
                            }
                            .job-tag-warning{
                                border-color: #FFDDA8;
                                color: #FF9E21;
                                background: rgba(255,236,199,0.7);
                            }
                        }

                        .percent{
                            font-weight: 500;
                            color: #FF9E21;
                        }
                        .promise-time{
                            color: #666666;
                            margin-left: 20px;
                            margin-right: 20px;
                            .month{
                                color: #FF9E21;
                                margin: 0 4px;
                            }
                        }
                        .salary{
                            font-size: 14px;
                            font-weight: 500;
                            color: #666666;
                            line-height: 20px;
                            margin-right: 8px;
                        }

                        .icon-wrap{
                            margin-left: 13px;
                            vertical-align: top;
                            .info-icon{
                                color: #666;
                                vertical-align: 1px;
                                margin-right: 2px;
                            }

                            .loc{
                                max-width: 300px;
                                display: inline-block;
                                vertical-align: bottom;
                            }
                        }
                    }
                    .nums-wrap{
                        background: linear-gradient(270deg, #FCFCFC 0%, #F7F8F8 100%);
                        border-radius: 5px;
                        height: 40px;

                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        .num-cell{
                        
                            font-size: 12px;
                            font-weight: 500;
                            color: #999999;
                            line-height: 17px;
                            flex: 1;
                            text-align: center;
                            position: relative;
                            &::after{
                                width: 1px;
                                height: 15px;
                                background: #DCDCDC;
                                content: '';
                                display: inline-block;
                                position: absolute;
                                right: 0;
                                top: 4px;
                            }
                            &:last-child::after{
                                display: none;
                            }
                            .count{
                                font-size: 14px;
                                font-weight: 600;
                                color: #333333;
                                line-height: 20px;
                                margin-right: 5px;
                            }


                        }
                    }
                    .customer-infos{
                        margin-top: 8px;
                        margin-bottom: 17px;
                        // height: 30px;
                        line-height: 30px;
                        display: flex;
                        align-items: center;
                        padding: 8px 0;
                        .pic{
                            display: inline-block;
                            width: 32px;
                            height: 32px;
                            border: 1px solid rgba(168,168,168,0.44);
                            overflow: hidden;
                            margin-right: 7px;
                            border-radius: 50%;
                            flex-shrink: 0;
                        }
                        .pointer-event-none {
                            pointer-events: none;
                        }
                        .infos{
                            flex: 1;
                            display: flex;
                            flex-flow: row nowrap;
                            justify-content: flex-start;
                            align-items: center;
                            .name{
                                display: inline-block;
                                max-width: 240px;
                                font-size: 12px;
                                font-weight: 500;
                                color: #666666;
                                line-height: 17px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }

                            .center-line {
                                display: inline-block;
                                width: 1px;
                                height: 12px;
                                margin: 0 12px;
                                background-color: rgba(235, 235, 235, 0.92);
                            }

                            .project-name{
                                display: inline-block;
                                max-width: 240px;
                                font-size: 12px;
                                font-weight: 500;
                                color: #666666;
                                line-height: 17px;
                                vertical-align: middle;


                                .project-info-item {
                                    color: #666;
                                    cursor: pointer;

                                    &:hover {
                                        color: $primary;
                                        text-decoration: underline;
                                    }
                                }
                            }

                            .underline{
                                text-decoration: underline;
                                padding-left: 30px;
                            }
                        }
                    }
                }

                .jd-content{
                    
                    .block{
                        padding-top: 15px 0;
                        .tit{
                            font-size: 16px;
                            font-weight: 600;
                            color: #333333;
                            line-height: 24px;
                            margin-bottom: 6px;
                            margin-top: 16px;
                            .text-primary{
                                color: $primary;
                            }
                        }

                        .firm-label-list {
                            // padding: 0 28px;
                            margin-bottom: 16px;
                            .firm-label-item {
                                display: inline-block;
                                margin-left: 20px;
                                padding: 0 20px;
                                border: 1px solid #38bc9d;
                                color: $primary;
                                line-height: 36px;
                                height: 36px;
                                &:nth-of-type(1) {
                                    margin-left: 0;
                                }
                            }
                        }


                        .job-share-board {
                            background-color: #f1faf7;
                            padding: 20px;
                            margin: 0 28px;
                            &-msg {
                                margin: 0;
                            }
                            &-btn {
                                margin-top: 14px;
                                padding: 0 20px;
                                height: 28px;
                                width: auto;
                                line-height: 28px;
                                border-radius: 3px;
                                background: $primary;
                                color: #fff;
                                &:hover {
                                    background-color: #36b495;
                                }
                                .email-icon {
                                    width: 22px;
                                    height: 22px;
                                    margin-top: -3px;
                                }
                            }
                        }

                        .pre-wrap{
                            // font-size: 14px;
                            // font-weight: 500;
                            color: #666666;
                            // line-height: 24px;
                            // font-family: "Microsoft Yahei";

                            // padding: 0 28px;
                            line-height: 24px;
                            margin-bottom: 14px;
                            white-space: pre-wrap;
                            font-family: inherit;
                        }


                        .accept-headhunters {
                            margin: 20px 0;
                            // padding: 0 28px;
                            cursor: default;
                            &.empty {
                                line-height: 24px;
                                margin: 0;
                            }
                            &.invite-headhunters-btn {
                                cursor: pointer;
                                &:hover {
                                    color: $primary;
                                }
                            }
                            .accepted-headhunters-avatar {
                                display: inline-block;
                                margin-right: 10px;
                            }
                        }
                    }

                    .infos-card{
                        background: linear-gradient(270deg, #FCFCFC 0%, #F7F8F8 100%);
                        border-radius: 8px;
                        padding: 21px 23px;
                        display: flex;
                        margin-top: 30px;
                        align-items: center;
                    
                        .user-pic{
                            width: 58px;
                            height: 58px;
                            margin-right: 16px;
                            // border: 1px solid rgba(168,168,168,0.44);
                            overflow: hidden;
                            .avatar-sm {
                                width: 58px;
                                height: 58px;
                                border: 1px solid rgba(168,168,168,0.44);
                                border-radius: 50%;
                            }
                        }
                        .user-info{
                            flex: 1;
                            >p{
                                margin: 0;
                            }
                            .name{
                                font-size: 12px;
                                font-weight: 500;
                                color: #333333;
                                line-height: 17px;
                            }
                            .time{
                                font-size: 11px;
                                font-weight: 500;
                                color: #999999;
                                line-height: 16px;
                            }
                        }
                        .infos-right{
                            width: 110px;
                            .contact-btn{
                                width: 106px;
                                height: 34px;
                                border-radius: 5px;
                                border: 1px solid #38BC9D;
                                font-size: 14px;
                                font-weight: 500;
                                color: #38BC9D;
                                line-height: 20px;
                                &:hover{
                                    background: #fafafa;
                                }
                            }
                        }
                    }

                    .bottom-wrap{
                        margin-top: 26px;
                        text-align: center;
                        .bottom-btn{
                            height: 38px;
                            line-height: 36px;
                            border-radius: 5px;
                            border: 1px solid #38BC9D;
                            font-size: 14px;
                            font-weight: 500;
                            color: #38BC9D;
                            padding: 0 16px;
                            &:hover{
                                background: #F1FFFC;
                            }
                        }
                    }
                }
            }
            
            .job-list-wrapper {
                // width: 1100px;
                // width: 70%;
                // margin-bottom: 20px;
                // width: calc(100% - 490px);
                // min-width: 564px;
                min-height: 200px;
                // padding: 0 20px 20px;
                // background-color: #fff;
                // border-radius: 0 0 8px 8px;
                .list-container{
                    max-height: calc(100vh - 360px);
                    overflow: auto;
                    scrollbar-width: none;  
                    &::-webkit-scrollbar {
                        display: none;
                    }
                }

                .empty {
                    min-height: 200px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    .empty-img {
                        display: inline-block;
                        background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
                        background-size: contain;
                        width: 170px;
                        height: 160px;
                    }
                    .empty-data {
                        font-size: 14px;
                        margin-bottom: 0px;
                    }
                }

                .job-list-pagination.el-pagination {
                    height: 38px;
                    background: #FFFFFF;
                    border-radius: 6px;
                    padding-top: 2px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    // box-shadow: 3px 3px rgba(0, 0, 0, 0.5);
                    .pagination-text{
                        color: #999;
                        span{
                            height: 28px;
                            line-height: 28px;
                            font-size: 14px;
                            .el-input{
                                width: 48px;
                                margin: 0 5px;
                                &__inner{
                                    height: 28px;
                                    line-height: 28px;
                                    padding: 0 5px;
                                }
                                .el-input__inner{
                                    height: 28px;
                                    line-height: 28px;
                                }
                            }
                            &:nth-of-type(2){
                                margin-left: 5px;
                                color: $primary;
                                cursor: pointer;
                            }
                        }
                    }

                }
                // .job-list-pagination.el-pagination {
                //     margin: 40px 0 20px 0;
                //     padding: 0;
                //     font-weight: normal;
                //     .btn-next, .btn-prev {
                //         height: 38px;
                //         border: 1px solid;
                //     }
                //     .btn-prev {
                //         border-right: none;
                //         border-radius: 4px 0 0 4px;
                //     }
                //     .btn-next {
                //         border-left: none;
                //         border-radius: 0 4px 4px 0;
                //     }
                //     .el-pagination__sizes .el-input{
                //         width: 87px;
                //         .el-input__inner {
                //             color: #666;
                //             .el-select__caret{
                //                 color: #999;
                //             }
                //         }
                //     }
                //     .el-pager {
                //         border-top: 1px solid;
                //         border-bottom: 1px solid;
                //         li {
                //             min-width: 36px;
                //             height: 36px;
                //             line-height: 36px;
                //             font-size: 14px;
                //         }
                //     }
                //     .btn-next, .btn-prev, .el-pager {
                //         border-color: #DDDDDD;
                //     }
                //     .el-input__inner, .el-pagination__total, .el-pagination__jump {
                //         height: 38px;
                //         line-height: 38px;
                //         font-size: 14px;
                //     }
                //     .pagination-text{
                //         color: #999;
                //         span{
                //             height: 38px;
                //             line-height: 38px;
                //             font-size: 14px;
                //             .el-input{
                //                 width: 48px;
                //                 margin: 0 5px;
                //                 &__inner{
                //                     height: 38px;
                //                     line-height: 38px;
                //                     padding: 0 5px;
                //                 }
                //             }
                //             &:nth-of-type(2){
                //                 margin-left: 5px;
                //                 color: $primary;
                //                 cursor: pointer;
                //             }
                //         }
                //     }
                //     .el-pagination__total,
                //     .el-pagination__sizes,
                //     .pagination-text {
                //         float: right;
                //     }
                // }
            }

            .page-empty{
                width: 100%;
                // height: 383px;
                min-height: calc(100vh - 340px);
                background: white;
                border-radius: 10px;
                text-align: center;
                >img{
                    width: 158px;
                    height: 178px;
                    margin: 73px auto 0;
                }
                .des{
                    margin: 24px auto 0;
                    font-size: 14px;
                    font-weight: 500;
                    color: #999999;
                    line-height: 20px;
                }
            }
        }
        .lbd-sign {
            padding-bottom: 20px;
            &:after {
                content: '© 2024 liebide.com';
                margin-left: 50%;
                text-align: center;
                font-size: 14px;
                color: #999;
            }
        }
    }
// @media screen and (max-width: 1400px) {
//     .job-search-bottom, .job-list-wrapper {
//         width: calc(100% - 340px) !important;
//         .job-promise {
//             overflow: hidden;
//             text-overflow: ellipsis;
//         }
//     }
//     .jobs-right{
//         width: 320px;
//     }
// }
// @media screen and (min-width: 1400px) and (max-width: 1680px) {
//     .job-search-bottom, .job-list-wrapper {
//         width: calc(100% - 430px) !important;
//         .job-promise {
//             overflow: hidden;
//             text-overflow: ellipsis;
//         }
//     }
//     .jobs-right{
//         width: 410px;
//     }
// }


.more-dropdown-menu{
    .item-wrap{
        display: flex;
        flex-flow: row nowrap;
        font-size: 16px;
        justify-content: space-between;
        width: 150px;
        align-items: center;
        height: 44px;
        .item-icon{
            font-size: 18px;
        }
        .item-text{
            padding-left: 16px;
            flex: 1;
        }
        .item-arrow-icon{
            font-size: 12px;
        }
    }
    
}
.share-popover{
    .share-btn{
        width: 100%;
        font-size: 14px;
        color: #333333;
        border: none;
        text-align: left;
        margin-bottom: 6px;
        >img{
            width: 35px;
            height: 35px;
            margin-right: 10px;
        }
        &:hover{
            background: #fafafa;
        }
    }
}

.bind-project-popover{
    margin: 0;
    .project-info-item {
        color: #666;
        &:hover {
            color: $primary;
            text-decoration: underline;
        }
    }
}
</style>