var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "custom-class": "update-job-status",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "update-job-status-header",
          attrs: { slot: "title" },
          slot: "title",
        },
        [
          _c("span", { staticClass: "header-title" }, [
            _vm._v("\n            更新职位状态\n        "),
          ]),
          _c("div", { staticClass: "batch-update-btn-list" }, [
            _c(
              "span",
              {
                staticClass: "btn-update-btn text-operate-btn",
                on: {
                  click: function ($event) {
                    return _vm.batchUpdateJobStatus(2)
                  },
                },
              },
              [_vm._v("批量暂停")]
            ),
            _c(
              "span",
              {
                staticClass: "btn-update-btn text-operate-btn",
                on: {
                  click: function ($event) {
                    return _vm.batchUpdateJobStatus(4)
                  },
                },
              },
              [_vm._v("批量完成")]
            ),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "update-job-status-wrapper",
        },
        [
          _c("div", { staticClass: "update-job-status-title" }, [
            _c("img", {
              attrs: {
                src: require("@src/assets/images/job_status_update.png"),
              },
            }),
            _c("p", { staticClass: "title-text" }, [
              _vm._v("\n                新的一周开始了！: )"),
              _c("br"),
              _vm._v(
                "\n                你现在有" +
                  _vm._s(_vm.jobList.length + _vm.earlierJobList.length) +
                  "个职位正在招聘中，快随手"
              ),
              _c("span", { staticClass: "text-primary" }, [
                _vm._v("更新职位状态"),
              ]),
              _vm._v("吧~\n            "),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "update-job-status-list" },
            [
              _vm.jobList.length > 0
                ? _vm._l(_vm.jobList, function (jobMonth, jobMonthIndex) {
                    return _c(
                      "div",
                      { key: jobMonthIndex, staticClass: "job-month-list" },
                      [
                        _c(
                          "div",
                          { staticClass: "job-month-title" },
                          [
                            _c("el-checkbox", {
                              on: {
                                change: (val) =>
                                  _vm.handleCheckAll(val, jobMonthIndex),
                              },
                              model: {
                                value: jobMonth.isSelected,
                                callback: function ($$v) {
                                  _vm.$set(jobMonth, "isSelected", $$v)
                                },
                                expression: "jobMonth.isSelected",
                              },
                            }),
                            _c(
                              "span",
                              { staticClass: "job-month-title-text" },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(jobMonth.month) +
                                    "月\n                        "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._l(jobMonth.listItem, function (job, jobIndex) {
                          return _c(
                            "div",
                            {
                              key: jobIndex,
                              staticClass: "update-status-job-item",
                            },
                            [
                              _c("el-checkbox", {
                                key: jobIndex,
                                on: {
                                  change: (val) =>
                                    _vm.handleCheckSingle(val, jobMonthIndex),
                                },
                                model: {
                                  value: job.isSelected,
                                  callback: function ($$v) {
                                    _vm.$set(job, "isSelected", $$v)
                                  },
                                  expression: "job.isSelected",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "job-item-detail" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "job-item-name text-ellipsis",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(job.name) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "job-item-customer text-ellipsis",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(job.firmShortName) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "job-item-recommend" },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "推 " + _vm._s(job.recommendCount)
                                        ),
                                      ]),
                                      _c("span", [
                                        _vm._v(
                                          "面 " + _vm._s(job.interviewCount)
                                        ),
                                      ]),
                                      _c("span", [
                                        _vm._v(
                                          "Offer " + _vm._s(job.offerCount)
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "el-dropdown",
                                    {
                                      staticClass: "job-item-status",
                                      attrs: { placement: "bottom" },
                                      on: {
                                        command: (val) =>
                                          _vm.singelUpdateJob(
                                            job,
                                            "jobStatus",
                                            val
                                          ),
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "el-dropdown-link",
                                          class: { primary: job.status === 1 },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.jobStatusMap[job.status]
                                              ) +
                                              "\n                                "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-dropdown-menu",
                                        {
                                          staticClass:
                                            "job-item-status-dropdown",
                                          attrs: { slot: "dropdown" },
                                          slot: "dropdown",
                                        },
                                        _vm._l(
                                          _vm.jobStatusList,
                                          function (jobStatus, jobStatusIndex) {
                                            return _c(
                                              "el-dropdown-item",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.showJobStatusOption(
                                                        job.status,
                                                        jobStatus.value
                                                      ),
                                                    expression:
                                                      "showJobStatusOption(job.status, jobStatus.value)",
                                                  },
                                                ],
                                                key: jobStatusIndex,
                                                attrs: {
                                                  command: jobStatus.value,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(jobStatus.text) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "job-item-emergency" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "job-item-emerency-item",
                                          class: {
                                            "emergency-active":
                                              job.emergencyDegree === 1,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.singelUpdateJob(
                                                job,
                                                "emergencyDegree",
                                                1
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    紧急\n                                "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "job-item-emerency-item",
                                          class: {
                                            "not-emergency-active":
                                              job.emergencyDegree === 0,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.singelUpdateJob(
                                                job,
                                                "emergencyDegree",
                                                0
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    不紧急\n                                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    )
                  })
                : _vm._e(),
              _vm.earlierJobList.length && _vm.isShowEarlierJob
                ? _c("div", { staticClass: "earlier-job-list-wrapper" }, [
                    _c(
                      "div",
                      { staticClass: "earlier-job-list-title" },
                      [
                        _c("el-checkbox", {
                          on: { change: _vm.handleCheckAllEarlier },
                          model: {
                            value: _vm.isAllEarlier,
                            callback: function ($$v) {
                              _vm.isAllEarlier = $$v
                            },
                            expression: "isAllEarlier",
                          },
                        }),
                        _c("span", { on: { click: _vm.handleEarlierJob } }, [
                          _vm._v(
                            "\n                        查看更早职位（共" +
                              _vm._s(_vm.earlierJobList.length) +
                              "个）\n                        "
                          ),
                          _c("i", { staticClass: "el-icon-arrow-up" }),
                        ]),
                      ],
                      1
                    ),
                    _vm.isShowEarlierJob
                      ? _c(
                          "div",
                          { staticClass: "earlier-job-list" },
                          _vm._l(_vm.earlierJobList, function (job, jobIndex) {
                            return _c(
                              "div",
                              {
                                key: jobIndex,
                                staticClass: "update-status-job-item",
                              },
                              [
                                _c("el-checkbox", {
                                  key: jobIndex,
                                  on: { change: _vm.handleCheckSingleEarlier },
                                  model: {
                                    value: job.isSelected,
                                    callback: function ($$v) {
                                      _vm.$set(job, "isSelected", $$v)
                                    },
                                    expression: "job.isSelected",
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "job-item-detail" },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "job-item-name" },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(job.name) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "job-item-customer" },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(job.firmShortName) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "job-item-recommend" },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            "推 " + _vm._s(job.recommendCount)
                                          ),
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            "面 " + _vm._s(job.interviewCount)
                                          ),
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            "Offer " + _vm._s(job.offerCount)
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "el-dropdown",
                                      {
                                        staticClass: "job-item-status",
                                        attrs: { placement: "bottom" },
                                        on: {
                                          command: (val) =>
                                            _vm.singelUpdateJob(
                                              job,
                                              "jobStatus",
                                              val
                                            ),
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "el-dropdown-link",
                                            class: {
                                              primary: job.status === 1,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.jobStatusMap[job.status]
                                                ) +
                                                "\n                                "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-dropdown-menu",
                                          {
                                            staticClass:
                                              "job-item-status-dropdown",
                                            attrs: { slot: "dropdown" },
                                            slot: "dropdown",
                                          },
                                          _vm._l(
                                            _vm.jobStatusList,
                                            function (
                                              jobStatus,
                                              jobStatusIndex
                                            ) {
                                              return _c(
                                                "el-dropdown-item",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.showJobStatusOption(
                                                          job.status,
                                                          jobStatus.value
                                                        ),
                                                      expression:
                                                        "showJobStatusOption(job.status, jobStatus.value)",
                                                    },
                                                  ],
                                                  key: jobStatusIndex,
                                                  attrs: {
                                                    command: jobStatus.value,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(jobStatus.text) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "job-item-emergency" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "job-item-emerency-item emergency",
                                            class: {
                                              active: job.emergencyDegree === 1,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.singelUpdateJob(
                                                  job,
                                                  "emergencyDegree",
                                                  1
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    紧急\n                                "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "job-item-emerency-item not-emergency",
                                            class: {
                                              active: job.emergencyDegree === 0,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.singelUpdateJob(
                                                  job,
                                                  "emergencyDegree",
                                                  0
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    不紧急\n                                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      !_vm.isShowEarlierJob
        ? _c(
            "span",
            {
              staticClass: "earlier-job-list-btn",
              on: { click: _vm.handleEarlierJob },
            },
            [
              _vm._v(
                "\n        查看更早职位（共" +
                  _vm._s(_vm.earlierJobList.length) +
                  "个）\n        "
              ),
              _c("i", { staticClass: "el-icon-arrow-down" }),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleCloseDialog } }, [
            _vm._v("稍后再提醒我"),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }