<template>
    <el-dialog
        title="订单数量变化"
        custom-class="job-recommend-amount"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false">
        <div
            class="job-recommend-amount-wrapper"
            v-loading="loading">
            <div class="job-recommend-amount-title">
                <img src="@src/assets/images/icon/icon_order_modal.png" alt="order"/>
                <div class="recommend-title">
                    <p>
                        你正在查看
                        <span class="recommend-time">{{params.startDate}}到{{params.endDate}}, </span>
                        <span class="recommend-customer text-ellipsis" :title="params.customerName" v-if="params.customerName">{{params.customerName}},</span>
                        <span class="recommend-menber text-ellipsis" :title="member">{{member}}</span>
                        的职位交付效率
                    </p>
                    <p>
                        职位共
                        <span class="recommend-job-amount">{{recommendData.total}}</span>
                        个，有
                        <span class="recommend-job-amount">{{recommendData.unhealthy}}</span>
                        个职位处于不健康状态
                    </p>
                </div>
                <el-button
                    type="primary"
                    @click="showSubscribeDataDialog">
                    订阅数据
                </el-button>
            </div>
            <div class="recommend-amount-list-wrapper">
                <el-row>
                    <el-col :span="6">
                        <div class="recommend-amount-list-title">
                            发布时间
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="recommend-amount-list-title">
                            推荐人数
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="recommend-amount-list-title">
                            职位数量
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="recommend-amount-list-title">
                            比例
                        </div>
                    </el-col>
                </el-row>
                <template v-for="(row, rowIndex) in deliveryList">
                    <el-row
                        :key="'delivery-' + rowIndex"
                        class="delivery-item">
                        <el-col :span="6">
                            <div class="publish-time">
                                <span class="publish-time-weekly">
                                    {{row.text}}
                                </span>
                                <span class="publish-time-daily">
                                    {{row.text | dayFilter}}
                                </span>
                            </div>
                        </el-col>
                        <el-col :span="18">
                            <div
                                class="recommend-amount-list"
                                v-if="row.item.some(item => item.jobCount > 0)">
                                <template v-for="(item, index) in row.item">
                                    <el-row
                                        class="recommend-amount-item"
                                        :class="{'warning': (rowIndex === 0 && item.recommendCount === 0) || (rowIndex !== 0 && item.recommendCount !== 3)}"
                                        :key="'item-' + index"
                                        v-if="item.jobCount > 0"
                                        @click.native="filterRecommendDegree(row, item.recommendCount)">
                                        <el-col :span="8">
                                            <div class="recomment-amount">
                                                <font-icon
                                                    class="recomment-amount-icon"
                                                    href="#icon-kulian"
                                                    v-if="(rowIndex === 0 && item.recommendCount === 0) || (rowIndex !== 0 && item.recommendCount !== 3)"
                                                ></font-icon>
                                                {{item.recommendCount}}个{{item.recommendCount >= 3 ? '及以上' : ''}}
                                            </div>
                                        </el-col>
                                        <el-col :span="8">
                                            <div class="job-amount">
                                                {{item.jobCount}}
                                            </div>
                                        </el-col>
                                        <el-col :span="8">
                                            <div class="scale-data">
                                                {{item.scale}}
                                            </div>
                                        </el-col>
                                    </el-row>
                                </template>
                            </div>
                            <div class="recommend-amount-list-null" v-else>
                                无职位发布
                            </div>
                        </el-col>
                    </el-row>
                </template>
            </div>
        </div>
        <el-dialog
            title="订阅到邮箱"
            :show-close="false"
            custom-class="subscribe-data-dialog"
            :visible.sync="subscribeDialogVisible"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            append-to-body>
            <div
                class="subscribe-data-wrapper"
                v-loading="subscribeLoading">
                <div class="subscribe-data-title">
                    <i class="el-icon-s-claim"></i>
                    <div class="subscribe-title-text">
                        你正在订阅
                        <span class="subscribe-data-detail" :title="member">{{member}}</span>、
                        <span class="subscribe-data-detail" :title="customer">{{customer}}</span>、
                        <span class="subscribe-data-detail">前一个月</span>
                        的职位交付效率。
                    </div>
                </div>
                <el-form
                    ref="subscribeDataForm"
                    class="subscribe-data-form"
                    :model="subscribeDataForm"
                    label-width="110px">
                    <el-form-item label="邮箱地址" prop="emails">
                        <el-input
                            type="textarea"
                            :rows="2"
                            placeholder="请输入订阅的邮箱，多个邮箱以分号“;”隔开"
                            v-model="subscribeDataForm.emails"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="发送周期">
                        <el-checkbox v-model="subscribeDataForm.isSubscriptionWeek">每周一</el-checkbox>
                        <el-checkbox v-model="subscribeDataForm.isSubscriptionMonth">每月一号</el-checkbox>
                    </el-form-item>
                </el-form>
            </div>
            <span class="dialog-footer" slot="footer">
                <el-button @click="hideSubscribeDialog">取消</el-button>
                <el-button type="primary" @click="subscribeData">确定</el-button>
            </span>
        </el-dialog>
    </el-dialog>
</template>

<script>
export default {
    name: 'jobRecommendAmount',
    props: {
        params: Object
    },
    computed: {
        member() {
            return this.params.memberName ? this.params.memberName : '全部成员';
        },
        customer() {
            return this.params.customerName ? this.params.customerName : '所有客户';
        }
    },
    data() {
        return {
            dialogVisible: false,
            loading: false,
            recommendData: {
                total: 0,
                unhealthy: 0
            },
            deliveryList: [],
            subscribeDialogVisible: false,
            subscribeLoading: false,
            subscribeDataForm: {
                emails: "",
                isSubscriptionWeek: false,
                isSubscriptionMonth: false
            }
        }
    },
    filters: {
        dayFilter(val) {
            switch(val) {
                case '第一周':
                    return '1-7天';
                case '第二周':
                    return '8-14天';
                case '第三周':
                    return '15-21天';
                case '第四周':
                    return '22-30天';
                case '更久':
                    return '30天以后';
            }
        }
    },
    methods: {
        show() {
            this.dialogVisible = true;
            this.getRecommendData();
        },
        getRecommendData() {
            this.loading = true;
            let params = this.params;
            _request({
                url: '/MyCompany/SatisticDeliveryList',
                method: "POST",
                data: {
                    creatorIds: params.creatorIds,
                    customerIds: params.customerIds,
                    startDate: params.startDate,
                    endDate: params.endDate
                },
            }).then(res => {
                this.loading = false;
                this.recommendData.total = res.total;
                this.recommendData.unhealthy = res.unhealthy;
                this.deliveryList = res.delivery;
            }).catch(err => {
                this.loading = false;
                console.log(err)
            })
        },
        showSubscribeDataDialog() {
            this.subscribeDialogVisible = true;
            this.getSubscribeData();
        },
        getSubscribeData() {
            this.subscribeLoading = true;
            _request({
                url: "/MyCompany/GetJobSubscription",
                method: "GET",
                data: {
                    JobSubscriptionType: 2,
                    _: new Date().getTime()
                }
            }).then(res => {
                this.subscribeLoading = false;
                if(res) {
                    this.subscribeDataForm.emails = res.emails || "";
                    this.subscribeDataForm.isSubscriptionWeek = res.isSubscriptionWeek || false;
                    this.subscribeDataForm.isSubscriptionMonth = res.isSubscriptionMonth || false;
                }
            }).catch(err => {
                this.subscribeLoading = false;
                console.log(err)
            })
        },
        hideSubscribeDialog() {
            this.$refs.subscribeDataForm.resetFields();
            this.subscribeDialogVisible = false;
        },
        subscribeData() {
            let emailList = this.subscribeDataForm.emails.split(';'),
                isError = false;
                emailList.some(email => {
                    if(email && !emailValid(email)) {
                        shortTips(`${email}不是有效的邮箱`);
                        isError = true;
                        return true
                    }
                });
            if(isError) {
                return false;
            } else {
                this.subscribeLoading = true;
                let json = {
                    customerIds: this.params.customerIds,
                    creatorIds: this.params.creatorIds,
                    teamId: "",
                    JobSubscriptionType: 2,
                    emails: emailList,
                    isSubscriptionWeek: this.subscribeDataForm.isSubscriptionWeek,
                    isSubscriptionMonth: this.subscribeDataForm.isSubscriptionMonth,
                    _: new Date().getTime()
                };
                _request({
                    url: "/MyCompany/CreateJobSubscription",
                    method: "GET",
                    data: json
                }).then(res => {
                    this.subscribeLoading = false;
                    this.hideSubscribeDialog();
                    shortTips('订阅成功！');
                }).catch(err => {
                    this.subscribeLoading = false;
                    console.log(err)
                })
            }
        },
        filterRecommendDegree(deliveryItem, recommendDegree) {
            this.$emit('filterRecommendDegree', {
                time: [deliveryItem.startDate, deliveryItem.endDate],
                recommendDegree: recommendDegree
            });
            this.dialogVisible = false;
        }
    }
}
</script>

<style lang="scss" scope>
    .job-recommend-amount.el-dialog {
        .text-ellipsis {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .job-recommend-amount-title {
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #9b9b9b;
            padding: 0 25px;
            img {
                width: 70px;
                height: 70px;
                margin-right: 40px;
            }
            .recommend-title {
                padding-top: 5px;
                flex-grow: 1;
                p {
                    line-height: 20px;
                    margin-bottom: 10px;
                    & > span {
                        color: #666;
                    }
                    .recommend-customer, .recommend-menber {
                        display: inline-block;
                        max-width: 140px;
                        position: relative;
                        top: 5px;
                    }
                    .recommend-job-amount, .recommend-job-amount {
                        margin: 0 3px;
                    }
                }
            }
        }
        .recommend-amount-list-wrapper {
            margin-bottom: 20px;
            .recommend-amount-list-title {
                text-align: center;
                font-size: 16px;
                line-height: 20px;
                color: #9b9b9b;
                margin-bottom: 20px;
            }
            .delivery-item {
                & + .delivery-item {
                    margin-top: 25px;
                }
            }
            .publish-time {
                display: flex;
                flex-direction: column;
                align-items: center;
                line-height: 20px;
                &-weekly {
                    color: #666;
                    font-size: 16px;
                }
                &-daily {
                    color: #9b9b9b;
                    font-size: 14px;
                }
            }
            .recommend-amount-item {
                line-height: 30px;
                background-color: #f8f8f8;
                color: #666;
                cursor: pointer;
                &:hover {
                    box-shadow: 0 0 3px rgba(50,50,50,.3);
                }
                &.warning {
                    color: #dc7070;
                }
                & + .recommend-amount-item {
                    margin-top: 5px;
                }
            }
            .recomment-amount, .job-amount, .scale-data {
                text-align: center;
            }
            .recomment-amount {
                position: relative;
                &-icon {
                    position: absolute;
                    width: 18px;
                    height: 18px;
                    top: 6px;
                    left: 20px;
                }
            }
            .recommend-amount-list-null {
                text-align: center;
                color: #666;
                line-height: 40px;
            }
        }
    }
</style>

<style lang="scss">
.subscribe-data-dialog.el-dialog {
    width: 600px;
    .subscribe-data-title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        line-height: 34px;
        color: #9b9b9b;
        .el-icon-s-claim {
            font-size: 50px;
            margin-right: 10px;
            color: $primary;
        }
        .subscribe-title-text {
            line-height: 20px;
        }
        .subscribe-data-detail {
            max-width: 80px;
            color: #333;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: inline-block;
            position: relative;
            top: 4px;
        }
    }
    .subscribe-data-form {
        margin-left: 7px;
        .el-form-item {
            margin-bottom: 15px;
        }
        .el-form-item__label {
            text-align: left;
        }
    }
}
</style>
