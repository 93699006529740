<template>
    <el-dialog
        :before-close="handleClose"
        :visible.sync="dialogVisible"
        custom-class="suc-dialog"
        width="360px"
        @close="dialogClose"
    >
        <div class="content">
            <p class="row">
                <span class="result-icon">
                    <font-icon href="#icon-yiwancheng"></font-icon>
                </span>
            </p>
            <p class="row bold">抢单成功</p>
            <p class="row normal">职位已抢单成功</p>
        </div>
        <span
            class="dialog-footer"
            slot="footer"
        >
            <button
                @click="handleJump('job')"
                class="btn job"
            >职位详情</button>
            <button
                @click="handleJump('recommend')"
                class="btn recommend"
            >推荐候选人</button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    name: 'grab-result-dialog',
    components: {
    },
    data () {
        return {
            dialogVisible: false,
            job: {},
        };
    },
    created () { },
    mounted () { },
    methods: {
        show (job) {
            this.job = job;
            this.dialogVisible = true;
        },
        handleClose (done) {
            this.dialogVisible = false;
        },
        handleJump(type) {
            switch(type) {
                case 'job':
                    window.open(`/Headhunting/MyCompany.html#/Job/${this.job.id}`);
                    this.dialogVisible = false;
                    break;
                case 'recommend':
                    this.$emit('recommend-btn-callback', this.job);
                    break;
            }
        },
        dialogClose() {
            this.$emit('grab-dialog-close', this.job);
        },
    },
}
</script>

<style lang="scss" scope>
.suc-dialog {
    .el-dialog__header {
        border-bottom: none;
    }
    .el-dialog__body {
        padding: 0;
        .content {
            .row {
                text-align: center;
                .result-icon {
                    font-size: 40px;
                    height: 40px;
                    line-height: 40px;
                    .font-icon {
                        vertical-align: top;
                    }
                }
            }
            .bold {
                font-size: 16px;
                font-weight: 600;
                color: #333333;
                line-height: 22px;
            }
            .normal {
                font-size: 14px;
                font-weight: 500;
                color: #666666;
                line-height: 20px;
            }
        }
    }

    .dialog-footer {
        text-align: center;
        display: block;
        margin: 22px 0 12px;
        .btn {
            width: 90px;
            height: 34px;
            border-radius: 5px;
            border: none;
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            color: #333333;
            line-height: 32px;
            padding: 0;
            &:first-child {
                margin-right: 12px;
            }
        }
        .job {
            color: #333;
        }
        .recommend {
            background: $primary;
            color: white;
        }
    }
}
</style>