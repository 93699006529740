var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "job-item",
      class: {
        "selected-job": _vm.currentIdx == _vm.activeIdx,
      },
    },
    [
      _c(
        "div",
        { staticClass: "job-item-top", on: { click: _vm.handlePageDetail } },
        [
          _c("div", { staticClass: "title-row ell" }, [
            _c(
              "span",
              {
                staticClass: "name text-ellipsis",
                attrs: { title: _vm.job.name },
              },
              [_vm._v(_vm._s(_vm.job.name))]
            ),
            _c(
              "span",
              {
                staticClass: "locations text-ellipsis",
                attrs: { title: _vm.job.location },
              },
              [_vm._v("[" + _vm._s(_vm.job.location) + "]")]
            ),
            _c(
              "span",
              { staticClass: "tags" },
              [
                _vm._l(_vm.job.activityTags, function (item) {
                  return _c(
                    "el-tooltip",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            item.id !== "StandstillJob" &&
                            item.id != "FirstOrderActivityJob",
                          expression:
                            "item.id !== 'StandstillJob' && item.id != 'FirstOrderActivityJob'",
                        },
                      ],
                      key: item.id,
                      attrs: {
                        effect: "light",
                        placement: "right",
                        disabled: !item.description,
                        "popper-class": "job-tag-description",
                      },
                    },
                    [
                      _c("div", {
                        attrs: { slot: "content" },
                        domProps: { innerHTML: _vm._s(item.description) },
                        slot: "content",
                      }),
                      item.id == "JobEmergency"
                        ? _c(
                            "span",
                            {
                              staticClass: "JobEmergency",
                              attrs: { title: "急聘" },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "hurry" },
                                [
                                  _c("font-icon", {
                                    attrs: { href: "#icon-shandian" },
                                  }),
                                ],
                                1
                              ),
                              _vm._v("急聘\n                    "),
                            ]
                          )
                        : _c(
                            "span",
                            {
                              staticClass: "tag",
                              class: `job-tag-${
                                _vm.jobActivityTag[item.id].theme
                              }`,
                              attrs: { title: item.name },
                            },
                            [
                              _vm._v(
                                _vm._s(item.name == "New" ? "最新" : item.name)
                              ),
                            ]
                          ),
                    ]
                  )
                }),
                _vm.job.jobType == 3
                  ? _c("span", { staticClass: "tag job-tag-info" }, [
                      _vm._v("HR"),
                    ])
                  : _vm._e(),
                _vm.job.jobType == 5
                  ? _c("span", { staticClass: "tag job-tag-info" }, [
                      _vm._v("代运营"),
                    ])
                  : _vm._e(),
                _vm.job.hasRecord
                  ? _c(
                      "span",
                      {
                        staticClass: "tag job-tag-info",
                        attrs: { title: "该职位有职位讲解" },
                      },
                      [_vm._v("有讲解")]
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]),
          _c("div", { staticClass: "item-row" }, [
            _c("div", { staticClass: "row-l" }, [
              _c("div", { staticClass: "item" }, [
                _c("span", { staticClass: "salary" }, [
                  _vm._v(
                    _vm._s(_vm.job.salary == "0K-0K" ? "面议" : _vm.job.salary)
                  ),
                ]),
                _c("span", { staticClass: "job-tag" }, [
                  _vm._v(_vm._s(_vm.job.yearOfExperience)),
                ]),
                _c("span", { staticClass: "job-tag" }, [
                  _vm._v(_vm._s(_vm.job.degree)),
                ]),
                _c("span", { staticClass: "job-tag" }, [
                  _vm._v("需 " + _vm._s(_vm.job.recruitingCount) + " 人"),
                ]),
                _vm.job.categoryName
                  ? _c("span", { staticClass: "job-tag" }, [
                      _vm._v(_vm._s(_vm.job.categoryName)),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "item" }, [
                _c("span", { staticClass: "percent" }, [
                  _vm._v(
                    _vm._s(
                      _vm.job.commissionValue.indexOf("0K-0K") > -1
                        ? "按比例"
                        : _vm.job.commissionValue
                    )
                  ),
                ]),
                _c("span", { staticClass: "promise-time" }, [
                  _vm._v("\n                        保证期"),
                  _c("span", { staticClass: "month" }, [
                    _vm._v(_vm._s(_vm.job.guaranteePeriod)),
                  ]),
                  _vm._v("个月 \n                    "),
                ]),
                _vm.job.canViewFirmDetail
                  ? _c(
                      "span",
                      {
                        staticClass: "customer",
                        class: {
                          "no-link":
                            !_vm.isMyFirm || [3, 5].includes(_vm.job.jobType),
                        },
                        attrs: {
                          target:
                            _vm.isMyFirm && ![3, 5].includes(_vm.job.jobType)
                              ? "_blank"
                              : "",
                          title: _vm.job.firmShortName || _vm.job.firmName,
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.handleJumpCustomer(_vm.job)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.job.firmShortName || _vm.job.firmName)
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "item" }, [
                _vm.job.isCooperativeJob &&
                _vm.userInfo.headhuntingFirmType > 0 &&
                ((_vm.userInfo.headhuntingFirmType > 2 &&
                  _vm.job.headhuntingFirmCommissionValue > 0) ||
                  (_vm.userInfo.headhuntingFirmType < 3 &&
                    _vm.job.otherFirmCommissionValue > 0))
                  ? _c(
                      "div",
                      { staticClass: "job-firm-commission" },
                      [
                        _vm._v(
                          "\n                        分佣比例\n                        "
                        ),
                        _c("span", { staticClass: "text-grey" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.userInfo.headhuntingFirmType > 2
                                  ? _vm.job.headhuntingFirmCommissionValue + "%"
                                  : _vm.job.otherFirmCommissionValue + "%"
                              ) +
                              "\n                        "
                          ),
                        ]),
                        _vm.userInfo.headhuntingFirmType < 3
                          ? _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  "popper-class": "commission-rate-text",
                                  effect: "light",
                                  content:
                                    "简历提供者，分成比例需要和平台运营确认。",
                                  placement: "right",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "tip-icon" },
                                  [
                                    _c("font-icon", {
                                      staticClass: "default",
                                      attrs: { href: "#icon-talent_qd" },
                                    }),
                                    _c("font-icon", {
                                      staticClass: "hover",
                                      attrs: { href: "#icon-talent_ql" },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "row-r" },
              [
                (!_vm.job.isMyOrder &&
                  !_vm.job.isMyJob &&
                  _vm.job.status != 4 &&
                  _vm.job.status != 2) ||
                ((_vm.job.isMyOrder || _vm.job.isMyJob) &&
                  _vm.job.status != 4 &&
                  _vm.job.status != 2) ||
                (_vm.job.isMyJob && _vm.job.status != 4) ||
                (_vm.job.isMyJob && _vm.job.status == 4)
                  ? [
                      !_vm.job.isMyOrder &&
                      !_vm.job.isMyJob &&
                      _vm.job.status != 4 &&
                      _vm.job.status != 2 &&
                      _vm.job.status != 3
                        ? _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.moreOperateLoading,
                                  expression: "moreOperateLoading",
                                },
                              ],
                              staticClass: "job-btn",
                              attrs: { "data-event-id": "E_job_item_click" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleJobMoreOperate("grabOrder")
                                },
                              },
                            },
                            [_vm._v("抢")]
                          )
                        : _vm._e(),
                      (_vm.job.isMyOrder || _vm.job.isMyJob) &&
                      _vm.job.status != 4 &&
                      _vm.job.status != 2
                        ? _c(
                            "button",
                            {
                              staticClass: "job-btn",
                              attrs: { "data-event-id": "E_job_item_click" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleJobMoreOperate("recommend")
                                },
                              },
                            },
                            [_vm._v("推荐候选人")]
                          )
                        : _vm._e(),
                      _vm.job.isMyJob && _vm.job.status != 4
                        ? _c(
                            "button",
                            {
                              staticClass: "job-btn",
                              attrs: { "data-event-id": "E_job_item_click" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleJobMoreOperate(
                                    "changeJobStatus"
                                  )
                                },
                              },
                            },
                            [_vm._v("修改职位状态")]
                          )
                        : _vm._e(),
                      _vm.job.isMyJob && _vm.job.status == 4
                        ? _c(
                            "button",
                            {
                              staticClass: "job-btn",
                              attrs: { "data-event-id": "E_job_item_click" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleJobMoreOperate("copyJob")
                                },
                              },
                            },
                            [_vm._v("复制职位")]
                          )
                        : _vm._e(),
                    ]
                  : _c("span", [
                      _vm._v(
                        "职位" +
                          _vm._s(_vm.job.status == 4 ? "已完成" : "已暂停") +
                          "状态下无法操作"
                      ),
                    ]),
              ],
              2
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "job-item-bottom" }, [
        _c(
          "div",
          { staticClass: "bottom-l" },
          [
            _c("avatar", {
              staticClass: "pic",
              attrs: {
                size: "sm",
                src: _vm.job.avatarUrl,
                userId: _vm.job.ownerId,
                enableCard: true,
                enableLink: true,
                shareData: _vm.shareData,
              },
            }),
            _c("div", { staticClass: "infos" }, [
              _c("p", { staticClass: "name" }, [
                _vm._v(_vm._s(_vm.job.realName) + " "),
              ]),
              _c("p", { staticClass: "time" }, [
                _vm._v(
                  _vm._s(_vm._f("dateFilter")(_vm.job.updatedDateTime)) +
                    " 更新"
                ),
              ]),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "bottom-m" }, [
          _c("div", { staticClass: "middle-cell" }, [
            _c("span", { staticClass: "count" }, [
              _vm._v(
                _vm._s(
                  _vm.job.recommendationStatistic
                    ? _vm.job.recommendationStatistic.total
                    : 0
                )
              ),
            ]),
            _vm._v("推荐\n            "),
          ]),
          _c("div", { staticClass: "middle-cell" }, [
            _c("span", { staticClass: "count" }, [
              _vm._v(
                _vm._s(
                  _vm.job.recommendationStatistic
                    ? _vm.job.recommendationStatistic.interviewCount
                    : 0
                )
              ),
            ]),
            _vm._v("面试\n            "),
          ]),
          _c("div", { staticClass: "middle-cell" }, [
            _c("span", { staticClass: "count" }, [
              _vm._v(
                _vm._s(
                  _vm.job.recommendationStatistic
                    ? _vm.job.recommendationStatistic.offerCount
                    : 0
                )
              ),
            ]),
            _vm._v("Offer\n            "),
          ]),
        ]),
        _c("div", { staticClass: "bottom-r" }, [
          _c(
            "span",
            {
              staticClass: "to-detail",
              attrs: { "data-event-id": "E_job_item_click" },
              on: {
                click: function ($event) {
                  return _vm.jumpJobDetail(_vm.job)
                },
              },
            },
            [
              _vm._v("\n                职位详情\n                "),
              _c(
                "span",
                { staticClass: "arrow-icon" },
                [_c("font-icon", { attrs: { href: "#icon-right-copy" } })],
                1
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }