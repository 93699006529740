var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "before-close": _vm.handleClose,
        visible: _vm.dialogVisible,
        "custom-class": "suc-dialog",
        width: "360px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.dialogClose,
      },
    },
    [
      _c("div", { staticClass: "content" }, [
        _c("p", { staticClass: "row" }, [
          _c(
            "span",
            { staticClass: "result-icon" },
            [_c("font-icon", { attrs: { href: "#icon-yiwancheng" } })],
            1
          ),
        ]),
        _c("p", { staticClass: "row bold" }, [_vm._v("抢单成功")]),
        _c("p", { staticClass: "row normal" }, [_vm._v("职位已抢单成功")]),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "button",
            {
              staticClass: "btn job",
              on: {
                click: function ($event) {
                  return _vm.handleJump("job")
                },
              },
            },
            [_vm._v("职位详情")]
          ),
          _c(
            "button",
            {
              staticClass: "btn recommend",
              on: {
                click: function ($event) {
                  return _vm.handleJump("recommend")
                },
              },
            },
            [_vm._v("推荐候选人")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }