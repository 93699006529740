<template>
    <div class="jobs-right">
        <!-- <div
            class="update-job-status-btn standstill-job-btn"
            @click="showStandstillJobDialog(true)"
            v-if="standstillJobNum && isMyFirm">
            批量更新僵尸职位({{standstillJobNum}})
        </div>
        <div
            class="update-job-status-btn"
            @click="goToUpdateJobStatus"
            v-if="standstillJobNum === 0 && isMyFirm">
            批量更新职位状态
        </div>
        <div
            class="update-job-status-btn standstill-job-btn"
            @click="showJobBindDeptDialog(true)"
            v-if="unbindDeptJobList.length > 0 && isMyFirm">
            批量绑定职位部门({{unbindDeptJobNum}})
        </div>
        <div
            class="job-order-dialog-btn-list"
            v-if="isMyFirm">
            <span
                class="job-order-dialog-btn"
                @click="showJobOrderAmountDialog">
                <img src="@src/assets/images/job-chart1.png" alt="订单变化图">
                订单数量变化
            </span>
            <span
                class="job-order-dialog-btn"
                @click="showJobRecommendAmountDialog">
                <img src="@src/assets/images/job-chart2.png" alt="订单推荐图">
                订单推荐数量
            </span>
        </div> -->
        <div class="job-order-list-wrapper">
            <div class="job-order-list-title">
                <i class="job-order-list-icon el-icon-office-building"></i>
                客户订单量
            </div>
            <div class="job-order-list" v-loading="customerLoading">
                <template v-if="customerOrders.length">
                    <div
                        class="job-order-item"
                        :key="customerIndex"
                        v-for="(customer, customerIndex) in customerOrders">
                        <i class="job-order-item-icon el-icon-office-building"></i>
                        <!-- :href="isMyFirm ? `/Headhunting/MyCompany.html#/Customer/${customer.customerId}` : 'javascript:void(0)'" -->
                        <a
                            class="job-order-item-name"
                            :class="{'disable-link': !isMyFirm}"
                            :href="isMyFirm ? `/#/Customer/${customer.customerId}` : 'javascript:void(0)'"
                            :target="isMyFirm? '_blank' : ''">
                            {{customer.customerName}}
                        </a>
                        <span
                            class="job-order-item-count"
                            @click="addParams('customer', customer)">
                            {{customer.orderCount}}
                        </span>
                    </div>
                </template>
                <span class="job-order-list-null" v-else>暂无数据</span>
            </div>
            <div
                class="job-order-more-btn"
                @click="showMoreJobOrder('0')"
                v-if="!customerLoading && customerOrders.length && isMyFirm">
                加载更多
            </div>
        </div>
        <div
            class="job-order-list-wrapper"
            v-if="isMyFirm">
            <div class="job-order-list-title">
                <i class="job-order-list-icon el-icon-s-custom"></i>
                成员订单量
            </div>
            <div class="job-order-list" v-loading="memberLoading">
                <template v-if="memberOrders.length">
                    <div
                        class="job-order-item"
                        :key="memberIndex"
                        v-for="(member, memberIndex) in memberOrders">
                        <i class="job-order-item-icon el-icon-s-custom"></i>
                        <!-- <a
                            class="job-order-item-name"
                            :href="`/Headhunting/MyCompany.html#/user/${member.userId}`"
                            target="_blank">
                            {{member.nickname}}
                        </a> -->
                        <a
                            class="job-order-item-name"
                            :href="`/#/user/${member.userId}`"
                            target="_blank">
                            {{member.nickname}}
                        </a>
                        <span
                            class="job-order-item-count"
                            @click="addParams('creator', member.userId)">
                            {{member.orderCount}}
                        </span>
                    </div>
                </template>
                <span class="job-order-list-null" v-else>暂无数据</span>
            </div>
            <div
                class="job-order-more-btn"
                @click="showMoreJobOrder('1')"
                v-if="!memberLoading && memberOrders.length && userInfo.isAdminOrTeamLeader">
                加载更多
            </div>
        </div>
        <div class="job-order-list-wrapper">
            <div class="job-order-list-title">
                <i class="job-order-list-icon el-icon-location"></i>
                地区订单量
            </div>
            <div class="job-order-list" v-loading="locationLoading">
                <template v-if="locationOrders.length">
                    <div
                        class="job-order-item"
                        :key="locationIndex"
                        v-for="(location, locationIndex) in locationOrders">
                        <i class="job-order-item-icon el-icon-location"></i>
                        <span class="job-order-item-name job-location-name">{{location.areaName}}</span>
                        <span class="job-order-item-count job-location-count">{{location.orderCount}}</span>
                    </div>
                </template>
                <span class="job-order-list-null" v-else>暂无数据</span>
            </div>
            <div
                class="job-order-more-btn"
                @click="showMoreJobOrder('2')"
                v-if="!locationLoading && locationOrders.length && isMyFirm && userInfo.isAdminOrTeamLeader">
                加载更多
            </div>
        </div>
        <!-- href="/MyCompany/Performances/Job" -->
        <a
            class="more-job-order-btn"
            @click="showMoreJobOrder('3')"
            v-if="isMyFirm && userInfo.isAdminOrTeamLeader">
            更多订单数据
        </a>
        <!-- <standstill-job-dialog
            ref="standstillJobDialog"
            @standstillJobUpdate="standstillJobUpdate"
            @auto-show-dialog-close="checkJobBindDeptDialog"
        ></standstill-job-dialog>
        <job-bind-dept-dialog
            ref="jobBindDeptDialog"
            @unbindDeptJobUpdate="unbindDeptJobUpdate"
        ></job-bind-dept-dialog> -->
        <!-- <update-job-status
            ref="updateJobStatus"
            :jobList="updateStatusJob.list"
            :earlierJobList="updateStatusJob.earlierList"
        ></update-job-status> -->
        <!-- <job-order-amount-dialog
            ref="jobOrderAmount"
            :params="orderParams"
        ></job-order-amount-dialog>
        <job-recommend-amount-dialog
            ref="jobRecommendAmount"
            :params="orderParams"
            @filterRecommendDegree="(json) => $emit('filterRecommendDegree', json)"
        ></job-recommend-amount-dialog> -->
    </div>
</template>

<script>
import moment from 'moment';
import JobService from '#/js/service/jobService.js';
import UpdateJobStatus from '../component/update-job-status.vue';
import JobOrderAmountDialog from '../component/job-order-amount-dialog.vue';
import JobRecommendAmountDialog from '../component/job-recommend-amount-dialog.vue';
import StandstillJobDialog from '#/component/common/dialog/standstill-job-dialog.vue';
import JobBindDeptDialog from '#/component/common/dialog/job-bind-dept-dialog.vue';
export default {
    components: {
        UpdateJobStatus,
        JobOrderAmountDialog,
        JobRecommendAmountDialog,
        StandstillJobDialog,
        JobBindDeptDialog
    },
    props: {
        params: {
            type: Object,
            default: () => {
                return {}
            }
        },
        orderParams: Object,
        isMyFirm: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            customerOrders: [],
            memberOrders: [],
            locationOrders: [],
            customerLoading: false,
            memberLoading: false,
            locationLoading: false,
            standstillJobNum: 0,
            unbindDeptJobNum: 0,
            unbindDeptJobList: []
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.user.userInfo;
        }
    },
    mounted() {
        // if(location.href.indexOf('isShowStandstill=true') > -1) {
        //     this.showStandstillJobDialog();
        //     location.href = '/Headhunting/MyCompany.html#/jobList';
        // }
        // if(location.href.indexOf('isShowJobBindDept=true') > -1) {
        //     this.showJobBindDeptDialog();
        //     location.href = '/Headhunting/MyCompany.html#/jobList';
        // }
        // this.getStandstillJobNum();
    },
    methods: {
        // getUpdateStatusJob() {
        //     JobService.getRecruitingJobList({
        //         orderBy: 0,
        //         jobSource: 1,
        //         jobStatus: 1,
        //         start: 0,
        //         take: 10,
        //         batchModifyJobStatus: false,
        //         _: new Date().getTime()
        //     }).then(res => {
        //         if(res.isShow) {
        //             let total = res.list.length + res.threeMonthBeforeList.length;
        //             if(total > 0) {
        //                 let htmlString = `
        //                     <p>新的一周开始了，你现在有${total}个职位正在招聘中</p>
        //                     <p>有职位已经停止招聘了吗？快随手<span style="color:#38bc9d;">更新职位状态</span>吧~</p>
        //                 `;
        //                 this.$confirm(htmlString, '更新职位状态', {
        //                     dangerouslyUseHTMLString: true,
        //                     customClass: "update-job-status-confirm-dialog",
        //                     showClose: false,
        //                     cancelButtonText: '稍后提醒我',
        //                     confirmButtonText: '去更新'
        //                 }).then(() => {
        //                     localStorage.setItem('jobListJobStatusDate', moment().format('YYYY-MM-DD'));
        //                     this.goToUpdateJobStatus();
        //                 }).catch(() => {})
        //             }
        //         } else {
        //             this.goToUpdateJobStatus();
        //         }
        //     }).catch(err => {
        //         console.log(err)
        //     })
        // },
        // showJobOrderAmountDialog() {
        //     this.$refs.jobOrderAmount.show();
        // },
        // showJobRecommendAmountDialog() {
        //     this.$refs.jobRecommendAmount.show();
        // },

        addParams(type, item) {
            let json = {};
            switch(type) {
                case 'customer':
                    json.customerName = item.customerName;
                    json.customerId = item.customerId;
                    break;
                case 'creator':
                    json.creatorId = item;
                    break;

            }
            this.$emit('search', json)
        },
        showMoreJobOrder(type) {
            let params = this.params;
            localStorage.setItem('statisticsJobParams', JSON.stringify({
                startDate: params.startDate || "",
                endDate: params.endDate || "",
                customerIds: params.customerIds || [],
                creatorIds: params.creatorIds || [],
                primaryCustomerIds: params.primaryCustomerIds || [],
                teamIndex: params.teamIndex || 0,
                type: type
            }));
            // window.open('/MyCompany/Performances/Job');
            // window.open('/Headhunting/#/teamData', '_blank');
            let _sUrl = '';
            if(type == '0') {
                // _sUrl = '/Headhunting/MyCompany.html#/customerList';
                _sUrl = '/#/customerList';
            } else{
                _sUrl = '/Headhunting/#/teamData?fromMore';
            }
            window.open(_sUrl, '_blank');
        },
        getOrdersCountTop(updateOrderType) {
            if(updateOrderType !== 0) {
                let orderJson = {
                    take: this.params.firmId && this.params.firmId !== this.$store.state.user.userInfo.firmId ? 10 : 7,
                    firmId: this.params.firmId
                }, params = this.params;
                if(params.startDate && params.endDate) {
                    orderJson.startDate = params.startDate;
                    orderJson.endDate = params.endDate;
                }
                if(params.customerIds && params.customerIds.length) {
                    orderJson.customerIds = params.customerIds;
                }
                if(params.creatorIds && params.creatorIds.length) {
                    orderJson.creatorIds = params.creatorIds;
                }
                this.getLocationOrders(orderJson);
                if(updateOrderType === 3) {
                    this.getCustomerOrders(orderJson);
                    this.getMemberOrders(orderJson);
                } else if(updateOrderType === 1) {
                    this.getMemberOrders(orderJson);
                } else if(updateOrderType === 2) {
                    this.getCustomerOrders(orderJson);
                }
            }
        },
        getCustomerOrders(orderJson) {
            this.customerOrders = [];
            this.customerLoading = true;
            orderJson.requestType = 0;
            JobService.getJobOrdersCount(orderJson)
            .then(res => {
                this.customerLoading = false;
                if(res && res.length) {
                    this.customerOrders = res;
                }
            }).catch(err => {
                this.customerLoading = false;
                console.log(err)
            })
        },
        getMemberOrders(orderJson) {
            this.memberOrders = [];
            this.memberLoading = true;
            orderJson.requestType = 1;
            JobService.getJobOrdersCount(orderJson)
            .then(res => {
                this.memberLoading = false;
                if(res && res.length) {
                    this.memberOrders = res;
                }
            }).catch(err => {
                this.memberLoading = false;
                console.log(err)
            })
        },
        getLocationOrders(orderJson) {
            this.locationOrders = [];
            this.locationLoading = true;
            orderJson.requestType = 2;
            JobService.getJobOrdersCount(orderJson)
            .then(res => {
                this.locationLoading = false;
                if(res && res.length) {
                    this.locationOrders = res;
                }
            }).catch(err => {
                this.locationLoading = false;
                console.log(err)
            })
        },

        // standstillJobUpdate(number) {
        //     this.standstillJobNum = number;
        //     this.$store.dispatch('setStandstillJobNum', this.standstillJobNum);
        // },
        // getStandstillJobNum() {
        //     JobService.getStandStillJob({
        //         includeDetail: false
        //     }).then(res => {
        //         this.standstillJobUpdate(res.total);
        //         let total = this.standstillJobNum,
        //             today = moment().format('YYYYMMDD');
        //         if(total >= 8 || (total > 0 && new Date().getDay() == 1)) {
        //             if(!localStorage.getItem('standstillJobDate') || localStorage.getItem('standstillJobDate') != today) {
        //                 localStorage.setItem('standstillJobDate', moment().format('YYYYMMDD'));
        //                 let fromText = '';
        //                 if(total > 0 && new Date().getDay() == 1) {
        //                     fromText = 'MondayAndHasUndisposedJob';
        //                 } else if(total >= 8) {
        //                     fromText = 'EightUndisposedJobExceeded';
        //                 }
        //                 _tracker.track('StandstillJobDialogAutoShow', JSON.stringify({
        //                     from: fromText
        //                 }));
        //                 this.showStandstillJobDialog();
        //             }
        //         } else if(total === 0 && new Date().getDay() == 1) {
        //             if(!localStorage.getItem('jobListJobStatusDate') || localStorage.getItem('jobListJobStatusDate') != today) {
        //                 localStorage.setItem('jobListJobStatusDate', moment().format('YYYYMMDD'));
        //                 this.getUpdateStatusJob();
        //             }
        //         }

        //         this.getUnbindDeptJobList();
        //     }).catch(err => {
        //         this.getUnbindDeptJobList();
        //     });
        // },
        // showStandstillJobDialog(isFromClick) {
        //     let dialogId = "";
        //     if(isFromClick) {
        //         dialogId = 'StandstillJobDialogShowClick-' + new Date().getTime();
        //         _tracker.track('StandstillJobDialogShowClick');
        //     } else {
        //         dialogId = 'StandstillJobDialogAutoShow-' + new Date().getTime();
        //     }
        //     localStorage.setItem('standstillJobDate', moment().format('YYYYMMDD'));
        //     this.$refs.standstillJobDialog.show(dialogId, isFromClick);
        // },
        // goToUpdateJobStatus() {
        //     window.open("/Headhunting/#/myOrders/createdJob?status=1");
        // },
        // unbindDeptJobUpdate(number) {
        //     this.unbindDeptJobNum = number;
        //     this.$store.dispatch('setUnbindDeptJobNum', this.unbindDeptJobNum);
        // },
        // getUnbindDeptJobList() {
        //     JobService.getUnbindDeptJobList({
        //         customerId: '',
        //         orderBy: 0
        //     }).then(res => {
        //         this.unbindDeptJobList = res.data || [];
        //         this.unbindDeptJobUpdate(this.unbindDeptJobList.length);
        //         this.checkJobBindDeptDialog();
        //     })
        // },
        // checkJobBindDeptDialog() {
        //     if(this.$refs.standstillJobDialog && this.$refs.standstillJobDialog.dialogVisible) {
        //         return false;
        //     }
        //     let total = this.unbindDeptJobList.length,
        //         today = moment().format('YYYYMMDD');
        //     if(total > 10) {
        //         if(!localStorage.getItem('bindJobDeptDate') || localStorage.getItem('bindJobDeptDate') != today) {
        //             localStorage.setItem('bindJobDeptDate', moment().format('YYYYMMDD'));
        //             _tracker.track('JobBindDeptDialogAutoShow');
        //             this.showJobBindDeptDialog();
        //         }
        //     }
        // },
        // showJobBindDeptDialog(isFromClick) {
        //     let dialogId = '';
        //     if(isFromClick) {
        //         dialogId = 'JobBindDeptDialogShowClick-' + new Date().getTime();
        //         _tracker.track('JobBindDeptDialogShowClick');
        //     } else {
        //         dialogId = 'JobBindDeptDialogAutoShow-' + new Date().getTime();
        //     }
        //     localStorage.setItem('bindJobDeptDate', moment().format('YYYYMMDD'));
        //     this.$refs.jobBindDeptDialog.show();
        // }
    }
}
</script>

<style lang="scss" scope>
    .jobs-right {
        margin-left: 20px;
        width: 470px;
        // width: 28%;
        // position: absolute;
        // right: 0;
        // top: -60px;
        .update-job-status-btn {
            margin-bottom: 40px;
            width: 100%;
            height: 40px;
            line-height: 40px;
            text-align: center;
            cursor: pointer;
            border: 1px solid #ddd;
            &:hover {
                box-shadow: 0 2px 4px 0 #ccc;
            }
            &.standstill-job-btn {
                color: #f39731;
            }
        }
        .job-order-dialog-btn-list {
            margin-bottom: 40px;
            display: flex;
            justify-content: space-between;
            .job-order-dialog-btn {
                width: 128px;
                height: 128px;
                border: 1px solid #ddd;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                cursor: pointer;
                &:hover {
                    box-shadow: 0 2px 4px 0 #ccc;
                }
                img {
                    width: 50px;
                    height: 50px;
                    margin-bottom: 16px;
                }
            }
        }
        .job-order-list-wrapper {
            background-color: #fff;
            padding: 20px;
            border-radius: 8px;
            .job-order-list-title {
                margin-bottom: 15px;
                font-size: 16px;
                color: #333;
                .job-order-list-icon {
                    margin-right: 6px;
                    font-size: 16px;
                    color: $primary;
                    vertical-align: top;
                    line-height: inherit;
                }
            }
            .job-order-list {
                border: 1px solid #ccc;
                border-radius: 2px;
                padding: 10px 20px;
                .job-order-item {
                    height: 45px;
                    line-height: 45px;
                    color: #666;
                    & + .job-order-item {
                        border-top: 1px solid #eee;
                        line-height: 44px;
                    }
                    .job-order-item-icon {
                        margin-right: 10px;
                        font-size: 16px;
                        color: #ccc;
                        vertical-align: top;
                        line-height: inherit;
                    }
                    .job-order-item-name {
                        display: inline-block;
                        max-width: 154px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        color: #666;
                        &:hover {
                            color: $primary;
                        }
                        &.job-location-name {
                            cursor: default;
                            color: #666;
                        }
                        &.disable-link {
                            cursor: default;
                            color: #666;
                        }
                    }
                    .job-order-item-count {
                        display: inline-block;
                        float: right;
                        text-align: center;
                        width: 42px;
                        cursor: pointer;
                        &:hover {
                            color: $primary;
                        }
                        &.job-location-count {
                            cursor: default;
                            color: #666;
                        }
                    }
                }
                .job-order-list-null {
                    height: 45px;
                    line-height: 44px;
                    color: #666;
                }
            }
            .job-order-more-btn {
                width: 100%;
                height: 44px;
                margin-top: 10px;
                line-height: 44px;
                text-align: center;
                cursor: pointer;
                border-radius: 2px;
                border: 1px solid #ccc;
                font-size: 14px;
                color: #b9b9b9;
                &:hover {
                    color: $primary;
                }
            }
            & + .job-order-list-wrapper{
                margin-top: 20px;
            }
        }
        .more-job-order-btn {
            margin-top: 15px;
            border: 1px solid #ccc;
            display: block;
            width: 100%;
            height: 40px;
            line-height: 38px;
            text-align: center;
            color: #4a4a4a;
            &:hover {
                border-color: $primary;
                color: $primary;
            }
        }
    }
</style>

<style lang="scss">
.update-job-status-confirm-dialog.el-dialog {
    p {
        color: #666;
        line-height: 26px;
        margin-bottom: 0;
    }
}
@keyframes bling{
    from {
        color:#666;
    } to {
        color:#fff;
    }
}
</style>