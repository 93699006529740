import { render, staticRenderFns } from "./protocol-job-add-dialog.vue?vue&type=template&id=4ec2b240&"
import script from "./protocol-job-add-dialog.vue?vue&type=script&lang=js&"
export * from "./protocol-job-add-dialog.vue?vue&type=script&lang=js&"
import style0 from "./protocol-job-add-dialog.vue?vue&type=style&index=0&id=4ec2b240&lang=scss&scope=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Work\\CSDN\\Code\\baza-lbd-portal-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4ec2b240')) {
      api.createRecord('4ec2b240', component.options)
    } else {
      api.reload('4ec2b240', component.options)
    }
    module.hot.accept("./protocol-job-add-dialog.vue?vue&type=template&id=4ec2b240&", function () {
      api.rerender('4ec2b240', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/job-list/component/protocol-job-add-dialog.vue"
export default component.exports